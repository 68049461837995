import React from "react";
import "../../../Styles//portfolio.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import SteaveJame from "../../../../images/steave.jpeg";
import ContactForm from "../../ContactForm";

function SteaveJames() {
  return (
    <div className="container">
      <div className="row case-study-parent">
        <div className="col-md-12 mt-5">
          <div className="project-card-parent case-studies-hero-section justify-content-between d-flex pb-5">
            <div className="col-lg-5">
              <div className="project-description-parent">
                <div className="project-heading">Steave and James</div>
                <div className="project-description">
                  This company tailors and produces garments for style-conscious
                  clients and brands worldwide
                </div>
                <a
                  href="https://www.steveandjames.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn read-btn"
                >
                  Visit... <FontAwesomeIcon icon={faChevronRight} />
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-parent">
                <img src={SteaveJame} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2 className="about-head">About</h2>
          <div className="case-description">
            Perfecting the art of tailoring since 1986, two generations of
            master tailors have dedicated their lives to creating the finest
            bespoke fashions for an appreciative and ever-growing international
            clientele. We serve a wider audience of style conscious clients,
            including members of society, the diplomatic corps, leaders in
            finance and industry. Within its main workshop, we employ highly
            skilled cutters, tailors and hand finishers, all dedicated to the
            creation of perfection in their art.
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <div className="port_obect">
            <h2>Project Objective</h2>
            <p className="case-description">
              To develop a social platform where international customers as well
              as local Thai customers can review and conveniently follow on our
              regular updates about tailoring. Whether its a launch of a new
              fabric, discounts or any other information.
            </p>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <div className="row">
            <div className="col-md-6">
              <div className="concept">
                <h3>Conceptualization</h3>
                <ul className="bullet_list">
                  <li>High-quality Fabric feel through design quality</li>
                  <li>Easy navigation</li>
                  <li>Reflecting legendary history of the store</li>
                  <li>Affordable pricing</li>
                  <li>A user-friendly online platform</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div class="tech_use">
                <h3>Technologies Used</h3>
                <ul class="bullet_list">
                  <li>PHP</li>
                  <li>JQuery</li>
                  <li>Apache server</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="col-md-6">
              <div className="show_features">
                <h3>Features</h3>
                <ul className="bullet_list">
                  <li>
                    Reseller Login (User can become a reseller for Steve & James
                    Bangkok. Reseller program allows you to set up your own
                    tailoring service, either using your own brand or ours.)
                  </li>
                  <li>Login module for Resellers</li>
                  <li>Listing of all fabrics.</li>
                  <li> Tailoring details.</li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div className="functions">
                <h3>Functions</h3>
                <ul className="bullet_list">
                  <li>User-friendly interface</li>
                  <li> Detailed product descriptions</li>
                  <li>Efficient customer support system</li>
                  <li>Easy to use reseller dashboard</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2>Our Project Challenges</h2>
          <div className="project-points mt-4">
            <div className="points-description">
              <strong>Design requirements:</strong>  The website needed to be visually appealing, user-friendly, and consistent with the brand image, which required careful attention to design elements and user experience.
            </div>

          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2>Solutions We Developed</h2>
          <div className="project-points mt-4">
            <div className="points-description">
              <strong>Easy Navigation:</strong> We created a simple and intuitive navigation structure to help users easily find what they're looking for on the website. This included a clear menu and search functionality, which enables users to quickly access products and information.
            </div>
            <div className="points-description mt-4">
              <strong>Custom CMS:</strong> To enable easy content management, we developed a custom content management system (CMS) for the website. This allows the client to easily update and publish new content, add new products to the store, and manage orders and customer data. <br />Conducted thorough market research to identify customer needs and preferences. Implemented industry-standard security measures to protect customer data.
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default SteaveJames;
