import React from "react";
import "../../../Styles//portfolio.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import wallet from "../../../../images/currency-g0d0bad874_1280.jpg";
import ContactForm from "../../ContactForm";

function SlotSpin() {
  return (
    <div className="container">
      <div className="row case-study-parent">
        <div className="col-md-12 mt-5">
          <div className="project-card-parent case-studies-hero-section justify-content-between d-flex pb-5">
            <div className="col-lg-5">
              <div className="project-description-parent">
                <div class="project-heading">Slot Spin Game</div>
                <div class="project-description">
                  Slot machine game offers a transparent, secure, and
                  decentralized gaming platform, providing players with
                  confidence in the fairness of the game
                </div>
                <a
                  href="http://slotgames.web3inventiv.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn read-btn"
                >
                  Visit...
                  <FontAwesomeIcon icon={faChevronRight} />
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-parent">
                <img src={wallet} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <h2 class="about-head">About</h2>
          <div class="case-description">
            This blockchain game allows you to spin the wheel to earn tokens and
            win rewards. Improve your skills and try your luck today!This
            blockchain-based game lets you spin the wheel to earn tokens as you
            play. Come and try your luck today!
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <div class="port_obect">
            <h2>Project Objective</h2>
            <p class="case-description">
              The objective of this project is to create a platform where
              players can place bets and spin the reels, with symbols appearing
              randomly, and users receiving rewards when these symbols align
              correctly.
            </p>
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <div class="row">
            <div class="col-md-6">
              <div class="concept">
                <h3>Conceptualization</h3>
                <ul class="bullet_list">
                  <li>Token Economy</li>
                  <li>Gameplay Mechanics</li>
                  <li>Randomization</li>
                  <li>Payouts and Winnings</li>
                  <li>Transparency and Auditability</li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div class="tech_use">
                <h3>Technologies Used</h3>
                <ul class="bullet_list">
                  <li>Solidity</li>
                  <li>Blockchain(Polygon)</li>
                  <li>React JS</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="show_features">
                <h3>Features</h3>
                <ul class="bullet_list">
                  <li>Connect to wallet</li>
                  <li>Purchase matic token to play</li>
                  <li>Spin</li>
                  <li>Claim</li>
                  <li>Token Balance</li>
                  <li>Wallet Balance</li>
                  <li>Winning Amount</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <h2>Our Project Challenges</h2>
          <div class="project-points mt-4">
            <div class="points-description">
              High transaction fees on public blockchains, particularly during
              times of network congestion, can make microtransactions associated
              with slot spins costly for players.
            </div>
            <div class="points-description mt-3">
              Traditional blockchains provide transparency by design, which may
              not be ideal for gambling applications where players may prefer to
              keep their identities and betting activities private
            </div>
            <div class="points-description mt-3">
              Ensuring the fairness of the game's outcome and the randomness of
              the slot spins is crucial for building player trust.
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <h2>Solutions We Developed</h2>
          <div class="project-points mt-4">
            <div class="points-description">
              Implemented techniques like batch processing or optimizing gas
              usage in smart contracts to reduce transaction costs.
            </div>
            <div class="points-description mt-3">
              Implemented privacy-enhancing technologies like zero-knowledge
              proofs or privacy-preserving smart contracts which helps protect
              user privacy while still ensuring the integrity of the game.
            </div>
            <div class="points-description mt-3">
              Achieved this by using verifiable randomness solutions, such as
              commit-reveal schemes or cryptographic techniques like hash-based
              random number generation, to generate random outcomes in a
              transparent and tamper-resistant manner.
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default SlotSpin;
