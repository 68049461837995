import React from "react";
import "../../../Styles//portfolio.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import slash from "../../../../images/slash-shopping.jpg";
import ContactForm from "../../ContactForm";

function Slash() {
  return (
    <div className="container">
      <div className="row case-study-parent">
        <div className="col-md-12 mt-5">
          <div className="project-card-parent case-studies-hero-section justify-content-between d-flex pb-5">
            <div className="col-lg-5">
              <div className="project-description-parent">
                <div class="project-heading">Slash App</div>
                <div class="project-description">
                  Innovative app transforms the shopping experience by rewarding
                  users with exciting perks every time they make a purchase.
                </div>
                {/* <a
                  href="https://kinder-nutrition.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn read-btn"
                >
                  Visit...
                  <FontAwesomeIcon icon={faChevronRight} />
                </a> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-parent">
                <img src={slash} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <h2 class="about-head">About</h2>
          <div class="case-description">
            Our innovative app rewards users every time they shop, seamlessly
            crediting them with points for a hassle-free experience. We're
            changing the way people shop for a more enjoyable experience
            worldwide.
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <div class="port_obect">
            <h2>Project Objective</h2>
            <p class="case-description">
              Location-based social commerce platform in India that allows users
              to discover local businesses, restaurants, and stores, and earn
              rewards for visiting and making purchases at these establishments.
            </p>
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <div class="row">
            <div class="col-md-6">
              <div class="concept">
                <h3>Conceptualization</h3>
                <ul class="bullet_list">
                  <li>Identify primary users of the app.</li>
                  <li>Create detailed user personas.</li>
                  <li>
                    Design a visually appealing and user-friendly interface.
                  </li>
                  <li>
                    Use intuitive navigation, clear typography, and engaging
                    visuals.
                  </li>
                  <li>
                    Select the right technology stack for app development.
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div class="tech_use">
                <h3>Technologies Used</h3>
                <ul class="bullet_list">
                  <li>React native</li>
                  <li>Node.js</li>
                  <li>AWS</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="show_features">
                <h3>Features</h3>
                <ul class="bullet_list">
                  <li>
                    Slash is a location-based app that provides tailored
                    recommendations based on user preferences and browsing
                    history.
                  </li>
                  <li>
                    The app offers detailed information about local merchants.
                  </li>
                  <li>Users earn rewards for shopping at partner merchants.</li>
                  <li>
                    Users can share their shopping experiences and
                    recommendations.
                  </li>
                  <li>
                    Slash offers personalized recommendations and notifications
                    based on user preferences.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <h2>Our Project Challenges</h2>
          <div class="project-points mt-4">
            <div class="points-description">
              Handling a large user base and increasing traffic while
              maintaining performance and reliability.
            </div>
            <div class="points-description mt-3">
              Safeguarding user data and ensuring compliance with privacy
              regulations.
            </div>
            <div class="points-description mt-3">
              Adapting to the unique characteristics and dynamics of local
              markets, including cultural preferences, regulatory requirements,
              and competitive landscape.
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <h2>Solutions We Developed</h2>
          <div class="project-points mt-4">
            <div class="points-description">
              Designed a scalable architecture using cloud computing,
              microservices, and caching mechanisms. Continuously monitored
              system performance and optimize resource allocation to handle peak
              loads. Implemented load balancing, auto-scaling, and disaster
              recovery strategies to ensure high availability and resilience.
            </div>
            <div class="points-description mt-3">
              Implemented robust security measures, such as encryption,
              authentication, and access controls, to protect user information.
              Adhere to data protection laws like GDPR, CCPA, and local
              regulations governing user privacy.
            </div>
            <div class="points-description mt-3">
              Conducted thorough market research to understand the needs,
              preferences, and behaviors of local users and businesses.
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default Slash;
