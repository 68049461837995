import React from "react";
import "../../../Styles//portfolio.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import petCorner from "../../../../images/pet-corner.jpg";
import ContactForm from "../../ContactForm";

function PetCorner() {
  return (
    <div className="container">
      <div className="row case-study-parent">
        <div className="col-md-12 mt-5">
          <div className="project-card-parent case-studies-hero-section justify-content-between d-flex pb-5">
            <div className="col-lg-5">
              <div className="project-description-parent">
                <div className="project-heading">Pet Corner</div>
                <div className="project-description">
                  Providing high-quality pet products such as collars, leashes,
                  harnesses, and toys for cats and dogs.
                </div>
                <a
                  href="https://petcornerdubai.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn read-btn"
                >
                  Visit... <FontAwesomeIcon icon={faChevronRight} />
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-parent">
                <img src={petCorner} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2 className="about-head">About</h2>
          <div className="case-description">
            It is an innovative e-commerce platform developed to cater to the
            needs of pet owners who need the best products for their pets. The
            platform offers a comprehensive range of high-quality pet supplies
            and accessories, including beds, collars, leashes, toys, and more,
            all available at affordable prices.
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <div className="port_obect">
            <h2>Project Objective</h2>
            <p className="case-description">
              The objective of the project is to empower pet owners by providing
              them with an innovative e-commerce platform for easily accessing
              and purchasing a wide range of high-quality pet supplies and
              accessories. We developed this platform with the aim of
              simplifying the pet supply shopping experience, offering durable
              and affordable products for cats and dogs. The project seeks to
              build a loyal customer base and establish itself as a leading
              online pet store in the market.
            </p>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <div className="row">
            <div className="col-md-6">
              <div className="concept">
                <h3>Conceptualization</h3>
                <ul className="bullet_list">
                  <li>A user-friendly online platform</li>
                  <li> High-quality accessories</li>
                  <li> Affordable pricing</li>
                  <li> A seamless shopping experience</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div class="tech_use">
                <h3>Technologies Used</h3>
                <ul class="bullet_list">
                  <li>Angular.js</li>
                  <li>Open Cart E-commerce</li>
                  <li>PHP</li>
                  <li>JQuery</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="col-md-6">
              <div className="show_features">
                <h3>Features</h3>
                <ul className="bullet_list">
                  <li>Payment Gateway Integration</li>
                  <li> Push Notifications</li>
                  <li> Login module for Buyer and Seller</li>
                  <li> Listing of all pets products</li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div className="functions">
                <h3>Functions</h3>
                <ul className="bullet_list">
                  <li>User-friendly interface</li>
                  <li> Detailed product descriptions</li>
                  <li> Secure payment options</li>
                  <li> Efficient customer support system</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2>Our Project Challenges</h2>
          <div className="project-points mt-4">
            <div className="points-description">
              <strong>Technical hurdles:</strong> Developing an e-commerce
              website from scratch involved a multitude of technical challenges,
              including server management, payment gateway integration, and
              security implementation.
            </div>
            <div className="points-description mt-4">
              <strong>Design requirements:</strong> The website needed to be
              visually appealing, user-friendly, and consistent with the brand
              image, which required careful attention to design elements and
              user experience.
            </div>
            <div className="points-description mt-4">
              <strong>Inventory Management:</strong> Managing a large inventory
              of products with varying attributes, such as size and color,
              required a robust content management system and data management
              practices.
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2>Solutions We Developed</h2>
          <div className="project-points mt-4">
            <div className="points-description">
              <strong>E-commerce Functionality:</strong> We integrated an
              e-commerce platform that allowed users to easily browse, select,
              and purchase products. The platform also included a secure payment
              gateway and inventory management system.
            </div>
            <div className="points-description mt-4">
              <strong>Easy Navigation:</strong> We created a simple and
              intuitive navigation structure to help users easily find what
              they're looking for on the website. This included a clear menu and
              search functionality, which enables users to quickly access
              products and information.
            </div>
            <div className="points-description mt-4">
              <strong>Custom CMS:</strong> To enable easy content management, we
              developed a custom content management system (CMS) for the
              website. This allows the client to easily update and publish new
              content, add new products to the store, and manage orders and
              customer data.
            </div>
            <div className="points-description mt-4">
              <strong>Secure Payment System: </strong> We implemented a secure
              payment system that encrypts user data and protects against fraud.
              This gives customers peace of mind when making purchases and helps
              to build trust in the brand.
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default PetCorner;
