import React from 'react'
import Banner from "../../../images/Website Development.jpg"
import lamp from "../../../images/lamp.jpg"
import mern from "../../../images/mern-stack.png"
import ror from "../../../images/ror.jpg"
import django from "../../../images/django-logo.png"
import asp from "../../../images/aspnet.png"
import wordpress from "../../../images/wordpress.png"
import why from "../../../images/why.png"
import '../../Styles/web-development.scss'
import ContactForm from '../ContactForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreativeCommonsShare } from '@fortawesome/free-brands-svg-icons'
import { faBook, faBuilding, faCoins, faLink, faPlane, faTableTennisPaddleBall, faUserNurse } from '@fortawesome/free-solid-svg-icons'

function WebDevelopment() {
  return (
    <>
      <div className='web-development-section'>
        <div className='banner'>
          <img src={Banner} alt='' />
        </div>
        <div className='container'>
          <h2 className='text-center'>Website Development</h2>
          <div className='description'>Web3inventiv is a leading Web Development Company that offers full-cycle Web development services for world-class businesses from startups to large enterprises to Fortune 500 companies across the eCommerce, Retail, Transportation, Logistics, Healthcare & Financial service industries. With 100+ technical experts and over 5+ years of experience building world-class websites, We provide various services such as web design, development, update, migration, and support. Whether a simple single-page web application or a complex enterprise-grade application our developers will help you launch your successful product.</div>
          <h4 className='feature-title mb-5'>Technology Stack Our Web Developers Use</h4>
          <div className='row justify-content-between mt-3'>
            <div className="col-md-3 feature-box">
              <img src={lamp} alt='' />
              <h6 className="text-center">LAMP Stack</h6>
              <div className="content">LAMP stands for Linux, Apache, MySQL, and PHP. It is a popular open-source technology stack  we use for building dynamic web applications.</div>
            </div>
            <div className="col-md-3 feature-box">
              <img src={mern} alt='' />
              <h6 className="text-center">MERN Stack</h6>
              <div className="content">MERN stands for MongoDB, Express.js, React, and Node.js. It is another popular full-stack JavaScript-based technology stack we use for building modern and interactive web applications.</div>
            </div>
            <div className="col-md-3 feature-box">
              <img src={ror} alt='' />
              <h6 className="text-center">Ruby on Rails</h6>
              <div className="content">Ruby on Rails is a popular web development framework based on the Ruby programming language. It follows the Model-View-Controller (MVC) architecture and is known for its simplicity, speed, and ease of use.</div>
            </div>
          </div>
          <div className='row justify-content-between mt-3'>
            <div className="col-md-3 feature-box">
              <img src={django} alt='' />
              <h6 className="text-center">Django</h6>
              <div className="content">Django is a popular web development framework based on the Python programming language. It follows the Model-View-Template (MVT) architecture and is known for its scalability, security, and versatility.</div>
            </div>
            <div className="col-md-3 feature-box">
              <img src={asp} alt='' />
              <h6 className="text-center">ASP.NET</h6>
              <div className="content">ASP.NET is a web development framework based on the .NET framework. It is known for its security, scalability, and cross-platform support.</div>
            </div>
            <div className="col-md-3 feature-box">
              <img src={wordpress} alt='' />
              <h6 className="text-center">WordPress</h6>
              <div className="content">WordPress is a popular open-source CMS platform based on PHP and MySQL. It is widely we use for building websites, blogs, and e-commerce sites.</div>
            </div>
          </div>
          <div className='personalized-nft-parent'>
            <h4 className='title'>Web Development Services</h4>
            <div className='row justify-content-between'>
              <div className='col-md-3 personalized-nft'>
                <div className='name'>Website Design</div>
                <div className='about'>This involves creating the layout, user interface, and visual elements of a website.</div>
              </div>
              <div className='col-md-3 personalized-nft'>
                <div className='name'>Front-end Development</div>
                <div className='about'>This involves building the client-side of a website using HTML, CSS, JavaScript and latest front-end frameworks such as Angular, React, etc.</div>
              </div>
              <div className='col-md-3 personalized-nft'>
                <div className='name'>Back-end Development</div>
                <div className='about'>This involves building the server-side of a website using frameworks such as PHP, Ruby on Rails, and Node.js.</div>
              </div>
            </div>
            <div className='row justify-content-between mt-5'>
              <div className='col-md-3 personalized-nft'>
                <div className='name'>Full-stack Development</div>
                <div className='about'>This involves building both the client-side and server-side of a website.</div>
              </div>
              <div className='col-md-3 personalized-nft'>
                <div className='name'>E-commerce Development</div>
                <div className='about'>This involves building online stores, shopping carts, and payment gateways.</div>
              </div>
              <div className='col-md-3 personalized-nft'>
                <div className='name'>Content Management System</div>
                <div className='about'>This involves building custom CMS solutions or integrating existing CMS platforms such as WordPress, Drupal, or Joomla.</div>
              </div>
            </div>
          </div>
        </div>
        <div className='bg-gray'>
          <div className='container'>
            <h4>Web Development Process</h4>
            <div className="timeline-center mt-5">
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>1</span>
                </div>
                <div className="tm-details">
                  <h4>Gathering Requirements</h4>
                  <p>In this phase, we works with the client to understand their business goals, user needs, and project requirements. The team creates a project plan that includes timelines, milestones, and deliverables.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>2</span>
                </div>
                <div className="tm-details">
                  <h4>Design</h4>
                  <p>In this phase, we creates wireframes, prototypes, and designs for the website or web application. They work with the client to refine the design until it meets their needs.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>3</span>
                </div>
                <div className="tm-details">
                  <h4>Development</h4>
                  <p>In this phase, we starts coding the website or web application. They may use various programming languages, frameworks, and tools to build the front-end and back-end of the website.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>4</span>
                </div>
                <div className="tm-details">
                  <h4>Testing</h4>
                  <p>In this phase, we tests the website or web application for functionality, usability, and performance. They may use automated testing tools or manual testing methods to identify and fix bugs and issues.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>5</span>
                </div>
                <div className="tm-details">
                  <h4>Deployment</h4>
                  <p>In this phase, we deploys the website or web application to a production environment. They may use various deployment tools and techniques to ensure that the website is available and accessible to users.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>6</span>
                </div>
                <div className="tm-details">
                  <h4>Maintenance</h4>
                  <p>In this phase, we provides ongoing maintenance and support for the website or web application. They may monitor the website for performance issues, security vulnerabilities, and other issues, and provide updates and fixes as needed.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='industry-parent'>
            <h4>Industry Focused Web Development</h4>
            <div className='description'>We develop websites and web applications that are tailored to meet the specific needs of a particular industry or niche. This approach involves understanding the unique challenges and opportunities facing a particular industry and designing solutions that address them.<br />Some examples of our industry-focused web development include:</div>
            <div class="testimonials-wrapper container-center mt50">
              <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 equalize aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                <div class="testimonials-item">
                  <div class="user">
                    <FontAwesomeIcon icon={faCreativeCommonsShare} />
                  </div>
                  <div class="testimonials-content">
                    <h3 class="user-name">E-commerce</h3>
                    <div class="txt">
                      <p>E-commerce websites are designed to facilitate online transactions and enable businesses to sell their products or services online. E-commerce web development typically involves integrating payment gateways, shopping carts, and other features that enable online transactions.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 equalize aos-init aos-animate" data-aos="fade-right" data-aos-delay="600">
                <div class="testimonials-item">
                  <div class="user">
                    <FontAwesomeIcon icon={faUserNurse} />
                  </div>
                  <div class="testimonials-content">
                    <h3 class="user-name">Healthcare</h3>
                    <div class="txt">
                      <p>Healthcare websites are designed to provide information and services related to healthcare, such as appointment scheduling, telemedicine, patient portals, and electronic health records (EHRs). Healthcare web development typically involves complying with regulations related to patient data privacy and security.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 equalize aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                <div class="testimonials-item">
                  <div class="user">
                    <FontAwesomeIcon icon={faBook} />
                  </div>
                  <div class="testimonials-content">
                    <h3 class="user-name">Education</h3>
                    <div class="txt">
                      <p>Education websites are designed to provide information and services related to education, such as course catalogs, registration, student portals, and learning management systems (LMSs). Education web development typically involves designing user-friendly interfaces and integrating with third-party learning tools.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 equalize aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                <div class="testimonials-item">
                  <div class="user">
                    <FontAwesomeIcon icon={faBuilding} />
                  </div>
                  <div class="testimonials-content">
                    <h3 class="user-name">Real Estate</h3>
                    <div class="txt">
                      <p>Real estate websites are designed to facilitate property sales and rentals, such as property listings, search filters, virtual tours, and mortgage calculators. Real estate web development typically involves integrating with multiple listing services (MLSs) and other real estate tools.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 equalize aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                <div class="testimonials-item">
                  <div class="user">
                    <FontAwesomeIcon icon={faCoins} />
                  </div>
                  <div class="testimonials-content">
                    <h3 class="user-name">Finance</h3>
                    <div class="txt">
                      <p>Finance websites are designed to provide financial information and services, such as online banking, investment management, and stock trading. Finance web development typically involves integrating with third-party financial data sources and complying with regulations related to financial data security.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 equalize aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                <div class="testimonials-item">
                  <div class="user">
                    <FontAwesomeIcon icon={faLink} />
                  </div>
                  <div class="testimonials-content">
                    <h3 class="user-name">Supply Chain & Logistics</h3>
                    <div class="txt">
                      <p>Simplify your transportation and logistics operations and improve productivity, enhancing your operational efficiency by leveraging our innovative web solutions.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 equalize aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                <div class="testimonials-item">
                  <div class="user">
                    <FontAwesomeIcon icon={faTableTennisPaddleBall} />
                  </div>
                  <div class="testimonials-content">
                    <h3 class="user-name">Sports</h3>
                    <div class="txt">
                      <p>Manage an entire sports ecosystem with the help of feature-rich, real-time and scalable web solutions that fulfill the needs of the users.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 equalize aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                <div class="testimonials-item">
                  <div class="user">
                    <FontAwesomeIcon icon={faPlane} />
                  </div>
                  <div class="testimonials-content">
                    <h3 class="user-name">Travel & Tourism</h3>
                    <div class="txt">
                      <p>Make the entire vacation process of travelers such as booking hotels, transport, and restaurants in an efficient way by leveraging our web solutions.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row justify-content-between why-us'>
            <div className='col-md-6'>
              <h4>Why web3inventiv for website development?</h4>
              <div className='text-justify content mt-4'>We  desigweb3inventiv develop a custom web application that brings enhanced growth for your business and helps to stay ahead of the competition. We have delivered fully functional web solutions to clients in diverse industry verticals such as banking, finance, retail, healthcare, logistics, entertainment to travel, and much more.</div>
            </div>
            <div className='col-md-5 image-parent'>
              <img src={why} alt='' />
            </div>
          </div>
          <ContactForm />
        </div>
      </div>
    </>
  )
}

export default WebDevelopment
