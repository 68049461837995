import React from "react";
import "../../../Styles//portfolio.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import wizard from "../../../../images/big-wizrd.png";
import ContactForm from "../../ContactForm";

function BigWizard() {
  return (
    <div className="container">
      <div className="row case-study-parent">
        <div className="col-md-12 mt-5">
          <div className="project-card-parent case-studies-hero-section justify-content-between d-flex pb-5">
            <div className="col-lg-5">
              <div className="project-description-parent">
                <div className="project-heading">Big rig Wizard App</div>
                <div className="project-description">
                  This app revolutionizes the trucking industry by empowering
                  drivers worldwide to quickly file claims with their insurance
                  agencies.
                </div>
                <a
                  href="https://www.bigrigwizard.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn read-btn"
                >
                  Visit...
                  <FontAwesomeIcon icon={faChevronRight} />
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-parent">
                <img src={wizard} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2 className="about-head">About</h2>
          <div className="case-description">
            The claims process for the trucking industry can be streamlined for
            drivers to submit claims quickly, eliminating paperwork and delays.
            This ensures efficiency and peace of mind on the road, paving the
            way for hassle-free experiences for truckers everywhere.
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <div className="port_obect">
            <h2>Project Objective</h2>
            <p className="case-description">
              Big Rig Wizard is an app that connect truck drivers with the
              services they need, when they need them. Allows any truck driver
              to file a claim with their insurance agency in minutes.
            </p>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <div className="row">
            <div className="col-md-6">
              <div className="concept">
                <h3>Conceptualization</h3>
                <ul className="bullet_list">
                  <li>
                    Policy management (Accessing and managing insurance
                    policies.)
                  </li>
                  <li>
                    Claims processing (Reporting accidents and filing insurance
                    claims.)
                  </li>
                  <li>
                    Communication (Facilitating communication between drivers,
                    fleet managers, and insurance providers.)
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="tech_use">
                <h3>Technologies Used</h3>
                <ul className="bullet_list">
                  <li>Swift</li>
                  <li>Kotlin</li>
                  <li>Node.js</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="show_features">
                <h3>Features</h3>
                <ul className="bullet_list">
                  <li>
                    User can submit a claim providing geolocated information,
                    Uploading pictures.
                  </li>
                  <li>User can see details of the claim.</li>
                  <li>User can communicate directly with the claimant.</li>
                  <li>Find the right insurance through agency finder</li>
                  <li>Truck financing</li>
                  <li>Roadside assistance</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2>Our Project Challenges</h2>
          <div className="project-points mt-4">
            <div className="points-description">
              Our developers were required to come up with a mobile app that
              resonates with the customers and offers the utmost level of
              convenience and comfort to truck drivers. Also, the app must be
              available for both iOS and Android.
            </div>
            <div className="points-description mt-3">
              Accuracy is the most important issue in taking a live selfies.
              Another major challenge is to prevent the uploading of duplicate
              or fake documents. The required mobile apps must also be
              performant. They should also ensure security as they will feature
              payment options and hence, demand entering sensitive financial
              information.
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2>Solutions We Developed</h2>
          <div className="project-points mt-4">
            <div className="points-description">
              We are able to develop a fully-functional and performant mobile
              app for both Android and iOS. The apps can be downloaded by
              directly going to App Store or Play Store.
            </div>
            <div className="points-description mt-3">
              Agency Sync, We have onboarded Insurance Agencies on the
              application as well. They will match the documentations and images
              while claiming.
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default BigWizard;
