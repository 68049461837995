import React from 'react'
import Banner from "../../../images/NFT Launchpad.jpg"
import nftBenifits from "../../../images/web3nft-min2.jpg"
import artist from "../../../images/artist.jpg"
import games from "../../../images/console.jpg"
import offering from "../../../images/influencer.jpg"
import musician from "../../../images/musician.jpg"
import content from "../../../images/content.jpg"
import film from "../../../images/clapboard.jpg"
import why from "../../../images/why.png"
import '../../Styles/nft-launchpad.scss'
import ContactForm from '../ContactForm'

function NftLaunchpad() {
  return (
    <>
      <div className='launchpad-section'>
        <div className='banner'>
          <img src={Banner} />
        </div>
        <div className='container'>
          <h2 className='text-center'>NFT LAUNCHPAD DEVELOPMENT</h2>
          <div className='description'>NFT launchpad development enables creators to effectively mint and launch NFT initiatives, which includes NFT Creations and promoting their NFTs. The NFT Launchpad Development simplifies the minting of the NFTs and brings up new NFT projects to the market competently. The launchpad has evolved as the marketing tool for the new NFT projects to withstand the market and enrich its user base across the globe.</div>
          <div className='benifits-parent row justify-content-between'>
            <h4 className='benifits-title'>WHY NFT LAUNCHPAD DEVELOPMENT?</h4>
            <div className='col-md-6'>
              <ul class="list">
                <li>To empower the NFT Market</li>
                <li>To Benefit the creators</li>
                <li>To empower the community</li>
                <li>To provide the experience of NFTs</li>
                <li>To build trust among the Investors</li>
                <li>To Implement a fair Minting process</li>
              </ul>
            </div>
            <div className='col-md-5 img-parent'>
              <img src={nftBenifits} />
            </div>
          </div>
          <div className='personalized-nft-parent'>
            <h4 className='title'>Personalized NFT Launchpad</h4>
            <div className='row justify-content-between'>
              <div className='col-md-3 personalized-nft'>
                <div className='img-parent'>
                  <img src={artist} />
                </div>
                <div className='name'>NFT Launchpad for Artist</div>
                <div className='about'>A simple secure platform with royalty to honor the creative artists is developed with an NFT artist launcher. This will allow the artist to showcase his or her pleasantly entertaining work worldwide.</div>
              </div>
              <div className='col-md-3 personalized-nft'>
                <div className='img-parent'>
                  <img src={games} />
                </div>
                <div className='name'>NFT Launchpad Games</div>
                <div className='about'>To tokenize items collected at the NFT launchpad, Game Players can use the NFT launchpad service to get affluence on the gaming assets in an easy way.</div>
              </div>
              <div className='col-md-3 personalized-nft'>
                <div className='img-parent'>
                  <img src={offering} />
                </div>
                <div className='name'>Initial Liquidity Offering</div>
                <div className='about'>Cryptopreneurs wrote their tokens in the DEX integrated AMM. In this way, the new token has the right market available for it. This, in turn, provides tokens, high liquidity, and short-term trading volume.</div>
              </div>
            </div>
            <div className='row justify-content-between mt-5'>
              <div className='col-md-3 personalized-nft'>
                <div className='img-parent'>
                  <img src={musician} />
                </div>
                <div className='name'>NFT Launchpad for Musicians</div>
                <div className='about'>The music industry has always been popular everywhere and the fast-paced NFT music launcher for NFTs will be a great help to musicians. This is for those who always tend to set new standards for themselves.</div>
              </div>
              <div className='col-md-3 personalized-nft'>
                <div className='img-parent'>
                  <img src={content} />
                </div>
                <div className='name'>NFT Launchpad for Content Creators</div>
                <div className='about'>This is a new and unique way to raise money where an investor has to offer a pair of token in a liquidity pool to get new tokens that have not been listed in any forums yet.</div>
              </div>
              <div className='col-md-3 personalized-nft'>
                <div className='img-parent'>
                  <img src={film} />
                </div>
                <div className='name'>NFT Launchpad for the Film Industry</div>
                <div className='about'>The film industry has a huge scope over the NFT market.</div>
              </div>
            </div>
          </div>
          <div className='personalized-nft-parent'>
            <h4 className='title'>Features of NFT launchpad development</h4>
            <div className='row justify-content-between'>
              <div className='col-md-3 personalized-nft'>
                <div className='name'>Easy launching of NFT projects</div>
                <div className='about'>The forum provides each creator a special portfolio to showcase their NFT. Projects can be started by creators and handle their portfolio independently. This enables NFT collectors to buy the collections of artists.</div>
              </div>
              <div className='col-md-3 personalized-nft'>
                <div className='name'>Building quick community</div>
                <div className='about'>A community of collectors that aim to purchase such NFTs in the appropriate art is developed by overseeing a distinctive portfolio of creators. NFT feels distinctive and exclusive, once purchased collectors will frequently return to the same portfolio.</div>
              </div>
              <div className='col-md-3 personalized-nft'>
                <div className='name'>Low transaction costs</div>
                <div className='about'>The platform may be linked into the two-layer ethereum networks and the launchpad will be modified to support different blockchain networks, allowing for seamless digestion at cheap gas prices.</div>
              </div>
            </div>
            <div className='row justify-content-between mt-5'>
              <div className='col-md-3 personalized-nft'>
                <div className='name'>High investments returns</div>
                <div className='about'>NFTs are pricey assets in the cryptocurrency market for a variety of reasons. It is an asset that offers numerous advantages due to its special characteristics and features. To create demand for your NFT and increase its market value, the launchpad regulates shortages and confirms the legitimacy of your NFT.</div>
              </div>
              <div className='col-md-3'></div>
              <div className='col-md-3'></div>
            </div>
          </div>
          <div className='business-benifits-parent'>
            <h4>Business benefits of NFT launchpad development</h4>
            <div class="testimonials-wrapper container-center mt50">
              <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 equalize aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                <div class="testimonials-item">
                  <div class="user">
                  </div>
                  <div class="testimonials-content">
                    <h3 class="user-name">New fan-base</h3>
                    <div class="txt">
                      <p>The distinctive portfolio gives the NFT their own fan base and devoted following, which also boosts traffic to the launchpad. This provides quick liquidity for newly minted NFTs and supports the launchpad to boost your NFTs.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 equalize aos-init aos-animate" data-aos="fade-right" data-aos-delay="600">
                <div class="testimonials-item">
                  <div class="user">
                  </div>
                  <div class="testimonials-content">
                    <h3 class="user-name">Range of audience</h3>
                    <div class="txt">
                      <p>Creators are able to mint and list their NFTs using NFT launchpad which also allows them to manage a separate portfolio. Through clever marketing skills that attracts a large audience to NFTs, NFT launchpad will provide projects a worldwide reach.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 equalize aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                <div class="testimonials-item">
                  <div class="user">
                  </div>
                  <div class="testimonials-content">
                    <h3 class="user-name">Royalty</h3>
                    <div class="txt">
                      <p>Each NFT that is minted and listed on the platform is eligible for royalty from the launchpad. The royalty will be applied to each NFTs transaction. When the minted NFT conducts each transaction, this provides financial assistance to the founders.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 equalize aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                <div class="testimonials-item">
                  <div class="user">
                  </div>
                  <div class="testimonials-content">
                    <h3 class="user-name">Legacy</h3>
                    <div class="txt">
                      <p>The artists are supported by the reputation and fame launchpad to unleash their creativity to launch NFTs and encourages the introduction of new trends to the NFT market which gains a reputation for the launchpad.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row justify-content-between why-us'>
            <div className='col-md-5'>
              <h4>Why Choose Web3inventiv for Staking and Yield Farming?</h4>
              <div className='text-justify content mt-4'>Web3inventiv offers development services for defi staking pools to expand the reach of DeFi staking platforms. Many crypto enthusiasts consider them exciting features of the decentralised world. Furthermore, our pool of blockchain architects and DeFi developers are guaranteed to deliver quality DeFi staking development services to launch your DeFi staking platform. </div>
            </div>
            <div className='col-md-5 image-parent'>
              <img src={why} alt="not found" />
            </div>
          </div>
          <ContactForm />
        </div>
      </div>
    </>
  )
}

export default NftLaunchpad
