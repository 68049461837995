import React from 'react'
import Banner from "../../images/contact-g961df81ab_1920.jpg"
import '../Styles/contact.scss'
import ContactForm from './ContactForm'

function Contact() {

    return (
        <div className='contact-section'>
            <div className='banner'>
                <img src={Banner} alt='' />
            </div>
            <div className='container'>
                <ContactForm/>
            </div>
        </div>
    )
}

export default Contact
