import React from "react";
import SteaveJames from "../../../images/steave.jpeg";
import UdeyCraft from "../../../images/udey.png";
import kinderNutrition from "../../../images/kinder.png";
import petCorner from "../../../images/petCorner.png";
import sportsGear from "../../../images/sgs_Logo.png";
import foreverRose from "../../../images/foreverRose.jpg";
import kos from "../../../images/kos.jpg";
import kenzDesign from "../../../images/kenz-design.png";
import "../../Styles/portfolio.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function WebPortfolio() {
  return (
    <div className="row porfolios-parent mt-3 w-100">
      <div className="col-md-12">
        <div className="project-card-parent steave-parent d-flex">
          <div className="col-lg-6">
            <div className="img-parent">
              <img src={SteaveJames} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="project-description-parent">
              <div className="project-heading">Steave and James</div>
              <div className="project-description">
                This website offers a world of elegance and sophistication,
                showcasing our collection of custom-made single-breasted suits.
                With over three decades of expertise in bespoke tailoring, we
                are committed to delivering sartorial excellence. Our bespoke
                manufacturing services in Bangkok cater to brands worldwide.
              </div>
              <Link to="/portfolio/steave-james" className="btn read-btn">
                Read more... <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-5">
        <div className="project-card-parent udey-parent d-flex">
          <div className="col-lg-6">
            <div className="project-description-parent">
              <div className="project-heading">Udey Crafts</div>
              <div className="project-description">
                Empowering creators and designers globally, Udey Crafts
                revolutionized textile manufacturing since 2001. Our innovative
                fabrics include Flannel, Mink, Velboa, Velour, and Anti-Pilling
                fabrics. As your partners in creativity, we simplify the fabric
                procurement process. Say hello to seamless transactions with
                Udey Crafts, the game-changer in textile sourcing.
              </div>
              <Link to="/portfolio/udey-crafts" className="btn read-btn">
                Read more... <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="img-parent">
              <img src={UdeyCraft} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-5">
        <div className="project-card-parent kinder-parent d-flex">
          <div className="col-lg-6">
            <div className="img-parent">
              <img src={kinderNutrition} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="project-description-parent">
              <div className="project-heading">Kinder Nutrition</div>
              <div className="project-description">
                Kinder Nutrition transforms children's health by empowering
                parents globally. Our platform offers essential knowledge on
                kids' nutrition with seven engaging features, reshaping healthy
                eating habits. Join us in nurturing young minds and bodies for a
                healthier generation.
              </div>
              <Link to="/portfolio/kinder-nutrients" className="btn read-btn">
                Read more... <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-5">
        <div className="project-card-parent pet-parent d-flex">
          <div className="col-lg-6">
            <div className="project-description-parent">
              <div className="project-heading">Pet Corner</div>
              <div className="project-description">
                We have developed Pet Corner, an innovative e-commerce platform
                that provides everything your pets need. Our seven dynamic
                features enhance your pet parenting journey for a fulfilling
                experience.
              </div>
              <Link to="/portfolio/pet-corner" className="btn read-btn">
                Read more... <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="img-parent">
              <img src={petCorner} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-5">
        <div className="project-card-parent sports-parent d-flex">
          <div className="col-lg-6">
            <div className="img-parent">
              <img src={sportsGear} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="project-description-parent">
              <div className="project-heading">Sports Gear Swag</div>
              <div className="project-description">
                Web3inventiv has created a website called Sports Gear Swag that
                caters to the needs of athletes in every game, match, and race.
                The website offers a wide selection of custom sports apparel and
                uniforms that are made from the best quality materials. These
                materials range from soft and lightweight fabrics to durable,
                moisture-wicking options.
              </div>
              <Link to="/portfolio/sports-gear" className="btn read-btn">
                Read more... <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-5">
        <div className="project-card-parent forever-rose-parent d-flex">
          <div className="col-lg-6">
            <div className="project-description-parent">
              <div className="project-heading">My Forever Rose</div>
              <div className="project-description">
                We has developed a website called "My Forever Rose" that has
                introduced an extraordinary concept of artisan flowers that
                possess eternal beauty. Forever Rose is the world's longest
                lasting rose, and their collection includes Gold Roses, Gold
                Dipped Roses, Silver Roses, and Platinum Roses.
              </div>
              <Link to="/portfolio/forever-rose" className="btn read-btn">
                Read more... <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="img-parent">
              <img src={foreverRose} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebPortfolio;
