import React from "react";
import "../../Styles/portfolio.scss";
import shareChing from "../../../images/share-ching.png";
import myVees from "../../../images/myVee.jpg";
import slotGame from "../../../images/currency-g0d0bad874_1280.jpg";
import global from "../../../images/global.jpg";
import nftExchange from "../../../images/NFT Token Development.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function BlockchainPortfolio() {
  return (
    <div className="row porfolios-parent mt-3 w-100">
      <div className="col-md-12">
        <div className="project-card-parent shareching-parent d-flex">
          <div className="col-lg-6">
            <div className="img-parent">
              <img src={shareChing} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="project-description-parent">
              <div className="project-heading">ShareChing App</div>
              <div className="project-description">
                ShareChing acts as an open source and decentralized social
                networking platform where users are rewarded with $SHARE tokens
                for contributions to the community. The goal is to build a new
                model for content creators to take back their Internet freedom,
                revenue and social reach.
              </div>
              <Link to="/portfolio/shareching" className="btn read-btn">
                Read more... <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-5">
        <div className="project-card-parent wallet-parent d-flex">
          <div className="col-lg-6">
            <div className="project-description-parent">
              <div className="project-heading">MyVEE Wallet App</div>
              <div className="project-description">
                Introducing myVee Wallet app - the revolutionary solution for
                digital currency management. Our app simplifies transactions,
                enabling users to send, receive, and store a variety of
                cryptocurrencies with ease, including MyVee, BUSD, BNB, Tron,
                and USDT.
              </div>
              <Link to="/portfolio/myVee" className="btn read-btn">
                Read more... <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="img-parent">
              <img src={myVees} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-5">
        <div className="project-card-parent global-parent d-flex">
          <div className="col-lg-6">
            <div className="img-parent">
              <img src={global} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="project-description-parent">
              <div className="project-heading">Global REIT App</div>
              <div className="project-description">
                Global REIT is the first-ever Blockchain-Based Sharia-Compliant
                REIT launched in the market. It started with a Net Asset Value
                (NAV) of USD 75 million and will reach USD 2 billion within a
                period of 3 years.
              </div>
              <Link to="/portfolio/globalReit" className="btn read-btn">
                Read more... <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-5">
        <div className="project-card-parent wallet-parent d-flex">
          <div className="col-lg-6">
            <div className="project-description-parent">
              <div className="project-heading">Slot Spin Game</div>
              <div className="project-description">
                This blockchain game allows you to spin the wheel to earn tokens
                and win rewards. Improve your skills and try your luck
                today!This blockchain-based game lets you spin the wheel to earn
                tokens as you play. Come and try your luck today!
              </div>
              <Link to="/portfolio/slotSpin" className="btn read-btn">
                Read more... <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="img-parent">
              <img src={slotGame} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-5">
        <div className="project-card-parent nft-exchange-parent d-flex">
          <div className="col-lg-6">
            <div className="img-parent">
              <img src={nftExchange} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="project-description-parent">
              <div className="project-heading">99 NFT Exchange</div>
              <div className="project-description">
                NFT exchange create, sell or collect digital items. Unit of data
                stored on a digital ledger, called a blockchain, that certifies
                a digital asset to be unique and therefore not interchangeable.
              </div>
              <Link to="/portfolio/nftExchange" className="btn read-btn">
                Read more... <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlockchainPortfolio;
