import React from "react";
import "../../../Styles//portfolio.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import shareChing from "../../../../images/shareching-home.png";
import aboutImg from "../../../../images/share-about-img.png";
import conceptImg from "../../../../images/shareching-concept.png";
import bscImg from "../../../../images/web3Binance.jpg";
import reactImg from "../../../../images/react-logo.png";
import nodeImg from "../../../../images/node-js.png";
import challengesImg from "../../../../images/shareching-project.png";
import solutionImg from "../../../../images/shareching-solution.png";
import ContactForm from "../../ContactForm";

function ShareChing() {
  return (
    <div className="case-study-parent">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12 mt-5">
            <div className="project-card-parent case-studies-hero-section align-items-center justify-content-between d-flex pb-5">
              <div className="col-lg-5">
                <div className="project-description-parent">
                  <div class="project-heading">ShareChing</div>
                  <div class="project-description">
                    ShareChing is a social platform where users earn $SHARE
                    tokens for contributing. It offers content creators a new
                    model for regaining control of their internet freedom,
                    revenue, and social reach.
                  </div>
                  {/* <a
                  href="https://kinder-nutrition.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn read-btn"
                >
                  Visit...
                  <FontAwesomeIcon icon={faChevronRight} />
                </a> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="share-img-parent">
                  <img src={shareChing} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row align-items-center mt-5">
          <div className="col-md-6">
            <div className="shareching-about-img-parent">
              <img src={aboutImg} />
            </div>
          </div>
          <div class="col-md-6">
            <h2 class="shareching-about-head about-head">About</h2>
            <div class="shareching-case-description case-description">
              It acts as an open source and decentralized social networking
              platform where users are rewarded with $SHARE tokens for
              contributions to the community. The goal is to build a new model
              for content creators to take back their Internet freedom, revenue
              and social reach.
            </div>
          </div>
        </div>
      </div>
      <div className="project-obj-parent bg-gray mt-5">
        <div className="container">
          <div className="row">
            <div class="col-md-12 mt-5">
              <div class="port_obect">
                <h2>Project Objective</h2>
                <p class="case-description">
                  World’s First User Concentric Smart Community Network.
                  Shareching is a bold initiative which leverages the power of
                  decentralization and blockchain technology to empower
                  membership-based social networking around the world. A truly
                  effective community network doesn’t just track members or
                  activities but catalyzes participation and helps content
                  creators reach their goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container concept-parent mt-5">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="concept">
              <h3>Conceptualization</h3>
              <ul class="bullet_list">
                <li>Tokenized Communities</li>
                <li>Decentralized Content Moderation</li>
                <li>NFT Integration</li>
                <li>Native token</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="concept-img-parent">
              <img src={conceptImg} />
            </div>
          </div>
        </div>
      </div>
      <div className="tech-parent mt-5 mb-5">
        <div className="container">
          <h2 className="text-center">Technologies Used</h2>
          <div className="row align-items-center justify-content-between mt-5">
            <div class="col-md-3">
              <div class="shareching-tech_use">
                <div className="img-parent">
                  <img src={bscImg} alt="" />
                </div>
                <h2>BSC Blockchain</h2>
              </div>
            </div>
            <div class="col-md-3">
              <div class="shareching-tech_use">
                <div className="img-parent">
                  <img src={reactImg} alt="" />
                </div>
                <h2>React JS</h2>
              </div>
            </div>
            <div class="col-md-3">
              <div class="shareching-tech_use">
                <div className="img-parent">
                  <img src={nodeImg} alt="" />
                </div>
                <h2>Node JS</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray mb-5">
        <div className="container">
          <h4 className="text-center">Features</h4>
          <div className="row">
            <div className="col-md-12 mt-5">
              <div className="timeline-center">
                <div className="timeline-row">
                  <div className="tm-icon">
                    <span>1</span>
                  </div>
                  <div className="tm-details">
                    <p>
                      Shareching uses $SHARE cryptocurrency token on Binance
                      Smart Chain to incentivize users.
                    </p>
                  </div>
                </div>
                <div className="timeline-row">
                  <div className="tm-icon">
                    <span>2</span>
                  </div>
                  <div className="tm-details">
                    <p>
                      All content creators can convert their creations into
                      unique NFTs for trading in a decentralized environment.
                    </p>
                  </div>
                </div>
                <div className="timeline-row">
                  <div className="tm-icon">
                    <span>3</span>
                  </div>
                  <div className="tm-details">
                    <p>
                      It operates in a partial decentralized format, leveraging
                      NFTs and cryptocurrency.
                    </p>
                  </div>
                </div>
                <div className="timeline-row">
                  <div className="tm-icon">
                    <span>4</span>
                  </div>
                  <div className="tm-details">
                    <p>
                      It uses AI tools and analytics engines to enhance
                      networking strategies and user experience.
                    </p>
                  </div>
                </div>
                <div className="timeline-row">
                  <div className="tm-icon">
                    <span>5</span>
                  </div>
                  <div className="tm-details">
                    <p>
                      ShareChing's token is built on the BSC BEP-20 standard for
                      optimized smart contract capabilities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center mt-5">
          <div class="col-md-7">
            <h2>Our Project Challenges</h2>
            <div class="project-points mt-4">
              <div class="points-description">
                Scalability is a significant concern when developing
                blockchain-based applications due to limitations in transaction
                throughput and network congestion.
              </div>
              <div class="points-description mt-3">
                Onboarding non-crypto users onto a blockchain-based social media
                platform can be challenging due to the complexity of wallet
                management and cryptocurrency transactions.
              </div>
              <div class="points-description mt-3">
                Protecting user privacy and securing sensitive data, including
                personal information and cryptographic assets.
              </div>
              <div class="points-description mt-3">
                Ensuring interoperability with other blockchain networks and
                traditional social media platforms to facilitate seamless data
                exchange and user interaction.
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="challenge-img-parent">
              <img src={challengesImg} />
            </div>
          </div>
        </div>
        <div className="row align-items-center justify-content-between mt-5">
          <div className="col-md-4 mt-5">
            <div className="solution-img-parent">
              <img src={solutionImg} />
            </div>
          </div>
          <div class="col-md-7 mt-5">
            <h2 className="solution-heading">Solutions We Developed</h2>
            <div class="project-points mt-4">
              <div class="shareching-points-description points-description">
                Optimized smart contract design and transaction processing to
                minimize gas fees and maximize efficiency.
              </div>
              <div class="shareching-points-description points-description mt-3">
                Simplified the user onboarding process by providing easy-to-use
                wallet integrations, fiat gateways, and intuitive user
                interfaces.
              </div>
              <div class="shareching-points-description points-description mt-3">
                Implemented strong encryption protocols and privacy-enhancing
                technologies to safeguard user data and ensure confidentiality.
              </div>
              <div class="shareching-points-description points-description mt-3">
                Utilized cross-chain interoperability protocols such as atomic
                swaps, bridges, or wrapped tokens to enable the transfer of
                assets and data between different blockchain networks.
              </div>
            </div>
          </div>
        </div>
        <ContactForm />
      </div>
    </div>
  );
}

export default ShareChing;
