import React from 'react'
import './privacyPolicy.scss'

function PrivacyPolicy() {
  return (
    <div className='terms-condition-section'>
      <div className='terms-condition-page common-page'>
        <div className='container'>
          <div className='title'>
            <h2>Privacy Policy</h2>
          </div>
        </div>
      </div>
      <div className='container terms-secs'>
        <div className='mt-5 mb-0 description'>This policy (together with our terms of use and any other documents referred to on it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. By visiting https://www.web3inventiv.com/ you are accepting and consenting to the practices described in this policy.</div>
        <div className='mt-3 mb-0 description'>We may collect and process the following data about you:</div>
        <div className='mt-4 mb-0 description'><strong>Information you give us: </strong>You may give us information about you by filling in forms on our or by corresponding with us by phone, e-mail or otherwise. This includes information you provide when you register to use our site, subscribe to our service, participate in discussion boards or other social media functions on our site, and when you report a problem with our site. The information you give us may include your name, address, e-mail address and phone number, financial and credit card information, personal description and photograph.</div>
        <div className='mt-3 mb-0 description'><strong>Information we collect about you: </strong>With regard to each of your visits to our website we may automatically collect the following information:</div>
        <div className='mt-2 mb-0 description'>technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform;</div>
        <div className='mt-2 mb-0 description'>information about your visit, including the full Uniform Resource Locators (URL) clickstream to, through and from our site (including date and time); products you viewed or searched for; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page and any phone number used to call our customer service number.</div>
        <div className='mt-3 description'><strong>Information we receive from other sources: </strong>We may receive information about you if you use any of the other websites we operate or the other services we provide. [In this case we will have informed you when we collected that data that it may be shared internally and combined with data collected on this site.] We are also working closely with third parties (including, for example, business partners, sub-contractors in technical, payment and delivery services, advertising networks, analytics providers, search information providers, credit reference agencies) and may receive information about you from them.</div>

        <h3>Cookies</h3>
        <div className='mt-5 mb-0 description'>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. For detailed information on the cookies we use and the purposes for which we use them see our Cookie policy.<br />We use information held about you in the following ways:</div>
        <div className='mt-3 mb-0 description'><strong>The Information you give to us: </strong>We will use this information:</div>
        <ul>
          <li className='mt-2 description mb-0'>To carry out our obligations arising from any contracts entered into between you and us and to provide you with the information, products and services that you request from us;</li>
          <li className='mt-2 description mb-0'>To provide you with information about other goods and services we offer that are similar to those that you have already purchased or enquired about;
          </li>
          <li className='mt-2 description mb-0'>To provide you, or permit selected third parties to provide you, with information about goods or services we feel may interest you. If you are an existing customer, we will only contact you by electronic means (e-mail or SMS) with information about goods and services similar to those which were the subject of a previous sale or negotiations of a sale to you. If you are a new customer, and where we permit selected third parties to use your data, we (or they) will contact you by electronic means only if you have consented to this. If you do not want us to use your data in this way, or to pass your details on to third parties for marketing purposes, please tick the relevant box situated on the form on which we collect your data if present and relevant.
          </li>
          <li className='mt-2 description mb-0'>To notify you about changes to our service;</li>
          <li className='mt-2 description mb-0'>To ensure that content from our site is presented in the most effective manner for you and for your computer.</li>
        </ul>
        <div className='mt-3 mb-0 description'><strong>Information we collect about you</strong>We will use this information:</div>
        <ul>
          <li className='mt-2 mb-0 description'>To administer our site and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;
          </li>
          <li className='mt-2 mb-0 description'>To improve our site to ensure that content is presented in the most effective manner for you and for your computer;
          </li>
          <li className='mt-2 mb-0 description'>To allow you to participate in interactive features of our service, when you choose to do so;
          </li>
          <li className='mt-2 mb-0 description'>as part of our efforts to keep our site safe and secure;
          </li>
          <li className='mt-2 mb-0 description'>To measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you;
          </li>
          <li className='mt-2 mb-0 description'>To make suggestions and recommendations to you and other users of our site about goods or services that may interest you or them.
          </li>
        </ul>
        <div className='mt-3 description'><strong>Information we receive from other sources: </strong>We may combine this information with information you give to us and information we collect about you. We may use this information and the combined information for the purposes set out above (depending on the types of information we receive).</div>

        <h3>Disclosure of your information</h3>
        <h4 className='mt-5'>We may share your information with selected third parties including:</h4>
        <ul>
          <li className='description mb-0'>Business partners, suppliers and sub-contractors for the performance of any contract we enter into with [them or] you.</li>
          <li className='description mt-2 mb-0'>Advertisers and advertising networks that require the data to select and serve relevant adverts to you and others. [We do not disclose information about identifiable individuals to our advertisers, but we may provide them with aggregate information about our users (for example, we may inform them that 500 men aged under 30 have clicked on their advertisement on any given day). We may also use such aggregate information to help advertisers reach the kind of audience they want to target (for example, women in SW1). We may make use of the personal data we have collected from you to enable us to comply with our advertisers' wishes by displaying their advertisement to that target audience].</li>
          <li className='description mt-2 mb-0'>Analytics and search engine providers that assist us in the improvement and optimization of our site.</li>
          <li className='mt-2 description'>[Credit reference agencies for the purpose of assessing your credit score where this is a condition of us entering into a contract with you.]</li>
        </ul>

        <h4>We may disclose your personal information to third parties:</h4>
        <ul>
          <li className='description mb-0'>In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets.</li>
          <li className='description mt-2 mb-0'>Web3inventiv or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets.</li>
          <li className='description mt-2'>If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of use [or terms and conditions of supply and other agreements; or to protect the rights, property, or safety of web3inventiv, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.]</li>
        </ul>

        <h4>Where we store your personal data</h4>
        <ul>
          <li className='description mb-0'>The data that we collect from you may be transferred to, and stored at, a destination outside the European Economic Area ("EEA"). It may also be processed by staff operating outside the EEA who works for us or for one of our suppliers. Such staff maybe engaged in, among other things, the fulfillment of your order, the processing of your payment details and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy.</li>
          <li className='description mt-2 mb-0'>[All information you provide to us is stored on our secure servers. Any payment transactions will be encrypted [using SSL technology].] Where we have given you (or where you have chosen) a password which enables you to access certain parts of our site, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.</li>
          <li className='description mt-2'>Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.</li>
        </ul>

        <h4>Your rights</h4>
        <div className='description mt-3'>You have the right to ask us not to process your personal data for marketing purposes. We will usually inform you (before collecting your data) if we intend to use your data for such purposes or if we intend to disclose your information to any third party for such purposes. You can exercise your right to prevent such processing by checking certain boxes on the forms we use to collect your data. You can also exercise the right at any time by contacting us at info@web3Inventiv.com.<br /><br />Our site may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.</div>

        <h4>Access to information</h4>
        <div className='description mt-3'>The Act gives you the right to access information held about you. Your right of access can be exercised in accordance with the Act. Any access request may be subject to a fee of £10 to meet our costs in providing you with details of the information we hold about you.</div>

        <h4>Changes to our privacy policy</h4>
        <div className='description mt-3'>Any changes we may make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by e-mail. Please check back frequently to see any updates or changes to our privacy policy.</div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
