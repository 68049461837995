import React from "react";
import "../../../Styles//portfolio.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import phiz from "../../../../images/phiz.png";
import ContactForm from "../../ContactForm";

function Phiz() {
  return (
    <div className="container">
      <div className="row case-study-parent">
        <div className="col-md-12 mt-5">
          <div className="project-card-parent case-studies-hero-section justify-content-between d-flex pb-5">
            <div className="col-lg-5">
              <div className="project-description-parent">
                <div className="project-heading">Phiz App</div>
                <div className="project-description">
                  Empowering users across Brazil, our innovative chat app is
                  revolutionizing the way people connect and communicate.
                </div>
                <a
                  href="https://play.google.com/store/apps/details?id=live.phiz.app&hl=en&gl=US&pli=1"
                  target="_blank"
                  rel="noreferrer"
                  className="btn read-btn"
                >
                  Visit...
                  <FontAwesomeIcon icon={faChevronRight} />
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-parent">
                <img src={phiz} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2 className="about-head">About</h2>
          <div className="case-description">
            Our chat app empowers users across Brazil with innovative features
            that enhance their chatting experience. We're revolutionizing social
            interaction one message at a time. Join us and be part of a more
            connected and engaged community in Brazil.
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <div className="port_obect">
            <h2>Project Objective</h2>
            <p className="case-description">
              With Phiz you can do everything you need in one place, safely,
              quickly and functional. With functionality and practicality you
              can connect with your friends and family,
              <br /> make transactions, access games and MiniApps.
            </p>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <div className="row">
            <div className="col-md-6">
              <div className="concept">
                <h3>Conceptualization</h3>
                <ul className="bullet_list">
                  <li>Focus on security, convenience, and innovation.</li>
                  <li>
                    Identify your target users and understand their
                    communication needs and challenges.
                  </li>
                  <li>
                    Create a cross-platform UI that's user-friendly and visually
                    appealing.
                  </li>
                  <li>
                    Ensure design consistency, branding guidelines, and
                    accessibility best practices.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="tech_use">
                <h3>Technologies Used</h3>
                <ul className="bullet_list">
                  <li>Flutter</li>
                  <li>Node.js</li>
                  <li>AWS</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="show_features">
                <h3>Features</h3>
                <ul className="bullet_list">
                  <li>
                    High-quality voice and video calling with end-to-end
                    encryption
                  </li>
                  <li>Group chat with multiple participants</li>
                  <li>
                    Security and privacy features like end-to-end encryption,
                    two-factor authentication, and privacy settings
                  </li>
                  <li>Real-time notifications</li>
                  <li>Payments feature</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2>Our Project Challenges</h2>
          <div className="project-points mt-4">
            <div className="points-description">
              Handling a large user base and increasing messaging traffic while
              maintaining performance and reliability.
            </div>
            <div className="points-description mt-3">
              Implementing real-time messaging features with low latency and
              high reliability across different devices and platforms.
            </div>
            <div className="points-description mt-3">
              Ensuring end-to-end encryption, data protection, and user privacy
              while maintaining usability and compliance with regulations.
            </div>
            <div className="points-description mt-3">
              Developing and maintaining consistent user experiences across
              multiple platforms and devices.
            </div>
            <div className="points-description mt-3">
              Ensuring seamless synchronization of messages, media files, and
              settings across multiple devices and platforms.
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2>Solutions We Developed</h2>
          <div className="project-points mt-4">
            <div className="points-description">
              Employed scalable architecture and technologies, such as cloud
              computing, microservices, and load balancing. Continuously
              monitored and optimize performance to accommodate growth.
            </div>
            <div className="points-description mt-3">
              Utilized messaging protocols like WebSockets or MQTT for real-time
              communication. Optimize network infrastructure and server
              configurations to minimize latency. Implemented error handling and
              retry mechanisms to ensure message delivery.
            </div>
            <div className="points-description mt-3">
              Implemented robust encryption algorithms like Signal Protocol for
              end-to-end encryption. Regularly audited security measures,
              conduct penetration testing, and stay updated on security best
              practices. Provided users with control over their privacy settings
              and transparent data handling policies.
            </div>
            <div className="points-description mt-3">
              Used cross-platform development frameworks Flutter to develop the
              apps for iOS and Android simultaneously. Customize UI/UX elements
              for each platform while adhering to platform-specific guidelines.
              Regularly tested the app on different devices and platforms to
              ensure compatibility.
            </div>
            <div className="points-description mt-3">
              Utilized cloud storage and synchronization services like AWS S3
              for storing and syncing user data. Implemented data conflict
              resolution strategies to handle concurrent updates from different
              devices. Provided users with options to control data
              synchronization settings, such as manual or automatic syncing.
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default Phiz;
