import React from 'react'
import '../../Styles/BlogFirst.scss'
import blogBanner from '../../../images/web5 - web3inventiv.png'
import ContactForm from '../ContactForm'

function BlogFirst() {
  return (
    <div className='first-blog-section p-5'>
      <div className='row d-flex justify-content-center'>
        <div className='col-md-10 blog-detail'>
          <h2>Exploring Web5: A New Paradigm in Decentralized Web Platforms</h2>
          <div className='img-parent'>
            <img src={blogBanner} alt='not loaded' />
          </div>
          <div className='description mt-4'>
            <span>Introduction:</span> The concept of Web3, a decentralized suite of protocols, has been gaining attention for its potential to add a censorship-resistant financial layer to the internet. However, the creator of Twitter, Jack Dorsey, surprised many by skipping ahead to what he calls "Web5." In this blog, we will delve into the concept of Web5, how it differs from Web3, and the implications it holds for the future of the internet.
          </div>
          <div className='description'>
            <span>Unveiling Web5:</span> Web5, announced in June 2022 by TBD, the crypto and decentralized finance arm of Dorsey's company Block (formerly Square), is built on Web3 technology but aims to provide an even more decentralized web platform. The name Web5 was derived by combining Web3 (blockchain-powered smart contracts) with Web2 (centralized content platforms like Twitter or Facebook), resulting in a new iteration: 3 + 2 = 5.
          </div>
          <div className='description'>
            <span>Empowering Users:</span> At its core, Web5 aims to put users in control of their data and identity, aligning with the fundamental principles of Web3's decentralized identity systems. Unlike traditional platforms where users create accounts, Web3 protocols reference users by their crypto wallet addresses. Services such as the Ethereum Name Service enable users to transform their wallet addresses into more memorable phrases, similar to how Domain Name Service (DNS) simplifies website addresses. Decentralized data storage, already provided by platforms like Filecoin and IPFS, is an essential component of Web5.
          </div>
          <div className='description'>
            <span>The Pillars of Web5:</span> Web5 focuses on three key pillars: self-owned decentralized identifiers, verifiable credentials, and decentralized web nodes for data storage and message relaying. These pillars closely resemble the functionality offered by existing decentralized identity services. The emphasis is on creating a new class of decentralized apps and protocols that prioritize individual users.
          </div>
          <div className='description'>
            <span>Differentiating from Web3:</span> One notable distinction between Web5 and Web3 is Jack Dorsey's span allegiance to Bitcoin and his skepticism towards Web3 culture. Web5 aims to eliminate the centralized actors that Dorsey believes have compromised the decentralization mission in Web3. It operates without utility tokens or subjective consensus present in governance-token-based decentralized autonomous organizations (DAOs) such as the Ethereum Name Service.
          </div>
          <div className='description'>
            <span>Web5's Integration with Web2:</span> Unlike Web3, which seeks to replace Web2 entirely, Web5 works in harmony with existing Web2 services. For instance, a Web5 user's decentralized identifier can be augmented with playlists from one service like Groove, which can then be used by another service like Tidal to curate personalized playlists. This integration eliminates the need for users to recreate preferences across multiple platforms.
          </div>
          <div className='description'>
            <span>Unlocking Data's Potential:</span> Web5 envisions a scenario where users grant various service providers access to their databases to enhance their experiences. For instance, a user can allow their hotel, airline, and rental car providers to add information to their database, which can be visualized through a user-controlled identity. This approach aims to untangle the complex web of data locked within centralized services, providing users with greater control and flexibility.
          </div>
          <div className='description'>
            <span>Challenges and Outlook:</span> While Web5 presents an intriguing vision for the future of the internet, its success remains uncertain. Currently, the Ethereum Name Service is the most prominent decentralized identity service, and IPFS leads the way in decentralized data storage. The backing of Jack Dorsey, a billionaire figure, provides a unique advantage for TBD's Web5 initiative. However, questions surrounding the monetization strategy and the motivations behind the creation of Web5 still linger.
          </div>
          <div className='description'>
            <span>Conclusion:</span> Web5 represents a further evolution in decentralized web platforms, building upon the principles of Web3 while emphasizing user control, decentralized identifiers, and integrated Web2 services. As the internet continues to evolve, Web5.
          </div>
        </div>
        <ContactForm/>
      </div>
    </div>
  )
}

export default BlogFirst
