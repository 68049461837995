import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import Services from "./Components/Pages/Services/Services";
import About from "./Components/Pages/About Us/About";
import Defi from "./Components/Pages/Blockchain/Defi";
import Staking from "./Components/Pages/Blockchain/Staking";
import LendingandBorrowing from "./Components/Pages/Blockchain/LendingandBorrowing";
import DAO from "./Components/Pages/Blockchain/DAO";
import IDO from "./Components/Pages/Blockchain/IDO";
import DecentralizedCrypto from "./Components/Pages/Blockchain/DecentralizedCrypto";
import SmartContract from "./Components/Pages/Blockchain/SmartContract";
import DAPP from "./Components/Pages/Blockchain/DAPP";
import CryptoCurrencyToken from "./Components/Pages/Blockchain/CryptoCurrencyToken";
import InitialCoinOffering from "./Components/Pages/Blockchain/InitialCoinOffering";
import StableCoin from "./Components/Pages/Blockchain/StableCoin";
import Token from "./Components/Pages/Blockchain/Token";
import NftMarketplace from "./Components/Pages/Blockchain/NftMarketplace";
import BlockchainConsulting from "./Components/Pages/BlockchainConsulting";
import NftMinting from "./Components/Pages/Blockchain/NftMinting";
import NftLaunchpad from "./Components/Pages/Blockchain/NftLaunchpad";
import NftStaking from "./Components/Pages/Blockchain/NftStaking";
import WebDevelopment from "./Components/Pages/Traditional Technology/WebDevelopment";
import AppDevelopment from "./Components/Pages/Traditional Technology/AppDevelopment";
import DigitalMarketing from "./Components/Pages/Traditional Technology/DigitalMarketing";
import Contact from "./Components/Pages/Contact";
import Portfolio from "./Components/Pages/Portfolio/Portfolio";
import BlogFirst from "./Components/Pages/Blog/BlogFirst";
import BlogSec from "./Components/Pages/Blog/BlogSec";
import AllBlog from "./Components/Pages/Blog/AllBlog";
import BlogThird from "./Components/Pages/Blog/BlogThird";
import CaseStudies from "./Components/Pages/Portfolio/CaseStudies";
import TermsCondition from "./Components/Pages/TermsCondition/TermsCondition";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy/PrivacyPolicy";
import NftForPhysical from "./Components/Pages/Blockchain/NftForPhysical";
import PetCorner from "./Components/Pages/Portfolio/websitePortfolio/PetCorner";
import SteaveJames from "./Components/Pages/Portfolio/websitePortfolio/SteaveJames";
import UdeyCrafts from "./Components/Pages/Portfolio/websitePortfolio/UdeyCrafts";
import Kinder from "./Components/Pages/Portfolio/websitePortfolio/Kinder";
import SportGear from "./Components/Pages/Portfolio/websitePortfolio/SportGear";
import ForeverRose from "./Components/Pages/Portfolio/websitePortfolio/ForeverRose";
import FitFlaunt from "./Components/Pages/Portfolio/AppPorfolio/FitFlaunt";
import BigWizard from "./Components/Pages/Portfolio/AppPorfolio/BigWizard";
import Phiz from "./Components/Pages/Portfolio/AppPorfolio/Phiz";
import Slash from "./Components/Pages/Portfolio/AppPorfolio/Slash";
import ShareChing from "./Components/Pages/Portfolio/BlockchainPortfolio/ShareChing";
import GlobalReit from "./Components/Pages/Portfolio/BlockchainPortfolio/GlobalReit";
import MyVee from "./Components/Pages/Portfolio/BlockchainPortfolio/MyVee";
import SlotSpin from "./Components/Pages/Portfolio/BlockchainPortfolio/SlotSpin";
import NftExchange from "./Components/Pages/Portfolio/BlockchainPortfolio/NftExchange";

function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/services" element={<Services />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/defi-blockchain" element={<Defi />} />
      <Route path="/staking" element={<Staking />} />
      <Route path="/lending-and-borrowing" element={<LendingandBorrowing />} />
      <Route path="/dao" element={<DAO />} />
      <Route path="/ido" element={<IDO />} />
      <Route
        path="/decentralized-crypto-exchange"
        element={<DecentralizedCrypto />}
      />
      <Route path="/smart-contract" element={<SmartContract />} />
      <Route path="/dapp" element={<DAPP />} />
      <Route path="/crypto-currency-token" element={<CryptoCurrencyToken />} />
      <Route path="/initial-coin-offering" element={<InitialCoinOffering />} />
      <Route path="/stable-coin" element={<StableCoin />} />
      <Route path="/token" element={<Token />} />
      <Route path="/nft-marketplace" element={<NftMarketplace />} />
      <Route
        path="/nft-marketplace-for-physical-assets"
        element={<NftForPhysical />}
      />
      <Route
        path="/blockchain-consultancy"
        element={<BlockchainConsulting />}
      />
      <Route path="/nft-minting" element={<NftMinting />} />
      <Route path="/nft-launchpad" element={<NftLaunchpad />} />
      <Route path="/nft-staking" element={<NftStaking />} />
      <Route path="/web-development" element={<WebDevelopment />} />
      <Route path="/app-development" element={<AppDevelopment />} />
      <Route path="/digital-marketing" element={<DigitalMarketing />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/portfolio/pet-corner" element={<PetCorner />} />
      <Route path="/portfolio/steave-james" element={<SteaveJames />} />
      <Route path="/portfolio/udey-crafts" element={<UdeyCrafts />} />
      <Route path="/portfolio/kinder-nutrients" element={<Kinder />} />
      <Route path="/portfolio/sports-gear" element={<SportGear />} />
      <Route path="/portfolio/forever-rose" element={<ForeverRose />} />
      <Route path="/portfolio/fit-flaunt" element={<FitFlaunt />} />
      <Route path="/portfolio/big-rig-wizard" element={<BigWizard />} />
      <Route path="/portfolio/phiz" element={<Phiz />} />
      <Route path="/portfolio/slash" element={<Slash />} />
      <Route path="/portfolio/shareching" element={<ShareChing />} />
      <Route path="/portfolio/globalReit" element={<GlobalReit />} />
      <Route path="/portfolio/myVee" element={<MyVee />} />
      <Route path="/portfolio/slotSpin" element={<SlotSpin />} />
      <Route path="/portfolio/nftExchange" element={<NftExchange />} />
      <Route path="/blog-1" element={<BlogFirst />} />
      <Route path="/blog-2" element={<BlogSec />} />
      <Route path="/blog-3" element={<BlogThird />} />
      <Route path="/blogs" element={<AllBlog />} />
      {/* <Route path='/case-studies' element={<CaseStudies/>}/> */}
      <Route path="/terms-conditions" element={<TermsCondition />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  );
}

export default Router;
