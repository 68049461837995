import React from 'react'
import Banner from "../../../images/Mobile-application-development.jpg"
import android from "../../../images/android.jpg"
import flutter from "../../../images/flutter.jpg"
import ios from "../../../images/ios.jpg"
import java from "../../../images/java.jpg"
import kotlin from "../../../images/kotlin.jpg"
import mongodb from "../../../images/mongodb.jpg"
import mySQL from "../../../images/mysql.jpg"
import php from "../../../images/php.jpg"
import reactJS from "../../../images/reactjs.jpg"
import swift from "../../../images/swift.jpg"
import why from "../../../images/why.png"
import '../../Styles/app-development.scss'
import ContactForm from '../ContactForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faCoins, faLink, faPlane, faTableTennisPaddleBall, faUserNurse } from '@fortawesome/free-solid-svg-icons'


function AppDevelopment() {
  return (
    <>
      <div className='app-development-section'>
        <div className='banner'>
          <img src={Banner} alt='' />
        </div>
        <div className='container'>
          <h2 className='text-center'>Mobile application development</h2>
          <div className='description'>Web3inventiv is a leading company in India with a team of mobile application developers who are very good at creating mobile applications using the latest technologies and tools. With years of experience in successful mobile app development, our mobile app developers will take your app business to the next level. We help start-ups, SMBs, agencies, and large enterprises achieve their business goals by leveraging mobile apps to meet their unique needs.</div>
          <div className='description'>Our certified app developers have in-depth knowledge of trending technologies like Flutter, Kotlin, Swift, React Native, and more. Launch feature-rich, interactive, and secure mobile apps that meet your business needs. Whether you need to build a new mobile app from scratch, modify an app you've already developed, or enhance it with add-ons and innovative features, we've got you covered.</div>
          <div className='personalized-nft-parent'>
            <h4 className='title'>Mobile App Development Services</h4>
            <div className='description'>Our mobile app developers are skilled in providing various mobile app development services that satisfies the diverse needs of the clients and take their business to the next level. We provide range of mobile application development services such as,</div>
            <ul>
              <li>Android App Development</li>
              <li>iOS App Development</li>
              <li>Cross-Platform App Development</li>
              <li>App UI/UX design</li>
              <li>App Testing</li>
            </ul>
          </div>
        </div>
        <div className='bg-gray'>
          <div className='container'>
            <h4>Steps involved in developing an Android app</h4>
            <div className="timeline-center mt-5">
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>1</span>
                </div>
                <div className="tm-details">
                  <h4>Set up your development environment</h4>
                  <p>To start developing an Android app, we'll need to set up a development environment. This involves downloading and installing Android Studio, the official Integrated Development Environment (IDE) for Android development.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>2</span>
                </div>
                <div className="tm-details">
                  <h4>Choose a programming language</h4>
                  <p>We will choose from several programming languages for Android app development, including Java, Kotlin, and C++. Java is the most commonly used language, but Kotlin has gained popularity in recent years due to its improved syntax and increased productivity.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>3</span>
                </div>
                <div className="tm-details">
                  <h4>Create a new project</h4>
                  <p>Once you have set up your development environment, we will create a new project by selecting "New Project" from the "File" menu. This will create a basic project structure with default files and folders.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>4</span>
                </div>
                <div className="tm-details">
                  <h4>Design your app's user interface</h4>
                  <p>The next step is to design your app's user interface using XML files. You can use the built-in Layout Editor in Android Studio to create the UI, or you can write the XML files manually.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>5</span>
                </div>
                <div className="tm-details">
                  <h4>Implement functionality</h4>
                  <p>After designing the UI, you'll need to implement the functionality of your app. This involves writing code to handle user interactions, data storage, and network communication.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>6</span>
                </div>
                <div className="tm-details">
                  <h4>Test your app</h4>
                  <p>Once you've implemented the functionality, it's important to test your app thoroughly. You can use the built-in emulator in Android Studio to test your app on various virtual devices, or you can connect a physical device and test.</p>
                </div>
              </div>
            </div>
            <h4 className='mt-80'>Steps involved in developing an iOS app</h4>
            <div className="timeline-center mt-5">
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>1</span>
                </div>
                <div className="tm-details">
                  <h4>Set up your development environment</h4>
                  <p>To start developing an iOS app, we'll need to set up a development environment. This involves downloading and installing Xcode, the official Integrated Development Environment (IDE) for iOS development.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>2</span>
                </div>
                <div className="tm-details">
                  <h4>Choose a programming language</h4>
                  <p>We will choose from several programming languages for iOS app development, including Swift and Objective-C. Swift is the most commonly used language for iOS app development due to its modern syntax and increased productivity.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>3</span>
                </div>
                <div className="tm-details">
                  <h4>Create a new project</h4>
                  <p>We will create a new project by selecting "New Project" from the "File" menu. This will create a basic project structure with default files and folders.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>4</span>
                </div>
                <div className="tm-details">
                  <h4>Design your app's user interface</h4>
                  <p>The next step is to design your app's user interface using Interface Builder, a built-in tool in Xcode. We can use pre-built UI components or create your own custom UI elements.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>5</span>
                </div>
                <div className="tm-details">
                  <h4>Implement functionality</h4>
                  <p> After designing the UI, you'll need to implement the functionality of your app. This involves writing code to handle user interactions, data storage, and network communication.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>6</span>
                </div>
                <div className="tm-details">
                  <h4>Test your app</h4>
                  <p>Once you've implemented the functionality, it's important to test your app thoroughly.</p>
                </div>
              </div>
            </div>
            <h4 className='mt-80'>Steps involved in cross-platform app development</h4>
            <div className="timeline-center mt-5">
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>1</span>
                </div>
                <div className="tm-details">
                  <h4>Choose a cross-platform development framework</h4>
                  <p>There are several cross-platform app development frameworks available, such as React Native, Flutter, Xamarin, and Ionic. Each framework has its own strengths and weaknesses, so it's important to choose the one that suits your needs the best.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>2</span>
                </div>
                <div className="tm-details">
                  <h4>Set up your development environment</h4>
                  <p>Once we have selected a cross-platform development framework, we'll set up a development environment. This involves downloading and installing the necessary tools and SDKs.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>3</span>
                </div>
                <div className="tm-details">
                  <h4>Design your app's user interface</h4>
                  <p>The next step is to design your app's user interface using the framework's built-in tools.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>4</span>
                </div>
                <div className="tm-details">
                  <h4>Implement functionality</h4>
                  <p>After designing the UI, we'll implement the functionality of your app. This involves writing code to handle user interactions, data storage, and network communication.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>5</span>
                </div>
                <div className="tm-details">
                  <h4>Test your app</h4>
                  <p>Once we've implemented the functionality, it's important to test the app thoroughly.</p>
                </div>
              </div>
            </div>
            <h4 className='mt-80'>Mobile App Development Process</h4>
            <div className="timeline-center mt-5">
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>1</span>
                </div>
                <div className="tm-details">
                  <h4>Define app's goals and target audience</h4>
                  <p>Before we start developing your mobile app, we need to define its goals and target audience. This will help us decide on the features and functionality you want to include in your app.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>2</span>
                </div>
                <div className="tm-details">
                  <h4>Plan and design your app's user experience</h4>
                  <p>Once we have a clear understanding of your app's goals and target audience, we can start planning and designing the user experience (UX) of your app. This includes wireframing and prototyping your app's interface and user flows.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>3</span>
                </div>
                <div className="tm-details">
                  <h4>Develop the app's backend and APIs</h4>
                  <p>Before we start building the app's frontend, we need to develop the app's backend and APIs. This involves designing and developing a server-side architecture that can handle user authentication, data storage, and other backend functionality.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>4</span>
                </div>
                <div className="tm-details">
                  <h4>Develop the app's frontend</h4>
                  <p>After we have developed the backend and APIs, we can start building the app's frontend. This involves developing the app's UI using the appropriate programming languages and tools for the targeted mobile platform(s).</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>5</span>
                </div>
                <div className="tm-details">
                  <h4>Test and refine your app</h4>
                  <p>Once the app is built, it's important to test it thoroughly to ensure it works as expected. This involves testing the app's functionality, usability, and performance. Based on the feedback received during testing, you may need to refine and make changes to the app.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='industry-parent'>
            <h4>App Development for Various Industries</h4>
            <div class="testimonials-wrapper container-center mt50">
              <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 equalize aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                <div class="testimonials-item">
                  <div class="user">
                    <FontAwesomeIcon icon={faUserNurse} />
                  </div>
                  <div class="testimonials-content">
                    <h3 class="user-name">Healthcare</h3>
                    <div class="txt">
                      <p>Healthcare apps can range from fitness and wellness apps to more specialized apps that allow users to monitor their medical conditions. For example, apps that track blood pressure, heart rate, and medication schedules can help users manage their health more effectively.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 equalize aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                <div class="testimonials-item">
                  <div class="user">
                    <FontAwesomeIcon icon={faBook} />
                  </div>
                  <div class="testimonials-content">
                    <h3 class="user-name">Education</h3>
                    <div class="txt">
                      <p>Education apps can help students and teachers to enhance the learning experience. Apps can provide access to educational materials, interactive quizzes and games, and other tools to help students study more effectively.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 equalize aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                <div class="testimonials-item">
                  <div class="user">
                    <FontAwesomeIcon icon={faCoins} />
                  </div>
                  <div class="testimonials-content">
                    <h3 class="user-name">Finance</h3>
                    <div class="txt">
                      <p>Finance apps can provide users with access to their bank accounts, investment portfolios, and financial management tools. These apps can help users to track their spending, pay bills, and manage their finances more effectively.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 equalize aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                <div class="testimonials-item">
                  <div class="user">
                    <FontAwesomeIcon icon={faTableTennisPaddleBall} />
                  </div>
                  <div class="testimonials-content">
                    <h3 class="user-name">Gaming</h3>
                    <div class="txt">
                      <p>Gaming apps are among the most popular types of mobile apps, offering users access to a wide range of games and interactive experiences. These apps can include casual games, multiplayer games, and more complex games with immersive graphics and storylines.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 equalize aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                <div class="testimonials-item">
                  <div class="user">
                    <FontAwesomeIcon icon={faLink} />
                  </div>
                  <div class="testimonials-content">
                    <h3 class="user-name">Supply Chain & Logistics</h3>
                    <div class="txt">
                      <p>We provide complete transport and logistics app solutions to digitize your entire supply chain and move your business towards sustainable operations.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 equalize aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                <div class="testimonials-item">
                  <div class="user">
                    <FontAwesomeIcon icon={faPlane} />
                  </div>
                  <div class="testimonials-content">
                    <h3 class="user-name">Travel & Tourism</h3>
                    <div class="txt">
                      <p>Make the entire vacation process of travelers such as booking hotels, transport, and restaurants in an efficient way by leveraging our web solutions.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='technologies-parent'>
            <h4 className='mt-80'>Mobile App Development Technologies We Use</h4>
            <div className='row justify-content-between mb-4 mt-80'>
              <div className="col-md-2 technologies">
                <div className='img-parent'>
                  <img src={android} alt='' />
                </div>
              </div>
              <div className="col-md-2 technologies">
                <div className='img-parent'>
                  <img src={flutter} alt='' />
                </div>
              </div>
              <div className="col-md-2 technologies">
                <div className='img-parent'>
                  <img src={ios} alt='' />
                </div>
              </div>
              <div className="col-md-2 technologies">
                <div className='img-parent'>
                  <img src={java} alt='' />
                </div>
              </div>
              <div className="col-md-2 technologies">
                <div className='img-parent'>
                  <img src={kotlin} alt='' />
                </div>
              </div>
            </div>
            <div className='row justify-content-between mb-5'>
              <div className="col-md-2 technologies">
                <div className='img-parent'>
                  <img src={mongodb} alt='' />
                </div>
              </div>
              <div className="col-md-2 technologies">
                <div className='img-parent'>
                  <img src={mySQL} alt='' />
                </div>
              </div>
              <div className="col-md-2 technologies">
                <div className='img-parent'>
                  <img src={php} alt='' />
                </div>
              </div>
              <div className="col-md-2 technologies">
                <div className='img-parent'>
                  <img src={reactJS} alt='' />
                </div>
              </div>
              <div className="col-md-2 technologies">
                <div className='img-parent'>
                  <img src={swift} alt='' />
                </div>
              </div>
            </div>
          </div>
          <div className='row justify-content-between why-us'>
            <div className='col-md-6'>
              <h4>Why Choose web3inventiv?</h4>
              <div className='text-justify content mt-4'>Use our mobile app development services to reduce your development cost, speed up time-to-market, and, most importantly push your business to the next level.</div>
              <ul>
                <li>Trusted By 150+ Clients</li>
                <li>High-Quality Code</li>
                <li>10x Better Project Tracking</li>
                <li>NDA Signed</li>
                <li>Pre/Post Deployment Support</li>
                <li>Customizable Solutions</li>
              </ul>
            </div>
            <div className='col-md-5 image-parent'>
              <img src={why} alt='' />
            </div>
          </div>
          <ContactForm />
        </div>
      </div>
    </>
  )
}

export default AppDevelopment
