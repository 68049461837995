import React from 'react'
import '../../../Components/Home/Home.scss'
import blogImg from '../../../images/blog-1.jpg'
import { Link } from 'react-router-dom'
import blogI from '../../../images/web5 - web3inventiv.png'
import blogII from '../../../images/Bitcoin NFT.jpeg'
import blogIII from '../../../images/bitcoin-investment.jpg'

function AllBlog() {
  return (
    <section className='blog-area pb-100'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='section-title'>
                <h6>Our Latest Blog</h6>
              </div>
            </div>
          </div>
          <div className='row justify-content-start'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-blog'>
                <Link to="/blog-1">
                  <div className='post-img'>
                    <img src={blogI} className="w-100 h-100" />
                  </div>
                  <div className='blog-content'>
                    <div className='blog-date mb-2'>
                      <ul className='blog-list d-flex p-0'>
                        <li><h6>20 January-2023</h6></li>
                        <li><span>|</span></li>
                        <li><a href='#'>Web5</a></li>
                      </ul>
                    </div>
                    <div className='blog-body-title'>
                      <h3><a href='#'>Exploring Web5: A New Paradigm in Decentralized Web Platforms</a></h3>
                    </div>
                    <div className='blog-body-text'>
                      <p>Web5, announced in June 2022 by TBD, is built on Web3 technology but aims to provide an even more decentralized web platform...</p>
                    </div>
                    <div className='read-more-btn mt-2'>
                      <a href='#'>+ Read More</a>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-blog'>
                <Link to="/blog-2">
                  <div className='post-img'>
                    <img src={blogII} className="w-100 h-100" />
                  </div>
                  <div className='blog-content'>
                    <div className='blog-date mb-2'>
                      <ul className='blog-list d-flex p-0'>
                        <li><h6>10 March-2023</h6></li>
                        <li><span>|</span></li>
                        <li><a href='#'>Bitcoin NFT</a></li>
                      </ul>
                    </div>
                    <div className='blog-body-title'>
                      <h3><a href='#'>Bitcoin NFTs: What Are Ordinal NFTs and How Do You Mint One?</a></h3>
                    </div>
                    <div className='blog-body-text'>
                      <p>Ordinal NFTs, also known as Bitcoin NFTs or Bitcoin Ordinals, have recently gained attention within the cryptocurrency community.</p>
                    </div>
                    <div className='read-more-btn mt-2'>
                      <a href='#'>+ Read More</a>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-blog'>
                <Link to="/blog-3">
                <div className='post-img'>
                  <img src={blogIII} className="w-100 h-100" />
                </div>
                <div className='blog-content'>
                  <div className='blog-date mb-2'>
                    <ul className='blog-list d-flex p-0'>
                      <li><h6>20 January-2022</h6></li>
                      <li><span>|</span></li>
                      <li><a href='#'>Bitcoin</a></li>
                    </ul>
                  </div>
                  <div className='blog-body-title'>
                    <h3><a href='#'>What is Bitcoin ETF? A Guide to Investing in Bitcoin Through ETFs</a></h3>
                  </div>
                  <div className='blog-body-text'>
                    <p>Bitcoin exchange-traded funds (ETFs) have emerged as a popular investment instrument, providing investors with exposure</p>
                  </div>
                  <div className='read-more-btn mt-2'>
                    <a href='#'>Read More</a>
                  </div>
                </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default AllBlog
