import React from 'react'
import Banner from "../../../images/NFT Staking Platform.jpg"
import ethereum from "../../../images/smart-contract.jpg"
import binance from "../../../images/binance-logo-g8d9787250_1280.png"
import polygon from "../../../images/Polygon Token Development.jpg"
import solana from "../../../images/Solana.png"
import cardano from "../../../images/Cardano Token Development.jpg"
import why from "../../../images/why.png"
import '../../Styles/nft-staking.scss'
import ContactForm from '../ContactForm'

function NftStaking() {
  return (
    <>
      <div className='nft-staking-section'>
        <div className='banner'>
          <img src={Banner} />
        </div>
        <div className='container'>
          <h2 className='text-center'>NFT Staking Platform</h2>
          <div className='description'>NFT Staking is one of the hot trends in the NFT space after NFT Marketplace. Apart from commissions from the trading of cryptocurrencies, staking helps to earn inactive income.NFT staking refers to locking up non-fungible tokens on a platform or protocol in exchange for staking rewards and other benefits. Staking NFTs permits holders to earn an income from their collection while maintaining ownership. These staked assets will, directly and indirectly, help in establishing a liquidity pool in the NFT space. NFT staking offers various benefits, rewards, and airdrops for staking an asset in a particular platform.</div>
          <div className='website-parent'>
            <h4 className='title'>NFT Staking Website Development on Various Blockchain</h4>
            <div className='row justify-content-between'>
              <div className='col-md-3 websites'>
                <div className='img-parent'>
                  <img src={ethereum} />
                </div>
                <div className='name'>Ethereum</div>
                <div className='about'>Launching on Ethereum gives more benefits since it gains more credibility across various business industries.</div>
              </div>
              <div className='col-md-3 websites'>
                <div className='img-parent'>
                  <img src={binance} />
                </div>
                <div className='name'>Binance Smart Chain</div>
                <div className='about'>BSC gets popular due to its unchanging gas price, faster adoption rates, and affordable transaction fees.</div>
              </div>
              <div className='col-md-3 websites'>
                <div className='img-parent'>
                  <img src={polygon} />
                </div>
                <div className='name'>Polygon</div>
                <div className='about'>Enables stakers to stake and receive rewards at a fraction of the Ethereum gas fees.</div>
              </div>
            </div>
            <div className='row justify-content-between mt-4'>
              <div className='col-md-3 websites'>
                <div className='img-parent'>
                  <img src={solana} />
                </div>
                <div className='name'>Solana</div>
                <div className='about'>High performance, facilitates people to control all staked tokens that they may have chosen to delegate.</div>
              </div>
              <div className='col-md-3 websites'>
                <div className='img-parent'>
                  <img src={cardano} />
                </div>
                <div className='name'>Cardano</div>
                <div className='about'>3rd Gen decentralized application and a public blockchain. Its numerous benefits such as quick transactions, reasonable gas fees, a higher degree of decentralization, eco-friendliness, and passive earnings.</div>
              </div>
              <div className='col-md-3'>
              </div>
            </div>
          </div>
        </div>
        <div className='bg-gray'>
          <div className='container'>
            <h4>Benefits of NFT Staking Platform</h4>
            <div className="timeline-center mt-5">
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>1</span>
                </div>
                <div className="tm-details">
                  <h4>Rewards</h4>
                  <p>The NFT staking platform will offer various rewards based on the NFT assets staked in the platform.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>2</span>
                </div>
                <div className="tm-details">
                  <h4>Airdrop</h4>
                  <p>Random airdrops are available for the users to claim in the NFT staking platform too keep them motivated in staking.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>3</span>
                </div>
                <div className="tm-details">
                  <h4>Incentives</h4>
                  <p>A constant revenue will be generated by staking the NFT, and the incentive will recursively appear as long as the NFT is staked.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>4</span>
                </div>
                <div className="tm-details">
                  <h4>Staking Rewards</h4>
                  <p>Based on the rarity of the NFT being staked, the NFT staking platform offers owners a unique set of prizes and idle earnings.</p>
                </div>
              </div>
              <div className="timeline-row">
                <div className="tm-icon">
                  <span>5</span>
                </div>
                <div className="tm-details">
                  <h4>Transparency</h4>
                  <p>The NFT staking platform will be completely transparent to the public, and this transparency will be a big help in enhancing the trust of the platform.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='digital-collection-parent'>
            <h4 className='collection-title'>Features of NFT Staking Platforms</h4>
            <div className='row justify-content-between'>
              <div className='col-md-3 digital-collection'>
                <div className='collection-name'>API Integration</div>
                <div className='about'>Wallets and other very essential APIs will be integrated with the NFT stacking platform by default. to enhance the efficiency of the platform from day zero, this API is utilized.</div>
              </div>
              <div className='col-md-3 digital-collection'>
                <div className='collection-name'>Supported Assets</div>
                <div className='about'>Protocols, and firewalls withstand any security breaches. Protection is an assertive factor when picking an NFT platform for staking.</div>
              </div>
              <div className='col-md-3 digital-collection'>
                <div className='collection-name'>Reward Calculator</div>
                <div className='about'>Rewards for the staking assets can be calculated with the inflation rate of the coin, the time duration of staking assets, and the type of asset staked on the platform.</div>
              </div>
            </div>
            <div className='row justify-content-between mt-5'>
              <div className='col-md-3 digital-collection'>
                <div className='collection-name'>Payouts</div>
                <div className='about'>It provides an exact picture of how they will acquire their shares of rewards to the people. Also displays transaction history to track their past actions.</div>
              </div>
              <div className='col-md-3 digital-collection'>
                <div className='collection-name'>Wallet Compatibility</div>
                <div className='about'>Keeping users in mind, Our developers build NFT Staking platform in a way that suits all types of wallets.</div>
              </div>
              <div className='col-md-3 digital-collection'>
                <div className='collection-name'>Assets and Protection</div>
                <div className='about'>Protocols, and firewalls withstand any security breaches. Security is a reassuring factor when choosing an NFT platform for staking.</div>
              </div>
            </div>
          </div>
          <div className='row justify-content-between why-us'>
            <div className='col-md-6'>
              <h4>Why Choose web3inventiv for NFT Staking Platform Development?</h4>
              <div className='text-justify content mt-4'>Web3inventiv, an expert in NFT Staking Platform Development, keeps track of the NFT market and its upcoming empowerment of the NFT community. Being the most reputed NFT staking development company, we offer our development services on various blockchain networks such as Ethereum, Binance Smart Chain (BSC), Polygon, Solana, and Cardano. Our elite tech team will give their support to your project until it is successfully entered into the business market.</div>
            </div>
            <div className='col-md-5 image-parent'>
              <img src={why} />
            </div>
          </div>
          <ContactForm/>
        </div>
      </div>
    </>
  )
}

export default NftStaking
