import React from 'react'
import './Footer.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'
import logo from "../../../images/logo.png"
import { Link } from 'react-router-dom';


function Footer() {
  return (
    <>
      <section className='footer-area p-100'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-md-6 footer-box-item'>
              <div className='footer-about footer-list'>
                <div className='footer-logo'><img src={logo} alt='logo' /></div>
                <div className='footer-desc mt-0 mb-2'>Trust Transparency Traceability and Temper-proof</div>
                <div className='footer-desc'>Web3inventiv is a leading, Full-Service Software Development Company for Blockchain, Metaverse, IoT, Artificial Intelligence, BOTS, Mobile, and Web Development with a special focus on Timeliness, Security, Scale, and Performance. Being a leading blockchain development company, we start with thoroughly examining how blockchain technology may help your organisation and enhance organizational trust and transparency.</div>
                <ul className='footer-social-icon mt-5 p-0'>
                  <li><a href='https://www.facebook.com/profile.php?id=100089640006280' target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                  <li><a href='https://twitter.com/web3Inventiv' target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faTwitter} /></a></li>
                  <li><a href='https://www.linkedin.com/company/web3inventiv/' target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                  <li><a href='https://www.instagram.com/web3inventiv/' target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a></li>
                </ul>
              </div>
            </div>
            <div className='col-lg-8 col-md-6 footer-box-item-parent'>
              <div className='row'>
                <div className='col-lg-2 col-md-6 footer-box-item'>
                  <div className='footer-list'>
                    <h5 className='title'>Company</h5>
                    <ul className='footer-nav-links p-0'>
                      <li><Link to="/about-us">About Us</Link></li>
                      <li><Link to="/contact-us">Contact Us</Link></li>
                      <li><Link to="/services">Our Services</Link></li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 footer-box-item'>
                  <div className='footer-list'>
                    <h5 className='title'>Digital Transformation</h5>
                    <ul className='footer-nav-links p-0'>
                      <li><Link to="/web-development">Web Development</Link></li>
                      <li><Link to="/app-development">App Development</Link></li>
                      <li><Link to="/digital-marketing">Digital Marketing</Link></li>
                      <li><Link to="/blockchain-consultancy">Blockchain consultancy</Link></li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 footer-box-item'>
                  <div className='footer-list'>
                    <h5 className='title'>Decentralized Platform</h5>
                    <ul className='footer-nav-links p-0'>
                      <li><Link to="/defi-blockchain">Defi Development</Link></li>
                      <li><Link to="/dapp">DApp Development</Link></li>
                      <li><Link to="/dao">DAO Development</Link></li>
                      <li><Link to="/smart-contract">Smart Contract Development</Link></li>
                    </ul>
                  </div>
                </div>

                <div className='col-lg-2 col-md-6 footer-box-item'>
                  <div className='footer-list'>
                    <h5 className='title'>NFT</h5>
                    <ul className='footer-nav-links p-0'>
                      <li><Link to="/nft-minting">NFT Minting</Link></li>
                      <li><Link to="/nft-marketplace">NFT Marketplace</Link></li>
                      <li><Link to="/nft-staking">NFT Staking</Link></li>
                      <li><Link to="/nft-launchpad">Nft Launchpad</Link></li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 footer-box-item'>
                  <div className='footer-list'>
                    <h5 className='title'>CryptoCurrency</h5>
                    <ul className='footer-nav-links p-0'>
                      <li><Link to="/crypto-currency-token">CryptoCurrency Token</Link></li>
                      <li><Link to="/initial-coin-offering">ICO</Link></li>
                      <li><Link to="/stable-coin">Stable Coin</Link></li>
                      <li><Link to="/token">Token Development</Link></li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-5 col-md-6 footer-box-item'>
                  <div className='footer-list'>
                    <div className='title'>Contact Info</div>
                    <div className='footer-contact-info'>
                      <ul className='footer-contact-list p-0'>
                        <li>
                          <span>Address:</span> 2306, Ground Floor, Sector 20-C, Chandigarh
                        </li>
                        <li>
                          <span>Phone:</span> +91-8287043028
                        </li>
                        <li>
                          <span>Email:</span>  info@web3inventiv.com
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='copyright-area'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 col-md-6'>
              <div className='copyright-text'>© 2021 - All Rights Reserved - Designed by <span className='author'>Web3 Inventiv</span></div>
            </div>
            <div className='col-lg-6 col-md-6 text-center'>
              <div className='terms'><Link to="/terms-conditions">Terms & Conditions</Link> | <Link to="/privacy-policy">Privacy Policy</Link></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
