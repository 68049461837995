import React from "react";
import "../../../Styles//portfolio.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import wallet from "../../../../images/myvee-wallet.jpg";
import ContactForm from "../../ContactForm";

function MyVee() {
  return (
    <div className="container">
      <div className="row case-study-parent">
        <div className="col-md-12 mt-5">
          <div className="project-card-parent case-studies-hero-section justify-content-between d-flex pb-5">
            <div className="col-lg-5">
              <div className="project-description-parent">
                <div class="project-heading">MyVee Wallet</div>
                <div class="project-description">
                  Crypto wallet application. In this you can send, receive and
                  store MyVee(BEP20), BUSD(BEP20), BNB(BEP20),
                  Tron(TRC20),USDT(TRC20).
                </div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.app.myveewallet&hl=en_US"
                  target="_blank"
                  rel="noreferrer"
                  className="btn read-btn"
                >
                  Visit...
                  <FontAwesomeIcon icon={faChevronRight} />
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-parent">
                <img src={wallet} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <h2 class="about-head">About</h2>
          <div class="case-description">
            Introducing myVee Wallet app - the revolutionary solution for
            digital currency management. Our app simplifies transactions,
            enabling users to send, receive, and store a variety of
            cryptocurrencies with ease, including MyVee, BUSD, BNB, Tron, and
            USDT.
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <div class="port_obect">
            <h2>Project Objective</h2>
            <p class="case-description">
              MYVEE Wallet is a crypto wallet. In this you can send, receive and
              store MyVee(BEP20), BUSD(BEP20), BNB(BEP20),
              Tron(TRC20),USDT(TRC20).
              <br /> Users can even use MYVEE Wallet to access Pancakeswap.
              Whether you are looking for a Tron wallet or Smartchain Wallet,
              MyVee provides a secure system that makes it simple to buy.
            </p>
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <div class="row">
            <div class="col-md-6">
              <div class="concept">
                <h3>Conceptualization</h3>
                <ul class="bullet_list">
                  <li>Multi-currency support</li>
                  <li>Secure storage with encryption</li>
                  <li>Backup and recovery options</li>
                  <li>User-friendly interface</li>
                  <li>Transaction management</li>
                  <li>Real-time market data</li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div class="tech_use">
                <h3>Technologies Used</h3>
                <ul class="bullet_list">
                  <li>Solidity</li>
                  <li>React native</li>
                  <li>Blockchain(Binance)</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="show_features">
                <h3>Features</h3>
                <ul class="bullet_list">
                  <li>Buy crypto</li>
                  <li>Receive crypto</li>
                  <li>Swap</li>
                  <li>Wallet (QR code and address for balance top-up.)</li>
                  <li> Access to Pancakeswap</li>
                  <li> Support</li>
                  <li> Price alert</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <h2>Our Project Challenges</h2>
          <div class="project-points mt-4">
            <div class="points-description">
              Security is paramount in crypto wallet development due to the risk
              of theft or hacking. Vulnerabilities in the app could result in
              the loss of users' funds.
            </div>
            <div class="points-description mt-3">
              Cryptocurrency regulations vary by jurisdiction, and compliance
              can be complex and challenging to navigate.
            </div>
            <div class="points-description mt-3">
              Developing a crypto wallet app that works seamlessly across
              multiple platforms (such as iOS, Android, and web) can be
              challenging and time-consuming.
            </div>
            <div class="points-description mt-3">
              As the number of users and transactions grows, scalability becomes
              a concern. The app must be able to handle increased traffic and
              transaction volume without performance degradation.
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <h2>Solutions We Developed</h2>
          <div class="project-points mt-4">
            <div class="points-description">
              Employ robust security measures such as encryption, secure
              authentication methods (like biometric authentication or
              two-factor authentication), secure storage of private keys, and
              regular security audits. Follow best practices for secure coding
              and implement protocols to handle potential security breaches
              effectively.
            </div>
            <div class="points-description mt-3">
              Stay informed about regulatory requirements in the regions where
              the app will be available. Work with legal experts to ensure
              compliance with relevant laws and regulations, such as Know Your
              Customer (KYC).
            </div>
            <div class="points-description mt-3">
              Choose technologies and frameworks that support cross-platform
              development, React Native.
            </div>
            <div class="points-description mt-3">
              Design the app with scalability in mind from the outset. Use
              scalable architectures and technologies that can accommodate
              growth, such as cloud hosting services and microservices
              architecture. Continuously monitor performance metrics and
              optimize the app as needed to ensure scalability.
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default MyVee;
