import React from "react";
import "../../../Styles//portfolio.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import sgs from "../../../../images/sgs.jpg";
import ContactForm from "../../ContactForm";

function SportGear() {
  return (
    <div className="container">
      <div className="row case-study-parent">
        <div className="col-md-12 mt-5">
          <div className="project-card-parent case-studies-hero-section justify-content-between d-flex pb-5">
            <div className="col-lg-5">
              <div className="project-description-parent">
                <div className="project-heading">Sports Gear Swag</div>
                <div className="project-description">
                  Providing custom sports apparel and is a top provider in the
                  market, offering high-quality products, excellent service, and
                  competitive prices.
                </div>
                <a
                  href="https://www.sportsgearswag.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn read-btn"
                >
                  Visit... <FontAwesomeIcon icon={faChevronRight} />
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-parent">
                <img src={sgs} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2 className="about-head">About</h2>
          <div className="case-description">
            It is an innovative e-commerce platform developed to cater to the
            needs of athletes who need the best customize products to play their
            sport. The platform offers a comprehensive range of high-quality
            supplies and accessories.
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <div className="port_obect">
            <h2>Project Objective</h2>
            <p className="case-description">
              User can purchase customized (Name, colour, size, print, style,
              fabric, neckline, number etc) sports Jersey for following sports:
              <br />
              Basketball, Baseball, Softball, Football, Volleyball, Soccer,
              Hockey, Lacrosse, Cycling, Tennis, Cricket, Pickleball
            </p>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <div className="row">
            <div className="col-md-6">
              <div className="concept">
                <h3>Conceptualization</h3>
                <ul className="bullet_list">
                  <li>Easy navigation</li>
                  <li>Competitive pricing</li>
                  <li>Excellent customer service</li>
                  <li>A user-friendly online platform</li>
                  <li>Recorded webinars on platform</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div class="tech_use">
                <h3>Technologies Used</h3>
                <ul class="bullet_list">
                  <li>Opencart</li>
                  <li>PHP</li>
                  <li>JQuery</li>
                  <li>AWS</li>
                  <li>Apache server</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="col-md-6">
              <div className="show_features">
                <h3>Features</h3>
                <ul className="bullet_list">
                  <li>Payment Gateway Integration</li>
                  <li>Live chat</li>
                  <li>Push Notifications</li>
                  <li>Login module for Buyer and Seller</li>
                  <li>Listing of all products</li>
                  <li>Packages detail page</li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div className="functions">
                <h3>Functions</h3>
                <ul className="bullet_list">
                  <li>User-friendly interface</li>
                  <li> Detailed product descriptions</li>
                  <li>Efficient customer support system</li>
                  <li>Secure payment options</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2>Our Project Challenges</h2>
          <div className="project-points mt-4">
            <div className="points-description">
              <strong>Technical hurdles:</strong> Developing an e-commerce
              website from scratch involved a multitude of technical challenges,
              including server management, payment gateway integration, and
              security implementation.
            </div>
            <div className="points-description">
              <strong>Design requirements:</strong> The website needed to be
              visually appealing, user-friendly, and consistent with the brand
              image, which required careful attention to design elements and
              user experience.
            </div>
            <div className="points-description">
              <strong>Inventory Management:</strong> Managing a large inventory
              of products with varying attributes, such as size and color,
              required a robust content management system and data management
              practices.
            </div>
            <div className="points-description">
              <strong>Maintenance and Upgrades:</strong> E-commerce websites
              require ongoing maintenance, updates, and feature enhancements.
              The development team faced challenges in managing post-launch
              maintenance, bug fixes, and implementing new features or
              improvements. They had to balance the need for continuous updates
              with minimal disruption to the live website and ensure seamless
              deployment of patches and upgrades.
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2>Solutions We Developed</h2>
          <div className="project-points mt-4">
            <div className="points-description">
              <strong>E-commerce Functionality:</strong> We integrated an
              e-commerce platform that allowed users to easily browse, select,
              and purchase products. The platform also included a secure payment
              gateway and inventory management system.
            </div>
            <div className="points-description mt-4">
              <strong>Easy Navigation:</strong> We created a simple and
              intuitive navigation structure to help users easily find what
              they're looking for on the website. This included a clear menu and
              search functionality, which enables users to quickly access
              products and information.
            </div>
            <div className="points-description mt-4">
              <strong>Custom CMS:</strong> To enable easy content management, we
              developed a custom content management system (CMS) for the
              website. This allows the client to easily update and publish new
              content, add new products to the store, and manage orders and
              customer data.
            </div>
            <div className="points-description mt-4">
              <strong>Secure Payment System: </strong> We implemented a secure
              payment system that encrypts user data and protects against fraud.
              This gives customers peace of mind when making purchases and helps
              to build trust in the brand.
            </div>
            <div className="points-description mt-4">
              <strong>Maintenance and Upgrades: </strong> Established a robust
              maintenance plan for regular bug fixes, security updates, and
              performance optimizations. Implemented version control systems to
              manage code changes and facilitate collaborative development.
              Utilized staging environments for testing new features or upgrades
              before deploying them to the live website.Employed agile
              development methodologies to efficiently prioritize and implement
              new feature requests.
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default SportGear;
