import React from "react";
import fitFluant from "../../../images/fitFluant.png";
import bigwizard from "../../../images/bigrigwizard.png";
import theTbt from "../../../images/thetbtnetwork.png";
import SteaveJames from "../../../images/Steaveandjames.png";
// import UdeyCraft from "../../../images/udeyCraft.png";
// import kinderNutrition from "../../../images/kinder-nutrition.png";
import petCorner from "../../../images/pet-corner.png";
import sportsGear from "../../../images/sportsgearswag.png";
// import foreverRose from "../../../images/my-forever-rose.png";
// import kos from "../../../images/kos.png";
// import kenzDesign from "../../../images/kenzDesigns.png";
// import keralaayurvedaclinic from "../../../images/keralaayurvedaclinic.png";
import shareChing from "../../../images/shareching.png";
// import fitImg from "../../../images/fit2flaunt.png";
// import "../../Styles/portfolio.scss";
// import FontAwesome from "react-fontawesome";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowRight, faChevronCircleRight, faChevronRight } from "@fortawesome/free-solid-svg-icons";

function AllPortfolio() {
  return (
    <div className="row porfolios-parent mt-3 w-100">
      <a
        href="https://api.fit2flaunt.com/"
        className="col-md-3 feature-box"
        target="_blank"
        rel="noreferrer"
      >
        <img src={fitFluant} alt="" />
        <h6 className="text-center">Fit2Flaunt</h6>
      </a>
      <a
        href="https://www.shareching.com"
        className="col-md-3 feature-box"
        target="_blank"
        rel="noreferrer"
      >
        <img src={shareChing} alt="" />
        <h6 className="text-center">ShareChing</h6>
      </a>
      <a
        href="https://www.bigrigwizard.com/"
        target="_blank"
        className="col-md-3 feature-box"
        rel="noreferrer"
      >
        <img src={bigwizard} alt="" />
        <h6 className="text-center">Bigrig Wizard</h6>
      </a>
      <a
        href="https://www.thetbtnetwork.com/home-page"
        target="_blank"
        className="col-md-3 feature-box"
        rel="noreferrer"
      >
        <img src={theTbt} alt="" />
        <h6 className="text-center">The TBT Network</h6>
      </a>
      <a
        href="https://www.steveandjames.com/"
        target="_blank"
        className="col-md-3 feature-box"
        rel="noreferrer"
      >
        <img src={SteaveJames} alt="" />
        <h6 className="text-center">Steave & James</h6>
      </a>
      <a
        href="https://udeycrafts.com/"
        target="_blank"
        className="col-md-3 feature-box"
        rel="noreferrer"
      >
        {/* <img src={UdeyCraft} alt="" /> */}
        <h6 className="text-center">Udey Crafts</h6>
      </a>
      <a
        href="https://kinder-nutrition.com/"
        target="_blank"
        className="col-md-3 feature-box"
        rel="noreferrer"
      >
        {/* <img src={kinderNutrition} alt="" /> */}
        <h6 className="text-center">Kinder Nutrition</h6>
      </a>
      <a
        href="https://www.petshopinuae.com/"
        target="_blank"
        className="col-md-3 feature-box"
        rel="noreferrer"
      >
        <img src={petCorner} alt="" />
        <h6 className="text-center">Pet Corner</h6>
      </a>
      <a
        href="https://www.sportsgearswag.com/"
        target="_blank"
        className="col-md-3 feature-box"
        rel="noreferrer"
      >
        <img src={sportsGear} alt="" />
        <h6 className="text-center">Sports Gear Swag</h6>
      </a>
      <a
        href="https://myforeverrose.com/"
        target="_blank"
        className="col-md-3 feature-box"
        rel="noreferrer"
      >
        {/* <img src={foreverRose} alt="" /> */}
        <h6 className="text-center">My Forever Rose</h6>
      </a>
      <a
        href="https://kos.com/"
        target="_blank"
        className="col-md-3 feature-box"
        rel="noreferrer"
      >
        {/* <img src={kos} alt="" /> */}
        <h6 className="text-center">KOS</h6>
      </a>
      <a
        href="https://www.kenzdesigns.com.au/"
        target="_blank"
        className="col-md-3 feature-box"
        rel="noreferrer"
      >
        {/* <img src={kenzDesign} alt="" /> */}
        <h6 className="text-center">KENZ Designs</h6>
      </a>
      <a
        href="https://keralaayurvedaclinic.com/"
        target="_blank"
        className="col-md-3 feature-box"
        rel="noreferrer"
      >
        {/* <img src={keralaayurvedaclinic} alt="" /> */}
        <h6 className="text-center">Kerala Ayurveda Clinic</h6>
      </a>
    </div>
  );
}

export default AllPortfolio;
