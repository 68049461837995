import React from "react";
import "./termsCondition.scss";

function TermsCondition() {
  return (
    <div className="terms-condition-section">
      <div className="terms-condition-page common-page">
        <div className="container">
          <div className="title">
            <h2>Terms and Condition</h2>
          </div>
        </div>
      </div>
      <div className="container terms-secs">
        <div className="py-5 website-link">
          <div>
            <strong>Website Covered:</strong> https://web3inventiv.com/
          </div>
        </div>
        <div>
          <h4>The Agreement:</h4>
          <div className="description">
            The utilization of this website and the services offered by
            Web3Inventiv Technologies (referred to as the "Owner") is contingent
            upon the following Terms & Conditions, commonly known as the "Terms
            of Service." These terms encompass all sections and subsections, all
            of which are expressly included and referred to herein. These Terms
            of Service pertain to your interaction with www.we3inventiv.com
            (referred to as the "Website"), including all its pages, as well as
            any services offered through or on this Website ("Services").
            <br />
            <br />
            By accessing either directly or through a hyperlink, the Website,
            and/ or purchasing something from us, you engage in our “Service”
            and agree to be bound by the Terms of Service including those
            additional terms and conditions and policies referenced herein
            and/or available by hyperlink. These Terms of Service apply to all
            users of the site, including without limitation vendors, buyers,
            customers, merchants, browsers, and/ or contributors of content.
          </div>
          <h4 className="mt-5">Definition</h4>
          <div className="mt-2 description mb-0">
            The parties referred to in these Terms of Service shall be defined
            as follows:
          </div>
          <div className="mt-1 mb-0 description">
            <strong>(i) Owner, Us, We:</strong> The Owner, as the creator,
            operator, and publisher of the Website makes the Website, and
            certain Services on it, available to users. Web3inventiv, Owner, Us,
            We, Our, Ours, and other first-person pronouns will refer to the
            Owner, as well as all employees and affiliates of the Owner.
          </div>
          <div className="mt-3 mb-0 description">
            <strong>(ii) You, the User, the Client:</strong> You, as the user of
            the Website, will be referred to throughout these Terms of Service
            with second-person pronouns such as You, Your, Yours, or as User or
            Client. For the purpose of these Terms of Service, the term ‘User’
            or ‘you’ shall mean any natural or legal person who person is
            accessing the Website. The term ‘Your’ shall be construed
            accordingly.
          </div>
          <div className="mt-3 mb-0 description">
            <strong>(iii) Parties:</strong> Collectively, the parties to these
            Terms of Service (the Owner and You) will be referred to as Parties.
          </div>

          <h4 className="mt-5">Assent & Acceptance</h4>
          <div className="mt-3 description">
            By accessing the Website, you confirm that you have read and
            examined these Terms of Service, and you consent to abide by them.
            If you do not agree to comply with these Terms of Service, kindly
            exit the Website promptly. The Owner will only grant access to this
            Website and its Services if you accept these Terms of Service.
            Additionally, specific Services may have extra terms and conditions
            based on the User's selection, forming an agreement between the
            Users and the Owner.
          </div>
          <h4>Age Restriction</h4>
          <div className="mt-3 description">
            To use this Website or any Services offered here, you need to be at
            least 18 (thirteen) years old. By accessing this Website, you
            confirm that you are at least 18 years old and have the legal
            capacity to accept these Terms of Service. The Owner is not
            responsible for any inaccurate age information provided by you.
          </div>
          <h4>About The Site</h4>
          <div className="mt-3 description">
            The Website is an online store which carries out sale of the
            following learning courses. We reserve the right to refuse service
            or refuse to sell the products on the Website at our sole discretion
            to anyone for any reason at any time.
            <br />
            The Website does not screen or censor the users who register on and
            access the Website. You assume all risks associated with dealing
            with other users with whom you come in contact through the Website.
            You agree to use the Website only for lawful purposes without
            infringing the rights or restricting the use of this Website by any
            third party.
          </div>
          <h4>License To Use Website</h4>
          <div className="mt-3 description">
            Through your use of the Website or Services, the Owner may provide
            you with certain information, such as documentation, data, or
            materials created by the Owner, collectively referred to as "Owner
            Materials." Subject to these Terms of Service, the Owner gives you a
            limited, non-transferable, and revocable license to use the Owner
            Materials exclusively for your use of the Website and Services.
            These materials cannot be used for any other purpose, and this
            license ends when you stop using the Website or Services or when
            these Terms of Service are terminated.
            <br />
            You agree not to collect contact information of other Users from the
            Website or download or copy any information by means of unsolicited
            access so as to communicate directly with them or for any reason
            whatsoever.
            <br />
            Any unauthorized use by you shall terminate the permission or
            license granted to you by the Website and You agree that you shall
            not bypass any measures used by the Owner to prevent or restrict
            access to the Website.
          </div>
          <h4>Intellectual Property</h4>
          <div className="mt-3 mb-0 description">
            You agree that the Website and all Services provided by the Owner
            are the property of the Owner, including all copyrights, trademarks,
            trade secrets, patents, and other intellectual property (“Owner
            IP”). You agree that the Owner owns all rights, title, and interest
            in and to the Owner IP and that You will not use the Owner IP for
            any unlawful or infringing purpose. You agree not to reproduce or
            distribute the Owner IP in any way, including electronically or via
            registration of any new trademarks, trade names, service marks, or
            Uniform Resource Locators (URLs), without express written permission
            from the Owner.
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(i) </strong> In order to make the Website and Services
            available to You, You hereby grant the Owner a royalty-free,
            non-exclusive, worldwide license to copy, display, use, broadcast,
            transmit and make derivative works of any content You publish,
            upload or otherwise make available to the Website (‘Your Content’).
            The Owner claims no further proprietary rights in Your Content.
          </div>
          <div className="mt-2 description">
            <strong>(ii) </strong> If You feel that any of Your intellectual
            property rights have been infringed or otherwise violated by the
            posting of information or media by another of Our users, please
            Contact Us and let Us know.
          </div>
          <h4>User Obligations</h4>
          <div className="mt-3 description">
            As a user of the Website or Services, You may be asked to register
            with Us. When You do so, You will choose a user identifier, which
            may be Your email address or another term, as well as a password.
            You may also provide personal information, including, but not
            limited to, Your name. You are responsible for ensuring the accuracy
            of this information. This identifying information will enable You to
            use the Website and Services. You must not share such identifying
            information with any third party and if You discover that Your
            identifying information has been compromised, You agree to notify Us
            immediately in writing. An email notification will suffice. You are
            responsible for maintaining the safety and security of Your
            identifying information as well as keeping Us apprised of any
            changes to Your identifying information. The billing information You
            provide us, including credit card, billing address and other payment
            information, is subject to the same confidentiality and accuracy
            requirements as the rest of Your identifying information. Providing
            false or inaccurate information, or using the Website or Services to
            further fraud or unlawful activity is grounds for immediate
            termination of these Terms of Service. The Owner reserves the right
            to refuse service, terminate accounts, or remove or edit content in
            its sole discretion.
          </div>
          <h4>Payment & Fees</h4>
          <div className="mt-3 description">
            If you choose to register for paid Services or buy any product or
            service on this website, you are agreeing to pay the specified
            monetary amounts associated with that product or those Services.
            These amounts, referred to as "Fees," will be clearly outlined to
            you during your account registration and/or confirmation process.
            The exact payment amount will be displayed to you right before the
            purchase is finalized. For continuous Services, payments are
            automatically billed until you inform us of your desire to terminate
            your access to the Services.
            <br />
            <br />
            We reserve the right to refuse service or refuse to sell the
            products on the Website at our sole discretion to anyone for any
            reason at any time.
          </div>
          <h4>Acceptable Use</h4>
          <div className="mt-3 mb-0 description">
            You agree not to use the Website or Services for any unlawful
            purpose or any purpose prohibited under this clause. You agree not
            to use the Website or Services in any way that could damage the
            Website, Services, or general business of the Owner.
            <br />
            You further agree not to use the Website or Services:
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(i) </strong> To harass, abuse, or threaten others or
            otherwise violate any person’s legal rights;
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(ii) </strong> To violate any intellectual property rights
            of the Owner or any third party;
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(iii) </strong> To upload or otherwise disseminate any
            computer viruses or other software that may damage the property of
            another;
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(iv) </strong> To perpetrate any fraud;
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(v) </strong> To engage in or create any unlawful gambling,
            sweepstakes, or pyramid scheme;
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(vi) </strong> To publish or distribute any obscene or
            defamatory material;
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(vii) </strong> To publish or distribute any material that
            incites violence, hate or discrimination towards any group;
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(viii) </strong> To unlawfully gather information about
            others.
          </div>
          <div className="mt-3 mb-0 description">
            <strong>
              You are prohibited from using the site or its content:
            </strong>
          </div>

          <div className="mt-2 mb-0 description">
            <strong>(i) </strong> For any unlawful purpose;
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(ii) </strong> To solicit others to perform or participate
            in any unlawful acts;
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(iii) </strong> To infringe on any third party’s
            intellectual property or proprietary rights, or rights of publicity
            or privacy, whether knowingly or unknowingly;
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(iv) </strong> To violate any local, federal or
            international law, statute, ordinance or regulation;
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(v) </strong> To harass, abuse, insult, harm, defame,
            slander, disparage, intimidate, or discriminate based on gender,
            sexual orientation, religion, ethnicity, race, age, national origin,
            or disability;
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(vi) </strong> To submit false or misleading information or
            any content which is defamatory, libelous, threatening, unlawful,
            harassing, indecent, abusive, obscene, or lewd and lascivious or
            pornographic, or exploits minors in any way or assists in human
            trafficking or content that would violate rights of publicity and/or
            privacy or that would violate any law;
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(vii) </strong> To upload or transmit viruses or any other
            type of malicious code that will or may be used in any way that will
            affect the functionality or operation of the Service or of any
            related website, other websites, or the Internet;
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(viii) </strong> To collect or track the personal
            information of others;
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(ix) </strong> To damage, disable, overburden, or impair the
            Website or any other party’s use of the Website; to spam, phish,
            pharm, pretext, spider, crawl, or scrape;
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(x) </strong> For any obscene or immoral purpose;
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(xi) </strong> To interfere with or circumvent the security
            features of the Service or any related website, other websites, or
            the Internet;
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(xii) </strong> To personally threaten or has the effect of
            personally threatening other Users.
          </div>

          <div className="mt-3 mb-0 description">
            We have the authority to end your use of the Service, application,
            or any associated website if you violate any prohibited uses. The
            Company has the complete right to assess all content posted by users
            on the Website. You understand that the Website is not accountable
            for the content posted by you or other users, and you are solely
            responsible for it. You agree not to upload, post, or transmit any
            content for which you don't have the necessary rights, such as
            another party's intellectual property.
          </div>

          <div className="mt-2 mb-0 description">
            You are obligated to adhere to all relevant laws and regulations
            related to your use of the Website. Additionally, you agree not to
            transmit any information, data, text, files, links, software, chats,
            communication, or other materials that are abusive, invasive of
            someone else's privacy, harassing, defamatory, vulgar, obscene,
            unlawful, false, misleading, harmful, threatening, hateful, or
            racially or otherwise objectionable. This includes, but is not
            limited to, any material that promotes activities that could lead to
            criminal offenses, civil liability, or violation of any applicable
            local, state, provincial, national, or international law or
            regulation. Moreover, you must refrain from encouraging the use of
            controlled substances.
          </div>
          <div className="mt-2 description">
            We may, but have no obligation to, monitor, edit or remove content
            that we determine in our sole discretion are unlawful, offensive,
            threatening, libelous, defamatory, pornographic, obscene or
            otherwise objectionable or violates any party’s intellectual
            property or these Terms of Service.
            <br />
            You may not use our products for any illegal or unauthorized purpose
            nor may you, in the use of the Service, violate any laws in your
            jurisdiction (including but not limited to copyright laws).
          </div>

          <h4>Communication</h4>
          <div className="mt-3 description">
            Every time you use the Website, you are confirming your acceptance
            of these Terms. By agreeing to these Terms, you acknowledge your
            interest in accessing and purchasing the selected Services. You also
            consent to receive communications via phone or electronic means from
            the Website, including emails informing you about the products and
            services provided by the Website, its affiliates, and partners, and
            understanding your needs. Notices may also be posted directly on the
            Website. You understand and agree that the communications sent to
            you by the Website will not be considered spam or bulk messages, as
            per the laws applicable in the country where you receive these
            communications.
          </div>

          <h4>Affiliates Marketing & Advertising</h4>
          <div className="mt-3 description">
            The Owner utilizes affiliate marketing on the Website and Services,
            earning a commission or percentage from sales of goods or services
            made through the Website. Additionally, the Owner may accept
            advertising, sponsorships from commercial businesses, or other forms
            of advertising compensation.
          </div>

          <h4> Privacy Information</h4>
          <div className="mt-3 mb-0 description">
            Through Your Use of the Website and Services, You may provide Us
            with certain information. By using the Website or the Services, You
            authorize the Owner to use Your information in India and any other
            country where We may operate.
          </div>

          <div className="mt-2 mb-0 description">
            <strong>(i) Information We May Collect or Receive:</strong>When You
            register for an account, You provide Us with a valid email address
            and may provide Us with additional information, such as Your name or
            billing information. Depending on how You use Our Website or
            Services, We may also receive information from external applications
            You use to access Our Website, or We may receive information through
            various web technologies, such as cookies, log files, clear gifs,
            web beacons or others.
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(ii) How We Use Information:</strong>We use the information
            gathered from You to ensure Your continued good experience on Our
            website, including through email communication. We may also track
            certain of the passive information received to improve Our marketing
            and analytics, and for this, We may work with third-party providers.
          </div>
          <div className="mt-2 description">
            <strong>(iii) How You Can Protect Your Information:</strong>If You
            would like to disable Our access to any passive information We
            receive from the use of various technologies, You may choose to
            disable cookies in Your web browser. Please be aware that the Owner
            will still receive information about You that You have provided,
            such as Your email address. If You choose to terminate Your account,
            the Owner will store information about You.
          </div>

          <h4>Assumption of Risk</h4>
          <div className="mt-3 description">
            The Website and Services serve communication purposes exclusively.
            You recognize that information posted on Our Website is not meant to
            be legal, medical, or financial advice, and it does not establish a
            fiduciary relationship between You and the Owner. Your purchase of
            any products on the Website is undertaken at Your own risk. The
            Owner bears no responsibility or liability for any advice or
            information provided on the Website.
          </div>

          <h4>Sale of Goods/Services</h4>
          <div className="mt-3 description">
            The Owner may sell goods or services or allow third parties to sell
            goods or services on the Website. The Owner undertakes to be as
            accurate as possible with all information regarding the goods and
            services, including product descriptions and images. However, the
            Owner does not guarantee the accuracy or reliability of any product
            information and You acknowledge and agree that You purchase such
            products at Your own risk. For goods or services sold by others, the
            Owner assumes no liability for any product and cannot make any
            warranties about the merchantability, fitness, quality, safety or
            legality of these products. For any claim You may have against the
            manufacturer or seller of the product, You agree to pursue that
            claim directly with the manufacturer or seller and not with the
            Owner. You hereby release the Owner from any claims related to goods
            or services manufactured or sold by third parties, including any and
            all warranty or product liability claims.
            <br />
            <br />
            We may, in our sole discretion, limit or cancel quantities purchased
            per person, per household or per order. Any orders including orders
            by or under the same customer account, the same credit card, and/or
            orders that use the same billing and/or shipping address may be
            subject to the above. We may restrict the sales of products or
            Services to any person, geographic region or jurisdiction at our
            sole discretion as we may decide on a case-by-case basis.
            <br />
            <br />
            We retain the absolute right to reject any order made by individuals
            and to restrict or prevent orders that, in our sole discretion, seem
            to be made by dealers, resellers, or distributors. If your order is
            canceled, we will reach out to the email and/or billing
            address/phone number provided during the order placement.
            <br />
            <br />
            We make every effort to display our products' colors and images
            accurately, but we cannot guarantee that your computer or phone
            screen will depict the colors precisely. We do not offer any
            warranties regarding the quality of products, services, information,
            or other materials you obtain, or that any errors in the Service
            will be rectified. Prices and product descriptions are subject to
            change at our discretion without prior notice. We may remove or stop
            offering any products from our site without advance notice. Any
            product or service offer on this site is void where prohibited.
            <br />
            <br />
            We may provide you with access to third-party tools over which we
            neither monitor nor have any control nor input. You acknowledge and
            agree that we provide access to such tools “as is” and “as
            available” without any warranties, representations or conditions of
            any kind and without any endorsement. We shall have no liability
            whatsoever arising from or relating to your use of optional
            third-party tools. Any use by you of optional tools offered through
            the site is entirely at your own risk and discretion and you should
            ensure that you are familiar with and approve of the terms on which
            tools are provided by the relevant third-party provider(s).
          </div>

          <h4>SHIPPING/DELIVERY/RETURN POLICY</h4>
          <div className="mt-3 mb-0 description">
            You agree to ensure payment for any items You may purchase from Us
            and You acknowledge and affirm that prices are subject to change.
            When purchasing a physical good, You agree to provide Us with a
            valid email and shipping address, as well as valid billing
            information. We reserve the right to reject or cancel an order for
            any reason, including errors or omissions in the information You
            provide to us. If We do so after payment has been processed, We will
            issue a refund to You in the amount of the purchase price. We also
            may request additional information from You prior to confirming a
            sale and We reserve the right to place any additional restrictions
            on the sale of any of Our products. You agree to ensure payment for
            any items You may purchase from Us and You acknowledge and affirm
            that prices are subject to change. For the sale of physical
            products, We may preauthorize Your credit or debit card at the time
            You place the order or We may simply charge Your card upon shipment.
            You agree to monitor Your method of payment. Shipment costs and
            dates are subject to change from the costs and dates You are quoted
            due to unforeseen circumstances. For any questions, concerns, or
            disputes, You agree to contact Us in a timely manner at the
            following email id; info@web3inventiv.com.
          </div>
          <div className="mt-3 mb-0 description">
            We will make reimbursements for returns without undue delay, and not
            later than:
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(i) </strong> 30 days after the day we received back from
            you any goods supplied; or
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(ii) </strong>(if earlier) 30 days after the day you provide
            evidence that you have returned the goods; or
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(iii) </strong> if there were no goods supplied, 30 days
            after the day on which we are informed about your decision to cancel
            this contract.
          </div>
          <div className="mt-3 description">
            We will make the reimbursement using the same means of payment as
            you used for the initial transaction unless you have expressly
            agreed otherwise; in any event, you will not incur any fees as a
            result of the reimbursement.
          </div>

          <h4>Reverse Engineering & Security</h4>
          <div className="mt-3 mb-0 description">
            You agree not to undertake any of the following actions:
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(i) </strong> Reverse engineer, or attempt to reverse
            engineer or disassemble any code or software from or on the Website
            or Services;
          </div>
          <div className="mt-2 description">
            <strong>(ii) </strong>Violate the security of the Website or
            Services through any unauthorized access, circumvention of
            encryption or other security tools, data mining or interference to
            any host, user or network.
          </div>

          <h4>Data Loss</h4>
          <div className="mt-3 description">
            The Owner does not accept responsibility for the security of Your
            account or content. You agree that Your use of the Website or
            Services is at Your own risk.
          </div>

          <h4>Spam Policy</h4>
          <div className="mt-3 description">
            You are strictly prohibited from using the Website or any of the
            Owner’s Services for illegal spam activities, including gathering
            email addresses and personal information from others or sending any
            mass commercial emails.
          </div>

          <h4>Third-party Links & Content</h4>
          <div className="mt-3 description">
            The Owner may occasionally post links to third-party websites or
            other services. You agree that the Owner is not responsible or
            liable for any loss or damage caused as a result of Your use of any
            third party services linked to Our Website.
          </div>

          <h4>Entire Agreement</h4>
          <div className="mt-3 description">
            This Agreement constitutes the entire understanding between the
            Parties with respect to any and all use of this Website. This
            Agreement supersedes and replaces all prior or contemporaneous
            agreements or understandings, written or oral, regarding the use of
            this Website.
          </div>

          <h4>Service Interruption</h4>
          <div className="mt-3 description">
            The Owner may need to interrupt Your access to the Website to
            perform maintenance or emergency services on a scheduled or
            unscheduled basis. You agree that Your access to the Website may be
            affected by unanticipated or unscheduled downtime, for any reason,
            but that the Owner shall have no liability for any damage or loss
            caused as a result of such downtime.
          </div>

          <h4>No Warranties</h4>
          <div className="mt-3 description">
            You agree that Your use of the Website and Services is at Your sole
            and exclusive risk and that any Services provided by Us are on an
            “As Is” basis. The Owner hereby expressly disclaims any and all
            express or implied warranties of any kind, including, but not
            limited to the implied warranty of fitness for a particular purpose
            and the implied warranty of merchantability. The Owner makes no
            warranties that the Website or Services will meet Your needs or that
            the Website or Services will be uninterrupted, error-free, or
            secure. The Owner also makes no warranties as to the reliability or
            accuracy of any information on the Website or obtained through the
            Services. You agree that any damage that may occur to You, through
            Your computer system, or as a result of loss of Your data from Your
            use of the Website or Services is Your sole responsibility and that
            the Owner is not liable for any such damage or loss.
            <br />
            <br />
            All information, software, products, services, and related graphics
            are provided on this site on are “as is” and “as available” basis
            without warranty of any kind, either expressed or implied. The
            Website disclaims all warranties, expressed or implied including,
            without limitation, all implied warranties of merchantability,
            fitness for a particular purpose, title, and non-infringement or
            arising from a course of dealing, usage, or trade practice. The
            company makes no representation about the suitability of the
            information, software, products, and services contained on this
            Website for any purpose, and the inclusion or offering of any
            products or services on this Website does not constitute any
            endorsement or recommendation of such products or services.
            <br />
            <br />
            The Website makes no warranty that the use of the Website will be
            uninterrupted, timely, secure, without defect or error-free. You
            expressly agree that the use of the site is at your own risk. The
            Website shall not be responsible for any content found on the
            Website.
            <br />
            <br />
            Your use of any information or materials on this site or otherwise
            obtained through the use of this Website is entirely at your own
            risk, for which we shall not be liable. It shall be your own
            responsibility to ensure that any products, services or information
            available through this website meet your specific requirements.
            <br />
            <br />
            The Website assumes no responsibility for the accuracy, currency,
            completeness or usefulness of information, views, opinions or advice
            in any material contained on the Website. Any information of third
            parties or advertisers is made available without doing any changes
            and so the Website cannot guarantee accuracy and is not liable to
            any inconsistencies arising thereof. All postings, messages,
            advertisements, photos, sounds, images, text, files, video or other
            materials posted on, transmitted through, or linked from the
            Website, are solely the responsibility of the person from whom such
            Content originated and the Website does not control and is not
            responsible for Content available on the Website.
            <br />
            <br />
            There may be instances when incorrect information is published
            inadvertently on our Website or in the Service such as typographical
            errors, inaccuracies, or omissions that may relate to product
            descriptions, pricing, promotions, offers, product shipping charges,
            transit times, and availability. Any errors, inaccuracies or
            omissions, may be corrected at our discretion at any time and we may
            change or update information or cancel orders if any information in
            the Service or on any related website is inaccurate at any time
            without prior notice (including after you have submitted your
            order).
            <br />
            <br />
            We undertake no obligation to update, amend or clarify information
            in the Service or on any related website, including without
            limitation, pricing information, except as required by law. No
            specified update or refresh date applied in the Service or on any
            related website should be taken to indicate that all information in
            the Service or on any related website has been modified or updated.
            <br />
            <br />
            The Website shall not be responsible for any interaction between you
            and the other users of the Website. Under no circumstances will the
            Website be liable for any goods, services, resources, or content
            available through such third party dealings or communications, or
            for any harm related thereto. The Website is under no obligation to
            become involved in any disputes between you and other users of the
            Website or between you and any other third parties. You agree to
            release the Website from any and all claims, demands, and damages
            arising out of or in connection with such dispute.
            <br />
            <br />
            You agree and understand that while the Website has made reasonable
            efforts to safeguard the Website, it cannot and does not ensure or
            make any representations that the Website or any of the information
            provided by You cannot be hacked by any unauthorized third parties.
            You specifically agree that the Website shall not be responsible for
            unauthorized access to or alteration of Your transmissions or data,
            any material or data sent or received or not sent or received, or
            any transactions entered into through the Website.
            <br />
            <br />
            You specifically agree that the Website is not responsible or liable
            for any threatening, defamatory, obscene, offensive or illegal
            content or conduct of any other party or any infringement of
            another’s rights, including intellectual property rights. You
            specifically agree that the Website is not responsible for any
            content sent using and/or included in the Website by any third
            party.
            <br />
            <br />
            In no event shall the Website be liable for any direct, indirect,
            punitive, incidental, special, consequential damages or any damages
            whatsoever including, without limitation, damages for loss of use,
            data or profits, arising out of or in any way connected with the use
            or performance of the site, with the delay or inability to use the
            site or related services, the provision of or failure to provide
            Services, or to deliver the products or for any information,
            software, products, services and related graphics obtained through
            the site, or any interaction between you and other participants of
            the Website or otherwise arising out of the use of the Website,
            damages resulting from use of or reliance on the information
            present, whether based on contract, tort, negligence, strict
            liability or otherwise, even if the Website or any of its
            affiliates/suppliers has been advised of the possibility of damages.
            If despite the limitation above, the Company is found liable for any
            loss or damage which arises out of or in any way connected with the
            use of the Website and/ or provision of Services, then the liability
            of the Company will in no event exceed, 50% (Fifty percent) of the
            amount you paid to the Company in connection with such
            transaction(s) on this Website.
            <br />
            <br />
            You accept all responsibility for and hereby agree to indemnify and
            hold harmless the company from and against, any actions taken by you
            or by any person authorized to use your account, including without
            limitation, disclosure of passwords to third parties. By using the
            Website, you agree to defend, indemnify and hold harmless the
            indemnified parties from any and all liability regarding your use of
            the site or participation in any site’s activities. If you are
            dissatisfied with the Website, or the Services or any portion
            thereof, or do not agree with these terms, your only recourse and
            exclusive remedy shall be to stop using the site.
          </div>

          <h4>Limitation on Liability</h4>
          <div className="mt-3 description">
            The Owner is not liable for any damages that may occur to You as a
            result of Your use of the Website or Services, to the fullest extent
            permitted by law. The maximum liability of the Owner arising from or
            relating to these Terms of Service
          </div>

          <h4>General Provisions</h4>
          <div className="mt-2 mb-0 description">
            <strong>(i) Language: </strong> All communications made or notices
            given pursuant to these Terms of Service shall be in the English
            language.
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(ii) Jurisdiction, Venue & Governing Law: </strong>By using
            the Website or Services, you consent to the application of Indian
            laws to any matter or dispute arising from these Terms of Service or
            any dispute between you and the Owner, except for its conflict of
            law rules. If any legal action allowed under these Terms of Service
            is initiated, both parties agree to submit exclusively to the
            jurisdiction of the courts in Chandigarh, India. It's important to
            note that this choice of law, venue, and jurisdiction provision is
            obligatory, not optional. You are giving up the right to raise
            objections about the venue, including citing the doctrine of forum
            non-conveniens or any similar doctrine.
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(iii) Sererability: </strong> If any part or sub-part of
            these Terms of Service is held invalid or unenforceable by a court
            of law or competent arbitrator, the remaining parts and sub-parts
            will be enforced to the maximum extent possible. In such condition,
            the remainder of these Terms of Service shall continue in full
            force.
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(iv) No Waiver: </strong> If we do not enforce any
            particular provision in these Terms of Service, it does not mean
            that we waive the right to enforce it or any other provision in the
            future. A waiver of a specific section or subsection in these Terms
            of Service does not imply a waiver of any other section or
            subsection.
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(v) Heading for Convenience only: </strong> Headings of
            parts and sub-parts under these Terms of Service are for convenience
            and organization, only. Headings shall not affect the meaning of any
            provisions of these Terms of Service.
          </div>
          <div className="mt-2 mb-0 description">
            <strong>(vi) No Agency, Partnership, or Joint Venture: </strong>{" "}
            These Terms of Service do not establish any agency, partnership, or
            joint venture between the Parties. None of the Parties has the
            authority to legally bind the other Party to third parties.
          </div>
          <div className="mt-2 description">
            <strong>(vii) Electronic Communications Permitted: </strong>{" "}
            Electronic communications are permitted to both Parties under these
            Terms of Service, including e-mail or fax. For any questions or
            concerns, please email Us at the following address:
            info@we3inventiv.com
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsCondition;
