import React from 'react'
import Banner from "../../../images/Digital-Marketing-Services.jpg"
import SEO from "../../../images/seo.jpg"
import socialMedia from "../../../images/social-media-marketing.jpg"
import onlineMarketing from "../../../images/online-marketing.jpg"
import ppp from "../../../images/pay-per-click.jpg"
import linkBuilding from "../../../images/link-building.jpg"
import AMS from"../../../images/AMS.jpg"
import why from "../../../images/why.png"
import "../../Styles/digital-marketing.scss"
import ContactForm from '../ContactForm'

function DigitalMarketing() {
  return (
    <>
      <div className='digital-marketing-section'>
        <div className='banner'>
          <img src={Banner} alt='' />
        </div>
        <div className='container'>
          <h2 className='text-center'>Digital Marketing Services</h2>
          <div className='description'>We specialize in driving growth for your brand and level-up your marketing strategy to engage potential customers. As a leading digital marketing agency in India, we offer cutting-edge digital solutions by getting indulged in hybrid and integrated marketing approaches. We combine our years of experience in creating integrated social solutions with creative output to generate a rich digital experience for our clients. We think we research, we create, and for our clients, we thrive to deliver best solutions to their every problem.</div>
          <h4 className='feature-title mb-5'>How Digital Marketing Services Drive Business Growth</h4>
          <div className='description'>Our digital marketing services can help your businesses drive growth in a variety of ways by leveraging digital channels to reach and engage with their target audience. Here are some ways in which our digital marketing services can help drive your business growth:</div>
          <div className='row justify-content-between mt-3'>
            <div className="col-md-3 feature-box">
              <h6 className="text-center">Increased visibility</h6>
              <div className="content">Our digital marketing services can help businesses improve their online visibility by optimizing their website and content for search engines, creating and publishing valuable content, and leveraging social media channels to reach a wider audience.</div>
            </div>
            <div className="col-md-3 feature-box">
              <h6 className="text-center">Targeted advertising</h6>
              <div className="content">Our digital marketing services can help businesses reach their target audience through targeted advertising campaigns that are designed to reach specific demographics, interests, and behaviors. This can result in higher conversion rates and more qualified leads.</div>
            </div>
            <div className="col-md-3 feature-box">
              <h6 className="text-center">Improved customer engagement</h6>
              <div className="content">Our digital marketing services can help businesses improve customer engagement by creating personalized and relevant content that resonates with their target audience. This can lead to increased customer loyalty and advocacy.</div>
            </div>
          </div>
          <div className='row justify-content-between mt-3'>
            <div className="col-md-3 feature-box">
              <h6 className="text-center">Better data tracking and analysis</h6>
              <div className="content">Our digital marketing services can help businesses track and analyze their marketing efforts more effectively, allowing them to identify what is working and what is not. This can lead to more informed marketing decisions and more effective use of resources.</div>
            </div>
            <div className="col-md-3">
            </div>
            <div className="col-md-3">
            </div>
          </div>
          <h4 className='mt-80'>Web3inventiv's Digital Marketing Services</h4>
          <div className='row justify-content-between mt-5'>
            <div className='services col-md-7'>
              <h6>I. Search Engine Optimization (SEO)</h6>
              <div className='content mt-4'>SEO (Search Engine Optimization) is the process of optimizing a website to improve its visibility and ranking on search engine results pages (SERPs) for relevant keywords and phrases. The goal of SEO is to increase the quantity and quality of organic (unpaid) traffic to a website.<br />SEO involves a range of techniques and strategies that are designed to make a website more attractive to search engines. Here are some of the key components of SEO:</div>
              <ul>
                <li>
                  <span>Keyword research: </span>Identifying the most relevant and valuable keywords and phrases that people use to search for a particular topic or business.
                </li>
                <li>
                  <span>On-page optimization: </span>Optimizing the website's content, structure, and HTML code to make it more attractive to search engines and easier for them to crawl and index.
                </li>
                <li>
                  <span>Off-page optimization: </span>Building high-quality backlinks to the website from other authoritative websites, which can help improve the website's visibility and ranking.
                </li>
                <li>
                  <span>Technical optimization: </span>Ensuring that the website's technical elements, such as site speed, mobile responsiveness, and security, are optimized for search engines.
                </li>
                <li>
                  <span>Content creation: </span>Creating high-quality, relevant, and engaging content that aligns with the target audience's needs and interests.
                </li>
                <li>
                  <span>Local SEO: </span>Optimizing the website for local search queries, such as "near me" or "in [location]," to help businesses appear in local search results.
                </li>
              </ul>

            </div>
            <div className='col-md-4 img-parent'>
              <img src={SEO} alt='' />
            </div>
          </div>
          <div className='row justify-content-between mt-80'>
            <div className='col-md-4 img-parent'>
              <img src={socialMedia} alt='' />
            </div>
            <div className='services col-md-7'>
              <h6>2. Social Media Management</h6>
              <div className='content mt-4'>Social media management is the process of creating, scheduling, analyzing, and engaging with content on social media platforms such as Facebook, Instagram, Twitter, LinkedIn, and others. The goal of social media management is to build a strong and engaged following, increase brand awareness, and drive traffic and conversions.<br />Here are some key components of social media management:</div>
              <ul>
                <li>
                  <span>Strategy development: </span>Creating a social media strategy that aligns with the business objectives and target audience, as well as selecting the appropriate social media platforms to focus on.
                </li>
                <li>
                  <span>Content creation: </span>Developing high-quality and engaging content, such as posts, videos, images, and infographics, that resonates with the target audience.
                </li>
                <li>
                  <span>Scheduling and posting: </span>Creating a content calendar and scheduling posts at optimal times to ensure maximum reach and engagement.
                </li>
                <li>
                  <span>Community management: </span>Monitoring and responding to comments, messages, and mentions on social media platforms to maintain a positive brand image and foster engagement.
                </li>
                <li>
                  <span>Analytics and reporting: </span>Analyzing social media performance data to understand what is working and what can be improved, as well as creating reports to communicate social media results to stakeholders.
                </li>
              </ul>
            </div>
          </div>
          <div className='row justify-content-between mt-80'>
            <div className='services col-md-7'>
              <h6>3. Online Reputation Management (ORM)</h6>
              <div className='content mt-4'>Online Reputation Management (ORM) is the practice of monitoring and influencing a business's online reputation across various digital channels, such as search engines, social media, review sites, and forums. The goal of ORM is to maintain a positive online image, protect a business's brand reputation, and manage any negative feedback or criticism.<br />Here are some key components of Online Reputation Management:</div>
              <ul>
                <li>
                  <span>Monitoring: </span>Constantly monitoring online mentions, reviews, and comments about the business to identify any negative feedback or criticism.
                </li>
                <li>
                  <span>Responding: </span>Responding to any negative feedback or criticism in a professional and timely manner to address concerns and resolve issues.
                </li>
                <li>
                  <span>Generating positive content: </span>Creating and promoting positive content such as testimonials, reviews, and blog posts to boost the business's online reputation.
                </li>
                <li>
                  <span>Social media management: </span>Managing social media accounts to maintain a positive online presence and engage with customers.
                </li>
                <li>
                  <span>Search engine optimization: </span>Optimizing search engine results to suppress negative reviews or content that may harm the business's online reputation.
                </li>
                <li>
                  <span>Review management: </span>Managing and responding to customer reviews on review sites such as Yelp, Google, and TripAdvisor.
                </li>
              </ul>

            </div>
            <div className='col-md-4 img-parent'>
              <img src={onlineMarketing} alt='' />
            </div>
          </div>
          <div className='row justify-content-between mt-80'>
            <div className='col-md-4 img-parent'>
              <img src={ppp} alt='' />
            </div>
            <div className='services col-md-7'>
              <h6>4. Pay Per Click (PPC) Management</h6>
              <div className='content mt-4'>Pay Per Click (PPC) Management is the process of managing and optimizing paid search campaigns to drive traffic to a website. PPC advertising is a type of online advertising where advertisers pay a fee each time a user clicks on one of their ads. The goal of PPC management is to maximize the return on investment (ROI) by driving targeted traffic to the website and converting visitors into customers.<br />Here are some key components of PPC Management:</div>
              <ul>
                <li>
                  <span>Keyword research: </span>Identifying relevant keywords and phrases that potential customers are searching for and creating ad campaigns targeting those keywords.
                </li>
                <li>
                  <span>Ad creation: </span>Developing ad copy that is relevant to the target audience and that encourages users to click through to the website.
                </li>
                <li>
                  <span>Bid management: </span>Adjusting bids for keywords and ad placement to optimize the campaign performance and maximize ROI.
                </li>
                <li>
                  <span>Landing page optimization: </span>Creating optimized landing pages that are relevant to the ad copy and that encourage visitors to take a desired action, such as making a purchase or filling out a form.
                </li>
                <li>
                  <span>Conversion tracking: </span>Tracking conversions to measure the effectiveness of the PPC campaign and make data-driven optimizations.
                </li>
              </ul>
            </div>
          </div>
          <div className='row justify-content-between mt-80'>
            <div className='services col-md-7'>
              <h6>5. Link Building</h6>
              <div className='content mt-4'>Link building is the process of acquiring hyperlinks from other websites to your own website. The practice is a key part of search engine optimization (SEO) as search engines use links to determine the relevance and authority of a website. <br />Here are some key components of link building:</div>
              <ul>
                <li>
                  <span>Content creation: </span>Creating high-quality and shareable content such as blog posts, infographics, videos, and whitepapers.
                </li>
                <li>
                  <span>Outreach: </span>Reaching out to other websites and influencers in your industry to request that they link to your content.
                </li>
                <li>
                  <span>Guest blogging: </span>Writing blog posts for other websites and including links back to your own website.
                </li>
                <li>
                  <span>Broken link building: </span>Finding broken links on other websites and reaching out to request that they replace the broken link with a link to your content.
                </li>
                <li>
                  <span>Social media promotion: </span>Promoting your content on social media platforms to encourage others to link to your content.
                </li>
              </ul>

            </div>
            <div className='col-md-4 img-parent'>
              <img src={linkBuilding} alt='' />
            </div>
          </div>
          <div className='row justify-content-between mt-80'>
            <div className='col-md-4 img-parent'>
              <img src={AMS} alt='' />
            </div>
            <div className='services col-md-7'>
              <h6>6. Amazon Marketing Services (AMS)</h6>
              <div className='content mt-4'>Amazon Marketing Services (AMS) is a suite of advertising tools offered by Amazon that enables sellers and vendors to promote their products and increase sales on the Amazon platform. The platform provides various advertising options, including Sponsored Products, Sponsored Brands, and Sponsored Display.<br />Here are some key components of Amazon Marketing Services:</div>
              <ul>
                <li>
                  <span>Sponsored Products: </span>This type of ad allows sellers to promote individual products within Amazon search results and product pages. Ads appear as sponsored listings, and advertisers only pay when a customer clicks on the ad.
                </li>
                <li>
                  <span>Sponsored Brands: </span>This type of ad appears in the search results and highlights a brand's logo, tagline, and up to three featured products. Advertisers pay on a cost-per-click basis.
                </li>
                <li>
                  <span>Sponsored Display: </span>This type of ad targets customers based on their shopping behavior and interests. Advertisers can choose to target audiences based on specific products, categories, or customer interests.
                </li>
                <li>
                  <span>Amazon DSP: </span>This type of ad enables advertisers to target customers across Amazon-owned and third-party websites and apps using display and video ads.
                </li>
              </ul>
            </div>
          </div>
          <div className='row justify-content-between why-us'>
            <div className='col-md-6'>
              <h4>Why web3inventiv for website development?</h4>
              <div className='text-justify content mt-4'>We  desigweb3inventiv develop a custom web application that brings enhanced growth for your business and helps to stay ahead of the competition. We have delivered fully functional web solutions to clients in diverse industry verticals such as banking, finance, retail, healthcare, logistics, entertainment to travel, and much more.</div>
            </div>
            <div className='col-md-5 image-parent'>
              <img src={why} alt='' />
            </div>
          </div>
          <ContactForm/>
        </div>
      </div>
    </>
  )
}

export default DigitalMarketing
