import './App.scss';
import Header from './Components/Home/Header/Header';
import Footer from './Components/Home/Footer/Footer';
import Router from './Router';
import { HashRouter } from "react-router-dom";
import ScrollToTop from './ScrollToTop';

function App() {

  return (
    <HashRouter>
      <Header />
      <ScrollToTop />
      <Router />
      <Footer />
    </HashRouter>
  );
}

export default App;
