import React from "react";
import marketPlaceBanner from "../../../images/nft-marketpace-for-pnysical-assets.jpg";
import digitalAssets from "../../../images/digital-assets.jpg";
import physicalAssets from "../../../images/NFT Token Development.png";
import "../../Styles/nftMarketplacePhysicalAssets.scss";
import ContactForm from "../ContactForm";

function NftForPhysical() {
  return (
    <>
      <div className="nft-marketplace-physical-section">
        <div className="banner">
          <img src={marketPlaceBanner} alt="" />
          <h2 className="text-center">NFT Marketplace for Physical Assets</h2>
        </div>
        <div className="container">
          <div className="description mt-100">
            Tokens have been pioneers in the crypto world, leading the way for
            many innovations. The introduction of NFTs is a particularly
            significant development. NFTs have made it possible to create unique
            tangible assets, leading to the emergence of a thriving business in
            the crypto space. There is a significant potential for enhancing the
            NFT marketplace, especially for tangible assets, in the future.
            However, before exploring the practical aspects of NFTs for business
            entities, it is essential to have a comprehensive understanding of
            what NFTs are.Tokens have undeniably been trendsetters in the crypto
            world, paving the way for numerous innovations, with the
            introduction of NFTs marking a particularly notable development. The
            emergence of NFT tangible assets has become a thriving business,
            offering a lucrative venture in the crypto space. Enhancing the NFT
            marketplace specifically for tangible assets holds substantial
            potential for the future. Before delving into the practical aspects
            of NFTs for business entities, it's crucial to gain a comprehensive
            understanding of the concept of NFTs.
          </div>
          <div className="row justify-content-between align-items-center assets-parent mt-100">
            <h2 className="text-center">
              How Is Physical NFT Different From Digital NFT?
            </h2>
            <div className="col-md-7 mt-80">
              <div className="nft-box">
                <h4>Digital Asset</h4>
                <div className="content mt-5">
                  Intangible assets, especially those that exist solely in the
                  digital medium, have played a crucial role in the evolution of
                  Non-Fungible Tokens (NFTs). Initially catering only to
                  collectors, NFTs have now expanded into various industries due
                  to their unique characteristics and robust security features.
                  NFTs are now finding applications across diverse sectors, with
                  a significant impact on the arts and creator communities.
                  Artists are leveraging NFTs to authenticate and monetize their
                  work.
                  <br />
                  <br />A significant revolution has occurred in the gaming
                  industry, where Non-Fungible Tokens (NFTs) have completely
                  transformed the gaming experience. Players who were once
                  frustrated with in-game limitations, now have the opportunity
                  to monetize their game assets in the real world through NFTs.
                  This extends to the conversion of Augmented Reality (AR) and
                  Virtual Reality (VR) assets into NFTs, ensuring that digital
                  assets carry unique values and can be accessed globally at any
                  time. In essence, NFTs have become a versatile tool that is
                  not confined to a single domain. They are influencing how
                  assets, both tangible and digital, are valued, traded, and
                  experienced across various sectors worldwide.
                </div>
              </div>
            </div>
            <div className="col-md-5 mt-80">
              <div className="img-parent">
                <img src={digitalAssets} alt="" />
              </div>
            </div>
            <div className="col-md-5 mt-80">
              <div className="img-parent">
                <img src={physicalAssets} alt="" />
              </div>
            </div>
            <div className="col-md-7 mt-80">
              <div className="nft-box">
                <h4>Physical asset</h4>
                <div className="content">
                  NFTs, or unique tokens, can extend beyond the digital realm to
                  encompass real-world assets like paintings, houses, cars, or
                  any physically existing item. These real-world NFTs can be
                  represented physically through barcodes, markers, or other
                  devices, essentially serving as a written and tradable
                  representation of the tangible object. As the global demand
                  for ownership of digital assets rises, a new trend is emerging
                  in the form of regulating NFT laws in real life. The concept
                  of physical NFTs is gaining traction in our society,
                  fundamentally altering the traditional reliance on third
                  parties by eliminating the need for intermediaries. This shift
                  empowers individuals to assert ownership rights, providing a
                  safeguard against fraudulent activities associated with their
                  property.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray p-100">
          <div className="container">
            <h4>
              Key Factors We Consider in the Development of NFT Marketplace for
              Physical Assets
            </h4>
            <div className="row align-items-center mt-5 factor-parent">
              <div className="col-md-4">
                <div className="feature-nft">
                  <div className="name">Advanced Navigation Features</div>
                  <div className="about">
                    Provide your users with dynamic search filters such as
                    categories, post date, creator names, prices, and more,
                    enabling seamless exploration of the extensive collection of
                    exceptional assets.
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className=" feature-nft">
                  <div className="name">Auction House</div>
                  <div className="about">
                    Users can auction off their intangible assets, allowing them
                    to set a predetermined minimum value for their items.
                    Collectors or buyers then place bids, and the highest bidder
                    ultimately becomes the owner of the asset. This type of
                    auction mechanism, where participants openly bid against
                    each other, is commonly known as the English auction.
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="feature-nft">
                  <div className="name">Crypto wallet</div>
                  <div className="about">
                    Integrate robust crypto wallets, including Coinbase Wallet,
                    Fortmatic, MetaMask, and MyEtherWallet (MEW), to facilitate
                    seamless peer-to-peer (P2P) transactions for artists and
                    investors. Ensure that these wallets support a diverse range
                    of advanced cryptocurrencies and stable coins, enhancing
                    flexibility and accessibility in the digital asset
                    ecosystem.
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="feature-nft">
                  <div className="name">Registration of Physical Assets</div>
                  <div className="about">
                    Establish a user-friendly platform that allows users to
                    amalgamate their assets into non-fungible tokens (NFTs) and
                    showcase them in a curated space. Provide a straightforward
                    process for users to include detailed descriptions of their
                    digital assets within the platform. This approach enhances
                    the accessibility and transparency of the assets within the
                    NFT ecosystem.
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="feature-nft">
                  <div className="name">Virtual NFT Shopping Mall</div>
                  <div className="about">
                    As the platform has the multi-chain interoperability option
                    the NFT can be traded by the users over various blockchain
                    networks.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          {/* <div className="row justify-content-between why-us">
            <div className="col-md-6">
              <h4>Why Choose web3inventiv for Token Development services?</h4>
              <ul>
                <li>
                  We create a platform that is capable of alluring millions of
                  NFT holders together.
                </li>
                <li>
                  Implement DeFi investment plans and enticing revenue models
                  for crypto investors.
                </li>
                <li>
                  Add futuristic features and functionalities to stay ahead of
                  the crowded crypto exchanges.
                </li>
                <li>
                  As the NFT marketplace website developer displays unique NFTs
                  attractively, your marketplace will have enhanced liquidity.
                </li>
                <li>
                  Completely secure and easy to use with error-free smart
                  contracts.
                </li>
                <li>
                  Each asset will be protected through ownership rights, secured
                  through cryptography.
                </li>
                <li>Multichain or cross-chain compatibility.</li>
                <li>
                  Easy integration of wallets and flexible payment systems for
                  fiat money and cryptocurrencies.
                </li>
                <li>
                  With our NFT marketplace services, you can add filters for
                  searching recently added & oldest NFTs, highest & lowest
                  prices, new & expiring auctions, and more.
                </li>
              </ul>
            </div>
            <div className="col-md-5 image-parent">
              <img src={why} alt='' />
            </div>
          </div> */}
          <ContactForm />
        </div>
      </div>
    </>
  );
}

export default NftForPhysical;
