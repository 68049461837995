import React, { useState } from "react";
import Banner from "../../../images/portfolio_banner.jpg";
import "../../Styles/portfolio.scss";
import AllPortfolio from "./AllPortfolio";
import WebPortfolio from "./WebPortfolio";
import MobilePortfolio from "./MobilePortfolio";
import BlockchainPortfolio from "./BlockchainPortfolio";
import CaseStudies from "./CaseStudies";

function Portfolio() {
  const [toggleState, setToggleState] = useState(4);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div className="portfolio-section">
      <div className="banner">
        <img src={Banner} alt="" />
      </div>
      <div className="container mt-5">
        <div class="top_content_wrap">
          <p class="sm-title text-center">Case Studies</p>
          <h1 class="heading1 text-center d-flex aling-items-center justify-content-center">
            <div class="txt_gradient_top">Optimize.</div>
            <div class="txt_gradient_top active">Innovate.</div>
            <div class="txt_gradient_top">Disrupt</div>
          </h1>
          <div class="app__subhead text-center">
            Success stories that demonstrate our expertise to deliver
            technology-led business
            <br />
            breakthroughs across startups, global brands.
          </div>
        </div>
        {/* <h2 className='text-center'>Our Portfolio</h2> */}
        <div className="tabs-parent mb-5">
          <div className="bloc-tabs">
            <div
              className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(4)}
            >
              Blockchain
            </div>
            <div
              className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(2)}
            >
              Website
            </div>
            <div
              className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(3)}
            >
              Mobile app
            </div>
            {/* <div
              className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(5)}
            >
              Case Studies
            </div> */}
          </div>
          <div className="content-tabs">
            <div
              className={
                toggleState === 1 ? "content active-content" : "content"
              }
            >
              <div className="size-selection">
                <AllPortfolio />
              </div>
            </div>
            <div
              className={
                toggleState === 4 ? "content active-content" : "content"
              }
            >
              <BlockchainPortfolio />
            </div>
            <div
              className={
                toggleState === 2 ? "content active-content" : "content"
              }
            >
              <WebPortfolio />
            </div>
            <div
              className={
                toggleState === 3 ? "content active-content" : "content"
              }
            >
              <MobilePortfolio />
            </div>
            {/* <div className={toggleState === 5 ? "content active-content" : "content"}>
              <CaseStudies />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
