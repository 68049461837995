import React from 'react'
import './About.scss'
import { faChain, faCloud, faCoins, faDesktop, faMobile, faPerson } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContactForm from '../ContactForm'

function About() {
  return (
    <>
      <div className='about-section'>
        <div className='about-us-page common-page'>
          <div className='container'>
            <div className='title'>
              <h2>About Us</h2>
            </div>
          </div>
        </div>
        <div className='container about-desc'>
          <div className='py-5'>
            <h3>About Web3Inventiv</h3>
            <div className='mt-4 description'>Web3inventiv is a leading, Full-Service Software Development Company for Blockchain, Metaverse, IoT, Artificial Intelligence, BOTS, Mobile, and Web Development with a special focus on Timeliness, Security, Scale, and Performance. Being a leading blockchain development company, we start with thoroughly examining how blockchain technology may help your organisation and enhance organizational trust and transparency.</div>
            <div className='description'>Our team of over 50 full-stack developers, designers and innovators have designed and developed digital solutions for over 100 verticals. As a tight-knit team of Web3 experts and full blockchain developers, we conduct collaborative research and development to create next-generation applications and solutions that are perfectly suited to the ever-changing Web3 space.</div>
            <div className='description'>We Deliver 360 degree service in designing and developing the Crypto Exchanges (Centralized, Decentralized, Hybrid, P2P, etc), DApps, Smart Contracts, DeFi Staking Platform, DeFi Yield Farming, DeFi Swapping, DeFi Token, DeFi Aggregator, (NFT) Non-Fungible Token Marketplace, Tokens (Ethereum, Tron, Binance Smart Chain, etc), ICO, STO, etc...</div>
            <h3 className='best'>What We Do Best</h3>
            <div className='mt-4 best-description'>
              We start by listening to the issues, requirements, challenges and objectives. Then we put effort into understanding the business, market sector and competitors to develop information which is combined with our technical knowledge, expertise and research in order to provide the best solution at the lowest cost.
            </div>
            <div className='row'>
              <div className='col-md-3'>
                <div className='key-feature'>
                  <div className='title'>Our Vision</div>
                  <div className='content'>Our vision is to provide complete business solutions with "Leading Edge Technology" and become the best IT service provider in India.</div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='key-feature'>
                  <div className='title'>Our Mission</div>
                  <div className='content'>Our mission is to develop "State of the Art" websites/apps that help businesses thrive into today's competitive marketplace.</div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='key-feature'>
                  <div className='title'>Our Values</div>
                  <div className='content'>We concern for the customers satisfaction.</div>
                </div>
              </div>
            </div>
            <h4 className='mt-80'>SERVICES & SOLUTIONS | WE OFFER</h4>
            <div className='row justify-content-center m-0'>
              <div className='col-md-4 services bg-green'>
                <div className='icon-parent'>
                  <div class="gyan-infobox-icon-holder gyan-ease-transition">
                    <span class="gyan-infobox-image">
                      <FontAwesomeIcon icon={faMobile} />
                    </span>
                  </div>
                </div>
                <h3>MOBILE Apps</h3>
                <div className='content'>B2B Apps | B2C Apps | OCR Apps | RFID Apps | Smart Watch App | Enterprise Mobility | Consumer Mobile & Web Apps | AR</div>
              </div>
              <div className='col-md-4 services bg-blue'>
                <div className='icon-parent'>
                  <div class="gyan-infobox-icon-holder gyan-ease-transition">
                    <span class="gyan-infobox-image">
                      <FontAwesomeIcon icon={faChain} />
                    </span>
                  </div>
                </div>
                <h3>BLOCKCHAIN Development</h3>
                <div className='content'>Decentralized Apps | Cryptocurrency | Coin Development | Crypto Wallets</div>
              </div>
              <div className='col-md-4 services bg-yellow'>
                <div className='icon-parent'>
                  <div class="gyan-infobox-icon-holder gyan-ease-transition">
                    <span class="gyan-infobox-image">
                      <FontAwesomeIcon icon={faDesktop} />
                    </span>
                  </div>
                </div>
                <h3>Artificial Intelligence & Machine Learning</h3>
                <div className='content'>BOTS | RPA | NLP</div>
              </div>
            </div>
            <div className='row justify-content-center m-0'>
              <div className='col-md-4 services bg-yellow'>
                <div className='icon-parent'>
                  <div class="gyan-infobox-icon-holder gyan-ease-transition">
                    <span class="gyan-infobox-image">
                      <FontAwesomeIcon icon={faCoins} />
                    </span>
                  </div>
                </div>
                <h3>BACK-END Technologies</h3>
                <div className='content'>Php | Laravel | Python | ROR | JAVA | Symphony | .NET | MEAN | MERN | CMS Firebase</div>
              </div>
              <div className='col-md-4 services bg-green'>
                <div className='icon-parent'>
                  <div class="gyan-infobox-icon-holder gyan-ease-transition">
                    <span class="gyan-infobox-image">
                      <FontAwesomeIcon icon={faPerson} />
                    </span>
                  </div>
                </div>
                <h3>Smart Contract Audit</h3>
                <div className='content'>Private Key Leakage | Underflow and Overflow | Recursive Calls | Data poisoning</div>
              </div>
              <div className='col-md-4 services bg-blue'>
                <div className='icon-parent'>
                  <div class="gyan-infobox-icon-holder gyan-ease-transition">
                    <span class="gyan-infobox-image">
                      <FontAwesomeIcon icon={faCloud} />
                    </span>
                  </div>
                </div>
                <h3>INTERNET of THINGS</h3>
                <div className='content'>SCADA | Home & Office Automation | Industrial IoT | Lifestyle IoT | Beacons | BLE | Wearable Device Apps</div>
              </div>
            </div>
          </div>
          <ContactForm />
        </div>
      </div>
    </>
  )
}

export default About
