import React from "react";
import "./Home.scss";
import {
  faBuildingUser,
  faGraduationCap,
  faHeartPulse,
  faHotel,
  faHouseFloodWater,
  faIndustry,
  faLink,
  faMicrochip,
  faMobileScreen,
  faMobileScreenButton,
  faSquareShareNodes,
  faStar,
  faTruckFast,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import blockchainF from "../../images/blockchainFirm.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Content from "../Pages/Services/Content";
import { Link } from "react-router-dom";
import { Typewriter } from "react-simple-typewriter";
import layerIcon from "../../images/layer_circle.png";
import techGirl from "../../images/tech-girl.png";
import techCircle from "../../images/tech_img_circle.png";
import mandeep from "../../images/mandeep.jpeg";
import karan from "../../images/karan.jpeg";
import ContactForm from "../Pages/ContactForm";
// import blogImg from '../../images/blog-1.jpg'
import blogI from "../../images/web5 - web3inventiv.png";
import blogII from "../../images/Bitcoin NFT.jpeg";
import blogIII from "../../images/bitcoin-investment.jpg";

// import 'react-simple-typewriter/dist/index.css'

export default function Home() {
  return (
    <>
      <section className="main-banner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="banner-text">
              <h1>We marry brilliant ideas with technology</h1>
              <div className="d-flex creating-text">
                <div className="mr-1">by creating</div>
                <Typewriter
                  loop
                  cursor
                  cursorStyle="|"
                  typeSpeed={70}
                  deleteSpeed={50}
                  delaySpeed={1000}
                  words={["Blockchain apps", "Web apps", "Mobile apps"]}
                />
              </div>
              <div className="d-flex mt-4 justify-content-center">
                <Link to="/contact-us" className="btn theme-btn mr-20">
                  Reach Out for More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-cream">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-md-7 bg-head text-start">
              <h2 className="text-start">Blockchain Consulting Firm</h2>
              <h3>A Global Leader in Blockchain Consulting and Services.</h3>
            </div>
          </div>
          <div className="row justify-content-between mt-4">
            <div className="col-md-4 img-parent">
              <img src={blockchainF} alt="" />
            </div>
            <div className="col-md-7 about-blockchain">
              <p>
                Blockchain is the future of Businesses. We help business leaders
                understand how blockchain fits seamlessly into their business
                models. We offer you long-term support in decision-making (We
                can help you which vendor to choose for development) and
                financial advisory. Also, we are willing to go the extra mile
                and can represent you while visiting your development partner
                face to face.
              </p>
              <p>
                You’ll get advice and help in the programming of scalable and
                distributed applications and several other custom-built
                protocols. Expert Blockchain consultants can help you understand
                the crypto industry in a much more efficient manner.{" "}
                <Link to="blockchain-consultancy"> Read more...</Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Content />
      {/* <section className='team-area'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='section-title'>
                <h6>Our Leadership</h6>
                <h2>Team Members</h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3 col-md-6'>
              <div className='single-team-box'>
                <div className='team-image'>
                  <img src={teamImg} />
                </div>
                <div className='team-info'>
                  <h3>Howard Berry</h3>
                  <span>Executive Officer</span>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-team-box'>
                <div className='team-image'>
                  <img src={teamImg} />
                </div>
                <div className='team-info'>
                  <h3>Howard Berry</h3>
                  <span>Executive Officer</span>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-team-box'>
                <div className='team-image'>
                  <img src={teamImg} />
                </div>
                <div className='team-info'>
                  <h3>Howard Berry</h3>
                  <span>Executive Officer</span>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-team-box'>
                <div className='team-image'>
                  <img src={teamImg} />
                </div>
                <div className='team-info'>
                  <h3>Howard Berry</h3>
                  <span>Executive Officer</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="technology-section pt-5 pb-5">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-4 tech-img-parent">
              <img src={layerIcon} className="tech-layer" alt="" />
              <img src={techGirl} className="tech-girl" height={431} alt="" />
              <img src={techCircle} className="tech-img-circle" alt="" />
            </div>
            <div className="col-md-7 tech-parent">
              <h2>Technologies</h2>
              {/* <h3>Huge Spectrum of Next-Gen Technologies Under One Roof</h3> */}
              {/* <p className='head-abt'>Leverage our in-depth technical expertise to bring your stunning ideas to Life.</p> */}
              <div className="row tech-rows">
                <div className="tech">
                  <p className="title">Frontend</p>
                  <ul className="tech-ul">
                    <li>HTML/CSS</li>
                    <li>Angular Js</li>
                    <li>React js</li>
                    <li>Vue Js</li>
                  </ul>
                </div>
                <div className="tech">
                  <p className="title">Backend</p>
                  <ul className="tech-ul">
                    <li>.NET</li>
                    <li>Java</li>
                    <li>Node Js</li>
                    <li>Python</li>
                  </ul>
                </div>
                <div className="tech">
                  <p className="title">Backend</p>
                  <ul className="tech-ul">
                    <li>C/C++</li>
                    <li>Django</li>
                    <li>PHP</li>
                    <li>Golang</li>
                  </ul>
                </div>
                <div className="tech">
                  <p className="title">Mobility</p>
                  <ul className="tech-ul">
                    <li>Android/Kotlin</li>
                    <li>iOS/Swift</li>
                    <li>React Native</li>
                    <li>Flutter</li>
                  </ul>
                </div>
                <div className="tech">
                  <p className="title">Blockchain</p>
                  <ul className="tech-ul">
                    <li>Solidity</li>
                    <li>Ethereum</li>
                    <li>Hyperledger</li>
                    <li>Smart Contract</li>
                  </ul>
                </div>
                <div className="tech">
                  <p className="title">Full Stack</p>
                  <ul className="tech-ul">
                    <li>MEAN</li>
                    <li>MERN</li>
                    <li>JavaScript</li>
                    <li>Python</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className='flow-parent'>
        <img src={flow}/>
      </section> */}
      <section className="industry-section">
        <div className="container">
          <div className="row justify-content-between industry-content">
            <div className="col-md-4 industry-head">
              <h2>Industries</h2>
              <h3>Transforming Industries to Become Smarter and Safer</h3>
              {/* <img src={shape} className='industry-circle'/> */}
            </div>
            <div className="col-md-7 industry-parent">
              <div className="row">
                <div className="col-md-3 industry">
                  <FontAwesomeIcon icon={faMicrochip} />
                  <p>Finetech</p>
                </div>
                <div className="col-md-3 industry">
                  <FontAwesomeIcon icon={faHeartPulse} />
                  <p>Heath Care</p>
                </div>
                <div className="col-md-3 industry">
                  <FontAwesomeIcon icon={faGraduationCap} />
                  <p>Education</p>
                </div>
                <div className="col-md-3 industry">
                  <FontAwesomeIcon icon={faMobileScreen} />
                  <p>E-Commerce</p>
                </div>
                <div className="col-md-3 industry">
                  <FontAwesomeIcon icon={faHouseFloodWater} />
                  <p>Insurance</p>
                </div>
                <div className="col-md-3 industry">
                  <FontAwesomeIcon icon={faHotel} />
                  <p>Resturant</p>
                </div>
                <div className="col-md-3 industry">
                  <FontAwesomeIcon icon={faIndustry} />
                  <p>Manufacturing</p>
                </div>
                <div className="col-md-3 industry">
                  <FontAwesomeIcon icon={faLink} />
                  <p>Supply Chain</p>
                </div>
                <div className="col-md-3 industry">
                  <FontAwesomeIcon icon={faTruckFast} />
                  <p>Logistics</p>
                </div>
                <div className="col-md-3 industry">
                  <FontAwesomeIcon icon={faMobileScreenButton} />
                  <p>Entertainment</p>
                </div>
                <div className="col-md-3 industry">
                  <FontAwesomeIcon icon={faBuildingUser} />
                  <p>Real State</p>
                </div>
                <div className="col-md-3 industry">
                  <FontAwesomeIcon icon={faSquareShareNodes} />
                  <p>Social Networking</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonial-area p-100">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-title">
                <h6>What Our Client Say</h6>
                <h2>Recent Feedback</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12">
              <div className="d-flex align-items-center justify-content-center testimonial-content">
                <div className="single-testimonial">
                  <div className="testi-content-inner">
                    <div className="rating-box">
                      <ul>
                        <li>
                          <FontAwesomeIcon icon={faStar} />
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faStar} />
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faStar} />
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faStar} />
                        </li>
                      </ul>
                    </div>
                    <div className="testimonial-content">
                      <p>
                        We hired Web3inventiv to help us build a custom solution
                        for our business. The team was incredibly professional
                        and knowledgeable. They delivered a high-quality product
                        on time and on budget, and their ongoing support has
                        been exceptional. I would highly recommend them to
                        anyone looking for reliable and experienced software
                        developers.
                      </p>
                    </div>
                    <div className="testimonial-bio">
                      <div className="avtar">
                        <img src={mandeep} alt="" />
                      </div>
                      <div className="bio-info">
                        <h3 className="name">Mandeep</h3>
                        <span>Founder of Bytenome</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="single-testimonial">
                  <div className="testi-content-inner">
                    <div className="rating-box">
                      <ul>
                        <li>
                          <FontAwesomeIcon icon={faStar} />
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faStar} />
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faStar} />
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faStar} />
                        </li>
                      </ul>
                    </div>
                    <div className="testimonial-content">
                      <p>
                        Working with Web3inventiv was a fantastic experience.
                        Their team was highly skilled and brought a lot of
                        creative ideas to the table. They were responsive and
                        communicative throughout the entire process, and they
                        delivered a product that exceeded our expectations.
                        We're thrilled with the final result and would
                        definitely work with them again.
                      </p>
                    </div>
                    <div className="testimonial-bio">
                      <div className="avtar">
                        <img src={karan} alt="" />
                      </div>
                      <div className="bio-info">
                        <h3 className="name">Karan</h3>
                        <span>Founder of Appzoinx </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hire-area p-100">
        <div className="container">
          <div className="hire-wrapper">
            <div className="row">
              <div className="col-lg-12">
                <div className="hire-content position-relative">
                  <h2 className="text-center mt-0 mb-0">
                    Discuss An Upcoming IT Project
                  </h2>
                  <ContactForm />
                  {/* <h2>Discuss An Upcoming IT Project</h2>
                  <h5>For A Free Consultation Call Today +91-8287043028</h5>
                  <Link to="/contact-us" className='btn btn-primary theme-btn mt-5'>Contact Us Now</Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blog-area pb-100">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-title">
                <h6>Our Latest Blog</h6>
                <h2>News & Updates</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-start">
            <div className="col-lg-4 col-md-6">
              <div className="single-blog">
                <Link to="/blog-1">
                  <div className="post-img">
                    <img src={blogI} className="w-100 h-100" alt="" />
                  </div>
                  <div className="blog-content">
                    <div className="blog-date mb-2">
                      <ul className="blog-list d-flex p-0">
                        <li>
                          <h6>20 January-2023</h6>
                        </li>
                        <li>
                          <span>|</span>
                        </li>
                        <li>
                          <a href="/#">Technology</a>
                        </li>
                      </ul>
                    </div>
                    <div className="blog-body-title">
                      <h3>
                        <a href="/#">
                          Exploring Web5: A New Paradigm in Decentralized Web
                          Platforms
                        </a>
                      </h3>
                    </div>
                    <div className="blog-body-text">
                      <p>
                        Web5, announced in June 2022 by TBD, is built on Web3
                        technology but aims to provide an even more
                        decentralized web platform...
                      </p>
                    </div>
                    <div className="read-more-btn mt-2">
                      <a href="/#">+ Read More</a>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-blog">
                <Link to="/blog-2">
                  <div className="post-img">
                    <img src={blogII} className="w-100 h-100" alt="" />
                  </div>
                  <div className="blog-content">
                    <div className="blog-date mb-2">
                      <ul className="blog-list d-flex p-0">
                        <li>
                          <h6>10 March-2023</h6>
                        </li>
                        <li>
                          <span>|</span>
                        </li>
                        <li>
                          <a href="/#">Technology</a>
                        </li>
                      </ul>
                    </div>
                    <div className="blog-body-title">
                      <h3>
                        <a href="/#">
                          Bitcoin NFTs: What Are Ordinal NFTs and How Do You
                          Mint One?
                        </a>
                      </h3>
                    </div>
                    <div className="blog-body-text">
                      <p>
                        Ordinal NFTs, also known as Bitcoin NFTs or Bitcoin
                        Ordinals, have recently gained attention within the
                        cryptocurrency community.
                      </p>
                    </div>
                    <div className="read-more-btn mt-2">
                      <a href="/#">+ Read More</a>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-blog">
                <Link to="/blog-3">
                  <div className="post-img">
                    <img src={blogIII} className="w-100 h-100" alt="" />
                  </div>
                  <div className="blog-content">
                    <div className="blog-date mb-2">
                      <ul className="blog-list d-flex p-0">
                        <li>
                          <h6>20 January-2022</h6>
                        </li>
                        <li>
                          <span>|</span>
                        </li>
                        <li>
                          <a href="/#">Bitcoin</a>
                        </li>
                      </ul>
                    </div>
                    <div className="blog-body-title">
                      <h3>
                        <a href="/#">
                          What is Bitcoin ETF? A Guide to Investing in Bitcoin
                          Through ETFs
                        </a>
                      </h3>
                    </div>
                    <div className="blog-body-text">
                      <p>
                        Bitcoin exchange-traded funds (ETFs) have emerged as a
                        popular investment instrument, providing investors with
                        exposure
                      </p>
                    </div>
                    <div className="read-more-btn mt-2">
                      <a href="/#">Read More</a>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            {/* <div className='col-lg-4 col-md-6'>
              <div className='single-blog'>
                <div className='post-img'>
                  <img src={blogImg} className="w-100 h-100" />
                </div>
                <div className='blog-content'>
                  <div className='blog-date mb-2'>
                    <ul className='blog-list d-flex p-0'>
                      <li><h6>20 January-2022</h6></li>
                      <li><span>|</span></li>
                      <li><a href='#'>Technology</a></li>
                    </ul>
                  </div>
                  <div className='blog-body-title'>
                    <h3><a href='#'>What could 5g change about the way we use Technology?</a></h3>
                  </div>
                  <div className='blog-body-text'>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore veniam dolore.</p>
                  </div>
                  <div className='read-more-btn mt-2'>
                    <a href='#'>Read More</a>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="blog-more mt-5 text-center">
              <Link
                to="/contact-us"
                className="btn btn-primary theme-btn mr-20"
              >
                Get Free Quote
              </Link>
              <Link to="/blogs" className="btn btn-primary theme-btn">
                View All Blog
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
