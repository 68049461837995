import React from "react";
import "../../../Styles//portfolio.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import fit from "../../../../images/fit.jpg";
import ContactForm from "../../ContactForm";

function FitFlaunt() {
  return (
    <div className="container">
      <div className="row case-study-parent">
        <div className="col-md-12 mt-5">
          <div className="project-card-parent case-studies-hero-section justify-content-between d-flex pb-5">
            <div className="col-lg-5">
              <div className="project-description-parent">
                <div className="project-heading">Fit2Flaunt App</div>
                <div className="project-description">
                  Empowering fitness enthusiasts worldwide, our cutting-edge
                  dance workout app revolutionizes the way people stay active
                  and engaged.
                </div>
                <a
                  href="https://api.fit2flaunt.com/#"
                  target="_blank"
                  rel="noreferrer"
                  className="btn read-btn"
                >
                  Visit...
                  <FontAwesomeIcon icon={faChevronRight} />
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-parent">
                <img src={fit} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2 className="about-head">About</h2>
          <div className="case-description">
            This dance workout app has seven advanced features to empower
            fitness enthusiasts worldwide and transform the fitness landscape.
            It offers personalized workout plans, real-time tracking, and
            feedback to keep you engaged and motivated. With comprehensive data
            and seamless integration with wearable devices, it makes it easy to
            stay on top of your fitness routine.
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <div className="port_obect">
            <h2>Project Objective</h2>
            <p className="case-description">
              Fit2Flaunt is a popular dance workout app that offers a range of
              pole dancing classes designed to help women of all skill levels
              learn and master this exciting art form. With a variety of
              classes, from beginner to advanced, Fit2Flaunt allows you to
              progress at your own pace and achieve your fitness goals while
              having fun. The Pole Dance app features a variety of video
              tutorials, workout plans, progress-tracking tools, & community
              features that make it easy for users to stay motivated & engaged
              with their fitness journey.
            </p>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <div className="row">
            <div className="col-md-6">
              <div className="concept">
                <h3>Conceptualization</h3>
                <ul className="bullet_list">
                  <li>Easy navigation</li>
                  <li>Fluent video play</li>
                  <li>Clear demonstrations & step-by-step instructions</li>
                  <li>multiple payment options</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="tech_use">
                <h3>Technologies Used</h3>
                <ul className="bullet_list">
                  <li>React Native</li>
                  <li>Node.js</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="show_features">
                <h3>Features</h3>
                <ul className="bullet_list">
                  <li>
                    Connect with other pole dancers from around the world.
                  </li>
                  <li>
                    Beginner to advanced: classes tailored to user’ skill level
                    and goals
                  </li>
                  <li>Membership & Subscription plan</li>
                  <li>Rewards & Badges(FitCoins)</li>
                  <li>In-app community</li>
                  <li>Video sessions</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2>Our Project Challenges</h2>
          <div className="project-points mt-4">
            <div className="points-description">
              Our developers were required to come up with a mobile app that
              resonates with the customers and offers the utmost level of
              convenience and comfort while opting for fitness services. Also,
              the app must be available for both iOS and Android. The required
              mobile apps must also be performant. They should also ensure
              security as they will feature payment options and hence, demand
              entering sensitive financial information.
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2>Solutions We Developed</h2>
          <div className="project-points mt-4">
            <div className="points-description">
              We are able to develop a fully-functional and performant mobile
              app for both Android and iOS. The apps can be downloaded by
              directly going to App Store or Play Store.
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default FitFlaunt;
