import React from 'react'
import './Services.scss'
import Content from './Content'
import ContactForm from '../ContactForm'

function Services() {
  return (
    <>
      <div className='services-page common-page'>
        <div className='container'>
          <div className='title'>
            <h2>Our Services</h2>
          </div>
        </div>
      </div>
      <Content />
      <div className='container'>
        <ContactForm/>
      </div>
    </>
  )
}

export default Services
