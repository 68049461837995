import React from "react";
import "../../../Styles//portfolio.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import kinderNutrition from "../../../../images/kinder-nutrient.jpg";
import ContactForm from "../../ContactForm";

function Kinder() {
  return (
    <div className="container">
      <div className="row case-study-parent">
        <div className="col-md-12 mt-5">
          <div className="project-card-parent case-studies-hero-section d-flex pb-5">
            <div className="col-lg-5">
              <div className="project-description-parent">
                <div className="project-heading">Kinder Nutrition</div>
                <div className="project-description">
                  It is an online platform for children to get counselling on
                  nutrition from Nutritionist specialist Verena.
                </div>
                <a
                  href="https://kinder-nutrition.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn read-btn"
                >
                  Visit... <FontAwesomeIcon icon={faChevronRight} />
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-parent">
                <img src={kinderNutrition} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2 className="about-head">About</h2>
          <div className="case-description">
            Kinder Nutrition promotes a kinder approach to child nutrition.
            Their mission is to help parents establish positive meal patterns
            for their children to develop a natural, relaxed relationship with
            food and feel good overall.
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <div className="port_obect">
            <h2>Project Objective</h2>
            <p className="case-description">
              To develop a social platform where international customers can
              have 1 to 1 consultation, watch webinars on child nutrition.
            </p>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <div className="row">
            <div className="col-md-6">
              <div className="concept">
                <h3>Conceptualization</h3>
                <ul className="bullet_list">
                  <li>Easy navigation</li>
                  <li>Competitive pricing</li>
                  <li>Excellent customer service</li>
                  <li>A user-friendly online platform</li>
                  <li>Recorded webinars on platform</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div class="tech_use">
                <h3>Technologies Used</h3>
                <ul class="bullet_list">
                  <li>WordPress</li>
                  <li>PHP</li>
                  <li>JQuery</li>
                  <li>Apache server</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="col-md-6">
              <div className="show_features">
                <h3>Features</h3>
                <ul className="bullet_list">
                  <li>1 to 1 nutrition consultation</li>
                  <li>Webinars</li>
                  <li>Success stories</li>
                  <li>
                    Resources for kids and parents(Books, films, toys, games and
                    activities)
                  </li>
                  <li>Blog</li>
                  <li>Packages detail page</li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div className="functions">
                <h3>Functions</h3>
                <ul className="bullet_list">
                  <li>User-friendly interface</li>
                  <li> Detailed service descriptions</li>
                  <li>Efficient customer support system</li>
                  <li>Fluent video display (Webinar)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2>Our Project Challenges</h2>
          <div className="project-points mt-4">
            <div className="points-description">
              <strong>Design requirements:</strong> The website needed to be visually appealing, user-friendly, and consistent with the brand image, which required careful attention to design elements and user experience. <br /> Competition from established market players. Ensuring website security and customer data protection.
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2>Solutions We Developed</h2>
          <div className="project-points mt-4">
            <div className="points-description">
              <strong>Easy Navigation:</strong> We created a simple and intuitive navigation structure to help users easily find what they're looking for on the website. This included a clear menu and search functionality, which enables users to quickly access products and information.
            </div>
            <div className="points-description mt-4">
              <strong>Custom CMS:</strong> To enable easy content management, we developed a custom content management system (CMS) for the website. This allows the client to easily update and publish new content, add new products to the store, and manage orders and customer data. <br /> Conducted thorough market research to identify customer needs and preferences. Implemented industry-standard security measures to protect customer data.
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default Kinder;
