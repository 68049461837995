import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';
import '../Styles/contact.scss'


function ContactForm() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_4iyu0rd', 'template_7ksudy1', form.current, 'sGb2_ZUmvauutq85-')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        alert('Form submitted!');
        window.location.reload();
    };
    return (
        <div className='row justify-content-center mt-80 mb-5'>
            <div className='col-md-10 form-parent'>
                <h4>Get In Touch</h4>
                <form ref={form} onSubmit={sendEmail}>
                    <label>Name</label>
                    <input type="text" name="name" />
                    <label>Email</label>
                    <input type="email" name="user_email" />
                    <label>Message</label>
                    <textarea name="message" />
                    <input type="submit" value="Send" />
                </form>
            </div>
        </div>
    )
}

export default ContactForm
