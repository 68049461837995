import React from "react";
import "../../../Styles//portfolio.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import udey from "../../../../images/udey.jpg";
import ContactForm from "../../ContactForm";

function UdeyCrafts() {
  return (
    <div className="container">
      <div className="row case-study-parent">
        <div className="col-md-12 mt-5">
          <div className="project-card-parent case-studies-hero-section justify-content-between d-flex pb-5">
            <div className="col-lg-5">
              <div className="project-description-parent">
                <div className="project-heading">Udey Crafts</div>
                <div className="project-description">
                  Manufacturing house of Flannel, Mink, Velboa, Velour, Anti
                  Pilling, 3 Thread Fleece and many more
                </div>
                <a
                  href="https://udeycrafts.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn read-btn"
                >
                  Visit... <FontAwesomeIcon icon={faChevronRight} />
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-parent">
                <img src={udey} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2 className="about-head">About</h2>
          <div className="case-description">
            It is an online marketplace where user can come and see the cloth
            fabric and machines.
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <div className="port_obect">
            <h2>Project Objective</h2>
            <p className="case-description">
              To develop a social platform where international customers as well
              as local customers can review and conveniently follow on our
              regular updates about cloths and their manufacturing process.
              Whether its a launch of a new fabric, discounts or any other
              information.
            </p>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <div className="row">
            <div className="col-md-6">
              <div className="concept">
                <h3>Conceptualization</h3>
                <ul className="bullet_list">
                  <li>High-quality Fabric feel through design quality</li>
                  <li>Easy navigation</li>
                  <li>Reflecting legendary history of the store</li>
                  <li>Affordable pricing</li>
                  <li>A user-friendly online platform</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div class="tech_use">
                <h3>Technologies Used</h3>
                <ul class="bullet_list">
                  <li>WordPress</li>
                  <li>PHP</li>
                  <li>JQuery</li>
                  <li>Apache server</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="col-md-6">
              <div className="show_features">
                <h3>Features</h3>
                <ul className="bullet_list">
                  <li>Listing of all fabrics.</li>
                  <li>Tailoring details</li>
                  <li>Fabric detail page</li>
                  <li> Machines detail page</li>
                  <li>Fabric manufacturing process</li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div className="functions">
                <h3>Functions</h3>
                <ul className="bullet_list">
                  <li>User-friendly interface</li>
                  <li> Detailed product descriptions</li>
                  <li>Efficient customer support system</li>
                  <li>Easy to use reseller dashboard</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2>Our Project Challenges</h2>
          <div className="project-points mt-4">
            <div className="points-description">
              <strong>Design requirements:</strong> The website needed to be visually appealing, user-friendly, and consistent with the brand image, which required careful attention to design elements and user experience. <br /> Competition from established market players. Ensuring website security and customer data protection.
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <h2>Solutions We Developed</h2>
          <div className="project-points mt-4">
            <div className="points-description">
              <strong>Easy Navigation:</strong> We created a simple and intuitive navigation structure to help users easily find what they're looking for on the website. This included a clear menu and search functionality, which enables users to quickly access products and information.
            </div>
            <div className="points-description mt-4">
              <strong>Custom CMS:</strong> To enable easy content management, we developed a custom content management system (CMS) for the website. This allows the client to easily update and publish new content, add new products to the store, and manage orders and customer data. <br /> Conducted thorough market research to identify customer needs and preferences. Implemented industry-standard security measures to protect customer data.
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default UdeyCrafts;
