import React from "react";
import fitFluant from "../../../images/fitFluant.png";
import bigwizard from "../../../images/bigrigwizard.jpg";
import phiz from "../../../images/phiz.png";
import slash from "../../../images/slashs.jpeg";
import namaste from "../../../images/namaste.jpg";
import "../../Styles/portfolio.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function MobilePortfolio() {
  return (
    <div className="row porfolios-parent mt-3 w-100">
      <div className="col-md-12">
        <div className="project-card-parent fit-parent d-flex">
          <div className="col-lg-6">
            <div className="img-parent">
              <img src={fitFluant} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="project-description-parent">
              <div className="project-heading">Fit2Flaunt App</div>
              <div className="project-description">
                Empowering fitness enthusiasts worldwide, our cutting-edge dance
                workout app revolutionizes the way people stay active and
                engaged. With seven dynamic features tailored to elevate your
                fitness journey, we're transforming the fitness landscape one
                dance move at a time.
              </div>
              <Link to="/portfolio/fit-flaunt" className="btn read-btn">
                Read more... <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-5">
        <div className="project-card-parent even-parent d-flex">
          <div className="col-lg-6">
            <div className="project-description-parent">
              <div className="project-heading">Big rig Wizard App</div>
              <div className="project-description">
                Our app revolutionizes the trucking industry by empowering
                drivers worldwide to quickly file claims with their insurance
                agencies. Say goodbye to paperwork and delays - our app allows
                drivers to submit claims in minutes, ensuring efficiency and
                peace of mind on the road. We're transforming the claims
                process, paving the way for hassle-free experiences for truckers
                everywhere.
              </div>
              <Link to="/portfolio/big-rig-wizard" className="btn read-btn">
                Read more... <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="img-parent">
              <img src={bigwizard} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-5">
        <div className="project-card-parent phiz-parent d-flex">
          <div className="col-lg-6">
            <div className="img-parent">
              <img src={phiz} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="project-description-parent">
              <div className="project-heading">Phiz App</div>
              <div className="project-description">
                Empowering users across Brazil, our innovative chat app is
                revolutionizing the way people connect and communicate. With a
                plethora of dynamic features designed to enhance your chatting
                experience, we're reshaping the landscape of social interaction
                one message at a time. Join us as we pave the way for a more
                connected and engaged community throughout Brazil.
              </div>
              <Link to="/portfolio/phiz" className="btn read-btn">
                Read more... <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-5">
        <div className="project-card-parent slash-parent d-flex">
          <div className="col-lg-6">
            <div className="project-description-parent">
              <div className="project-heading">Slash App</div>
              <div className="project-description">
                Our innovative app transforms the shopping experience by
                rewarding users with exciting perks every time they make a
                purchase. Say farewell to traditional loyalty programs and hello
                to effortless rewards - our app seamlessly credits users with
                points, ensuring a hassle-free and delightful shopping journey.
                We're revolutionizing the way people shop, paving the path for a
                more rewarding and enjoyable experience for shoppers across
                world.
              </div>
              <Link to="/portfolio/slash" className="btn read-btn">
                Read more... <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="img-parent">
              <img src={slash} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-5">
        <div className="project-card-parent namaste-parent d-flex">
          <div className="col-lg-6">
            <div className="img-parent">
              <img src={namaste} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="project-description-parent">
              <div className="project-heading">Namaste Video Conferencing</div>
              <div className="project-description">
                Namaste - Video Conferencing app is revolutionizing the way
                people connect and collaborate in India with advanced features,
                ensuring secure and seamless video conferencing experiences for
                everyone.
              </div>
              <button className="btn read-btn">
                Read more... <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobilePortfolio;
