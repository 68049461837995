import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../../../images/logo.png";
import "./Header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faXmark } from "@fortawesome/free-solid-svg-icons";

function Header() {
  const [active, setActive] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [company, setCompany] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [mobileSubMenu, setMobileSubMenu] = useState({});

  const myFunction = () => {
    setActive(!active);
  };
  const traditionaltech = () => {
    setDropdown(!dropdown);
  };
  const companyDropdown = () => {
    setCompany(!company);
  };
  const toogleMenu = () => {
    setMobileMenu(!mobileMenu);
  };
  const toggleMobileSubMenu = (menuName) => {
    setMobileSubMenu((prevState) => ({
      ...prevState,
      [menuName]: !prevState[menuName],
    }));
  };

  return (
    <>
      <div className="navbar-area">
        <div className="inspire-nav">
          <div className="container">
            <div className="inspire-fixed-nav">
              <div className="navbar navbar-expand-md navbar-light p-0">
                <NavLink to="/" className="navbar-brand">
                  <img src={logo} className="logo" alt="" />
                </NavLink>
                <button
                  className={`navbar-toggler ${mobileMenu ? "active" : ""}`}
                  type="button"
                  onClick={toogleMenu}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`mobile-sidebar ${mobileMenu ? "show" : ""}`}>
                  <button className="toggle-cancel-icon" onClick={toogleMenu}>
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <div
                        className="dropdown-btn"
                        onClick={() => toggleMobileSubMenu("company")}
                      >
                        Company
                        <FontAwesomeIcon
                          icon={
                            mobileSubMenu["company"]
                              ? faChevronUp
                              : faChevronDown
                          }
                          className="dropdown-icon"
                        />
                      </div>
                      {mobileSubMenu["company"] && (
                        <ul className="mobile-dropdown-menu d-block">
                          <li onClick={toogleMenu}>
                            <Link to="/services">Services</Link>
                          </li>
                          <li onClick={toogleMenu}>
                            <Link to="/about-us">About Us</Link>
                          </li>
                          <li onClick={toogleMenu}>
                            <Link to="/contact-us">Contact Us</Link>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li className="nav-item">
                    <div className="dropdown-btn" onClick={() => toggleMobileSubMenu("blockchain")}>
                        Blockchain
                        <FontAwesomeIcon
                          icon={mobileSubMenu["blockchain"] ? faChevronUp : faChevronDown}
                          className="dropdown-icon"
                        />
                      </div>
                      {mobileSubMenu["blockchain"] && (
                        <ul className="mobile-dropdown-menu d-block">
                          <li onClick={toogleMenu}>
                            <Link to="/defi-blockchain">DeFi</Link>
                          </li>
                          <li onClick={toogleMenu}>
                            <Link to="/dao">DAO</Link>
                          </li>
                          <li onClick={toogleMenu}>
                            <Link to="/staking">Staking and Yield Farming</Link>
                          </li>
                          <li onClick={toogleMenu}>
                            <Link to="/ido">Initial Dex Offering (IDO)</Link>
                          </li>
                          <li onClick={toogleMenu}>
                            <Link to="/decentralized-crypto-exchange">Decentralized Crypto Exchange</Link>
                          </li>
                          <li onClick={toogleMenu}>
                            <Link to="/smart-contract">Smart Contract</Link>
                          </li>
                          <li onClick={toogleMenu}>
                            <Link to="/lending-and-borrowing">Defi Borrowing and Lending</Link>
                          </li>
                          <li onClick={toogleMenu}>
                            <Link to="/dapp">DApp</Link>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li className="nav-item">
                    <div className="dropdown-btn" onClick={() => toggleMobileSubMenu("digitalTransformation")}>
                        Digital Transformation
                        <FontAwesomeIcon
                          icon={mobileSubMenu["digitalTransformation"] ? faChevronUp : faChevronDown}
                          className="dropdown-icon"
                        />
                      </div>
                      {mobileSubMenu["digitalTransformation"] && (
                        <ul className="mobile-dropdown-menu d-block">
                          <li onClick={toogleMenu}>
                            <Link to="/web-development">Web Development</Link>
                          </li>
                          <li onClick={toogleMenu}>
                            <Link to="/app-development">App Development</Link>
                          </li>
                          <li onClick={toogleMenu}>
                            <Link to="/digital-marketing">Digital Marketing</Link>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li className="nav-item">
                      <NavLink to="portfolio">Portfolio</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/blogs">Blog</NavLink>
                    </li>
                  </ul>
                </div>
                <div
                  className={`collapse navbar-collapse mean-menu ${
                    mobileMenu ? "" : "show"
                  }`}
                >
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                      <div className="dropdown-btn" onClick={companyDropdown}>
                        Company
                      </div>
                      <div
                        className={`dropdown-content-parent ${
                          company ? "tech-active" : ""
                        }`}
                        onClick={companyDropdown}
                      ></div>
                      <div
                        className={`dropdown-content ${
                          company ? "tech-active" : ""
                        }`}
                      >
                        <ul className="menu">
                          <li onClick={companyDropdown}>
                            <Link to="/services">Services</Link>
                          </li>
                          <li onClick={companyDropdown}>
                            <Link to="/about-us">About Us</Link>
                          </li>
                          <li onClick={companyDropdown}>
                            <Link to="/contact-us">Contact Us</Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="nav-item dropdown">
                      <div className="dropdown-btn" onClick={myFunction}>
                        Blockchain
                      </div>
                      <div
                        className={`dropdown-content-parent ${
                          active ? "active" : ""
                        }`}
                        onClick={myFunction}
                      ></div>
                      <div
                        className={`dropdown-content ${active ? "active" : ""}`}
                      >
                        <div className="row p-5 justify-content-between">
                          <div className="col-md-3 mega-menu">
                            <div className="header">Decentralized Platform</div>
                            <div className="content mt-2">
                              <ul>
                                <li onClick={myFunction}>
                                  <Link to="/defi-blockchain">DeFi</Link>
                                </li>
                                <li onClick={myFunction}>
                                  <Link to="/dao">DAO</Link>
                                </li>
                                <li onClick={myFunction}>
                                  <Link to="/staking">
                                    Staking and Yield Farming
                                  </Link>
                                </li>
                                <li onClick={myFunction}>
                                  <Link to="/ido">
                                    Initial Dex Offering(IDO)
                                  </Link>
                                </li>
                                <li onClick={myFunction}>
                                  <Link to="/decentralized-crypto-exchange">
                                    Decentralized Crypto Exchange
                                  </Link>
                                </li>
                                <li onClick={myFunction}>
                                  <Link to="/smart-contract">
                                    Smart Contract
                                  </Link>
                                </li>
                                <li onClick={myFunction}>
                                  <Link to="/lending-and-borrowing">
                                    Defi Borrowing and Lending
                                  </Link>
                                </li>
                                <li onClick={myFunction}>
                                  <Link to="/dapp">DApp</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-3 mega-menu">
                            <div className="header">Cryptocurrency</div>
                            <div className="content mt-2">
                              <ul>
                                <li onClick={myFunction}>
                                  <Link to="/crypto-currency-token">
                                    CryptoCurrency Token
                                  </Link>
                                </li>
                                <li onClick={myFunction}>
                                  <Link to="/initial-coin-offering">
                                    Initial Coin Offering
                                  </Link>
                                </li>
                                <li onClick={myFunction}>
                                  <Link to="/stable-coin">Stable Coin</Link>
                                </li>
                                <li onClick={myFunction}>
                                  <Link to="/token">Token</Link>
                                </li>
                                {/* <li>Tron Token</li>
                                <li>BOTS</li> */}
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-4 mega-menu">
                            <div className="header">
                              NFT(Non-fungible token)
                            </div>
                            <div className="content mt-2">
                              <ul>
                                <li onClick={myFunction}>
                                  <Link to="/nft-minting">NFT Minting</Link>
                                </li>
                                <li onClick={myFunction}>
                                  <Link to="/nft-marketplace">
                                    NFT Marketplace
                                  </Link>
                                </li>
                                <li onClick={myFunction}>
                                  <Link to="/nft-marketplace-for-physical-assets">
                                    NFT Marketplace for Physical Assets
                                  </Link>
                                </li>
                                <li onClick={myFunction}>
                                  <Link to="/nft-launchpad">NFT Launchpad</Link>
                                </li>
                                <li onClick={myFunction}>
                                  <Link to="/nft-staking">
                                    NFT Staking Platform
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="nav-item dropdown">
                      <div className="dropdown-btn" onClick={traditionaltech}>
                        Digital Transformation
                      </div>
                      <div
                        className={`dropdown-content-parent ${
                          dropdown ? "tech-active" : ""
                        }`}
                        onClick={traditionaltech}
                      ></div>
                      <div
                        className={`dropdown-content ${
                          dropdown ? "tech-active" : ""
                        }`}
                      >
                        <ul className="menu">
                          <li onClick={traditionaltech}>
                            <Link to="/web-development">Web Development</Link>
                          </li>
                          <li onClick={traditionaltech}>
                            <Link to="/app-development">App Development</Link>
                          </li>
                          <li onClick={traditionaltech}>
                            <Link to="/digital-marketing">
                              Digital Marketing
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="nav-item">
                      <NavLink to="portfolio">Portfolio</NavLink>
                    </li>
                    {/* <li className='nav-item'>
                      <NavLink to="/case-studies">Case Studies</NavLink>
                    </li> */}
                    <li className="nav-item">
                      <NavLink to="/blogs">Blog</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
