import React from "react";
import "../../../Styles//portfolio.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import nft from "../../../../images/meet.jpg";
import ContactForm from "../../ContactForm";

function NftExchange() {
  return (
    <div className="container">
      <div className="row case-study-parent">
        <div className="col-md-12 mt-5">
          <div className="project-card-parent case-studies-hero-section justify-content-between d-flex pb-5">
            <div className="col-lg-5">
              <div className="project-description-parent">
                <div class="project-heading">99 NFT Exchange</div>
                <div class="project-description">
                  NFT exchange create, sell or collect digital items. Unit of
                  data stored on a digital ledger, called a blockchain, that
                  certifies a digital asset to be unique and therefore not
                  interchangeable.
                </div>
                <a
                  href="http://nftexchange.web3inventiv.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn read-btn"
                >
                  Visit...
                  <FontAwesomeIcon icon={faChevronRight} />
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-parent">
                <img src={nft} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <h2 class="about-head">About</h2>
          <div class="case-description">
            It is a platform for trading non-fungible tokens (NFTs), unique
            digital assets like art, collectibles, and virtual real estate. It
            offers features like secure transactions through smart contracts,
            user-friendly interfaces, and community engagement. Compliance with
            regulations, ensuring liquidity, and continuous innovation are key
            focuses.
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <div class="port_obect">
            <h2>Project Objective</h2>
            <p class="case-description">
              To develop a platform where users can buy, sell, and trade digital
              assets represented as NFTs, digital tokens that represent
              ownership or proof of authenticity of a specific item or piece of
              content, such as digital art, collectibles, virtual real estate,
              in-game items, music, videos, and more.
            </p>
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <div class="row">
            <div class="col-md-6">
              <div class="concept">
                <h3>Conceptualization</h3>
                <ul class="bullet_list">
                  <li>User Interface and Experience</li>
                  <li>Asset Listings and Discovery</li>
                  <li>Asset Management</li>
                  <li>Trading and Transactions</li>
                  <li>Security and Compliance</li>
                  <li>Community Engagement</li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div class="tech_use">
                <h3>Technologies Used</h3>
                <ul class="bullet_list">
                  <li>Solidity</li>
                  <li>Node</li>
                  <li>React JS</li>
                  <li>Blockchain(Ethereum)</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="show_features">
                <h3>Features</h3>
                <ul class="bullet_list">
                  <li>Web3 wallet integration(Metamask and trust)</li>
                  <li>Ability to Edit profile of users</li>
                  <li>
                    Listing of Crypto Asset , Creation of NFT, setting its
                    Metadata, and Placing it on Auction.
                  </li>
                  <li>Supports Artwork, MP4, MP3, URL creation for NFTs</li>
                  <li>Top Collections</li>
                  <li>
                    NFT metadata to be stored on the IPFS File Storage System
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <h2>Our Project Challenges</h2>
          <div class="project-points mt-4">
            <div class="points-description">
              Ensuring sufficient liquidity in the marketplace to facilitate
              trades and maintain a vibrant ecosystem.
            </div>
            <div class="points-description mt-3">
              Smart contracts powering the marketplace may contain bugs or
              vulnerabilities that could be exploited by attackers.
            </div>
            <div class="points-description mt-3">
              NFT marketplaces must comply with various legal and regulatory
              requirements.
            </div>
            <div class="points-description mt-3">
              NFT marketplaces are attractive targets for hackers due to the
              high value of digital assets traded.
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <h2>Solutions We Developed</h2>
          <div class="project-points mt-4">
            <div class="points-description">
              Implemented features such as market-making incentives, liquidity
              pools, and trading incentives to encourage participation from
              buyers and sellers. Foster partnerships with liquidity providers
              and integrate with decentralized finance (DeFi) protocols.
            </div>
            <div class="points-description mt-3">
              Conducted thorough code reviews, security audits, and testing of
              smart contracts before deployment. Utilized formal verification
              tools and implemented upgrade mechanisms to address any discovered
              issues promptly.
            </div>
            <div class="points-description mt-3">
              Work closely with legal experts to ensure compliance with relevant
              laws and regulations in jurisdictions where the marketplace
              operates.
            </div>
            <div class="points-description mt-3">
              Employ robust security measures such as encryption, multi-factor
              authentication, audit trails, and regular security audits. Utilize
              smart contracts with well-audited code to ensure the integrity of
              transactions and asset ownership.
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default NftExchange;
