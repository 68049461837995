import React from 'react'
import blockchainBanner from '../../images/blockchain consulting.jpg'
import bitcoin from '../../images/services-blockchain-consulting-bitcoin.png'
import cardano from "../../images/services-blockchain-consulting-cardano.png"
import corda from "../../images/services-blockchain-consulting-corda.png"
import echo from '../../images/services-blockchain-consulting-echo.png'
import eos from '../../images/services-blockchain-consulting-eos.png'
import etherum from '../../images/services-blockchain-consulting-ethereum.png'
import fileCoin from '../../images/services-blockchain-consulting-filecoin.png'
import flow from '../../images/services-blockchain-consulting-flow.png'
import graphene from '../../images/services-blockchain-consulting-graphene.png'
import hedera from '../../images/services-blockchain-consulting-hedera.png'
import hyperledger from '../../images/services-blockchain-consulting-hyperledger.png'
import polkaDot from '../../images/services-blockchain-consulting-polkadot.png'
import polygon from '../../images/services-blockchain-consulting-polygon.png'
import qtum from '../../images/services-blockchain-consulting-qtum.png'
import quorum from '../../images/services-blockchain-consulting-quorum.png'
import ripple from '../../images/services-blockchain-consulting-ripple.png'
import solana from '../../images/services-blockchain-consulting-solana.png'
import graph from '../../images/services-blockchain-consulting-the-graph.png'
import why from "../../images/why.png"
import './blockchain-consulting.scss'

function BlockchainConsulting() {
    return (
        <>
            <div className='blockchain-section'>
                <div className='banner'>
                    <img src={blockchainBanner} />
                </div>
                <div className='container'>
                    <h2 className='text-center'>Blockchain Consultancy</h2>
                    <div className='description'>Blockchain is the future of Businesses. We help business leaders understand how blockchain fits seamlessly into their business models. We offer you long-term support in decision-making (We can help you which vendor to choose for development) and financial advisory. Also, we are willing to go the extra mile and can represent you while visiting your development partner face to face. Our blockchain consulting services and application development solutions include custom applications built on high-performance platforms such as Hyperledger, EOS, and Stellar. </div>
                    <h4 className='text-center'>Our Blockchain Consulting Services</h4>
                    <div className='row justify-content-between mb-5'>
                        <div className='col-md-3 service-box'>
                            <h5 className='mt-3 mb-2'>Blockchain Consultancy</h5>
                            <div className='content'>We review your existing solutions, identify the need for blockchain solutions specific to your business use case, and analyze how blockchain will add value to your business. We offer the right technology and possible solutions for the proposed projects.</div>
                        </div>
                        <div className='col-md-3 service-box'>
                            <h5 className='mt-3 mb-2'>Quick PoC</h5>
                            <div className='content'>Our team creates a proof of concept (PoC) to demonstrate the real potential of your blockchain project in 4 weeks. PoCs help customers understand how their blockchain ecosystem will work.</div>
                        </div>
                        <div className='col-md-3 service-box'>
                            <h5 className='mt-3 mb-2'>Help you hire the right partner if not us</h5>
                            <div className='content'>We offer you long-term support in decision-making (We can help you which vendor to choose for development) and financial advisory. Also, we are willing to go the extra mile and can represent you while visiting your development partner face to face.</div>
                        </div>
                    </div>
                    <div className='row justify-content-between mb-5'>
                        <div className='col-md-3 service-box'>
                            <h5 className='mt-3 mb-2'>Tokenomics development</h5>
                            <div className='content'>Today, the scale of tokenized assets is enormous, and web3inventiv prides itself on being a certified expert in tokenization, non-fungible tokens, and STO blockchain consulting. We help design unique NFTs and efficient DeFi systems that provide access to key emerging markets.</div>
                        </div>
                        <div className='col-md-3 service-box'>
                            <h5 className='mt-3 mb-2'>Financial modeling</h5>
                            <div className='content'>To estimate and simulate the economic viability of your solution, our blockchain consultants work closely with the quantitative team. They test numerous scenarios, design monetization capabilities, calculate project costs, allocate resources and optimize budgets.</div>
                        </div>
                        <div className='col-md-3 service-box'>
                            <h5 className='mt-3 mb-2'>Industry insights integration</h5>
                            <div className='content'>Not only do we provide market specific applications, but we also formulate a surefire way to make you a game changer in your field. Our blockchain strategy consulting provides clients with value-added knowledge on how to implement a decentralized ledger in any given environment.</div>
                        </div>
                    </div>
                    <div className='row justify-content-between mb-5'>
                        <div className='col-md-3 service-box'>
                            <h5 className='mt-3 mb-2'>Tech stack advice</h5>
                            <div className='content'>Choosing a reliable platform and solid technical support is half the solution. We'll help you choose blockchain tools and services wisely to find a solution that fully meets your security, user experience, and functionality requirements.</div>
                        </div>
                    </div>
                    <h4 className='platform-heading'>Blockchain platforms we work with</h4>
                    <div className='row justify-content-between mb-3'>
                        <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={bitcoin} />
                            </div>
                        </div>
                        <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={echo} />
                            </div>
                        </div>
                        <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={cardano} />
                            </div>
                        </div>
                        <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={corda} />
                            </div>
                        </div>
                        <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={eos} />
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-between mb-3'>
                        <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={etherum} />
                            </div>
                        </div>
                        <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={fileCoin} />
                            </div>
                        </div>
                        <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={flow} />
                            </div>
                        </div>
                        <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={graphene} />
                            </div>
                        </div>
                        <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={hedera} />
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-between mb-3'>
                        <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={hyperledger} />
                            </div>
                        </div>
                        <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={polkaDot} />
                            </div>
                        </div>
                        <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={polygon} />
                            </div>
                        </div>
                        <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={qtum} />
                            </div>
                        </div>
                        <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={quorum} />
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-between mb-5'>
                        <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={ripple} />
                            </div>
                        </div>
                        <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={solana} />
                            </div>
                        </div>
                        <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={graph} />
                            </div>
                        </div>
                        <div className='col-md-2'></div>
                        <div className='col-md-2'></div>
                        {/* <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={qtum} />
                            </div>
                        </div>
                        <div className='col-md-2 platforms-box'>
                            <div className='img-parent'>
                                <img src={quorum} />
                            </div>
                        </div> */}
                    </div>
                    <div className='why-us row justify-content-between'>
                        <div className='col-md-6'>
                            <h4>Why choose us?</h4>
                            <ul>
                                <li>You’ll get advice and help in the programming of scalable and distributed applications and several other custom-built protocols.</li>
                                <li>We will help you develop a network which will allow several devices to interconnect with each other and prevent threats of spoofing.</li>
                                <li>Expert Blockchain consultants can help you understand the crypto industry in a much more efficient manner.</li>
                                <li>Consulting services can help you to predict what is about to come in the future so that you can make your investments accordingly.</li>
                            </ul>
                        </div>
                        <div className='col-md-5 image-parent'>
                            <img src={why} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlockchainConsulting
